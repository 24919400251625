import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Colours } from "../../../Constants/Colours";
import IAuthor from "../../../Models/DTOs/IAuthorDTO";
import { GetFrontPageAuthors } from "../../../Api/Author";
import AuthorCarouselItem from "../../Carousels/AuthorCarouselItem";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { AxiosError } from "axios";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    padding: 1rem;
    background: #191919;
    h2 {
        text-align: center;
    }
    
        
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        padding: 2rem 1rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        padding: 3rem 0 4rem 1rem;
        h2 {
            padding: 0 1rem 0 0;
        }
    }
`;

const AuthorContainer = styled.div`
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {

    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        display: flex;
        justify-content: center;
        flex-wrap: no-wrap;
        & > div {
            width: calc(100% - 1rem);
            margin: 0 1rem 1rem 0;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {

    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {

    }  
`;

const ContentContainer = styled.div`
    p {
        color: ${ Colours.TextBright };
        text-align: center;
        margin: 2rem auto;
        max-width: 650px;
        font-size: 14pt;
    }
`;

const ButtonContainer = styled.div`
    & > a,
    & > button {
        display: table;
        margin: 0 auto;
    }
`;




function PanelPresenter(props: { panel: IPanelDTO, freeDaysTrial: number }) {
    const isLoaded = useRef<boolean>(false);
    const controller = new AbortController();
    const [authors, setAuthors] = useState<IAuthor[]>([]);

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        async function LoadData() {
            await Promise.all([InitAuthors()]);
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function InitAuthors(){
        const result = await GetFrontPageAuthors(controller);

        if(result === null || result === undefined || result instanceof AxiosError || result.length <= 0){
            return;
        }

        setAuthors(result.filter(x => x.HeaderImg !== null && x.HeaderImg !== undefined && x.HeaderImg.length > 0));
    }

    return (
        <Container>
            {props.panel.Properties.filter(
                (x) => x.PropertyType === PanelPropertyType.Title
            ).map((property, index) => {
                return (
                    <Heading key={"T" + property.Id} type={ HeadingType.H2 }>
                        {property.Value.replace(
                            "{freetrialdays}",
                            props.freeDaysTrial.toString()
                        )}
                    </Heading>
                );
            })}

            <AuthorContainer>
                {authors.map((author) => {
                    return (
                        <AuthorCarouselItem key={author.Id} author={author} />
                    );
                })}
            </AuthorContainer>

            <ContentContainer>
                {props.panel.Properties.filter(
                    (x) => x.PropertyType === PanelPropertyType.Text
                ).map((property, index) => {
                    return (
                        <React.Fragment key={"T" + property.Id}>
                            {parse(
                                DOMPurify.sanitize(
                                    property.Value.replace(
                                        "{freetrialdays}",
                                        props.freeDaysTrial.toString()
                                    )
                                )
                            )}
                        </React.Fragment>
                    );
                })}
            </ContentContainer>

            <ButtonContainer>
                {props.panel.Properties.filter(
                    (x) => x.PropertyType === PanelPropertyType.ButtonText
                ).map((property, index) => {
                    return (
                        <NavLinkPinkButtonFat
                            key={"B" + property.Id}
                            to={RoutePaths.CreateYourAccount}
                        >
                            {property.Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </NavLinkPinkButtonFat>
                    );
                })}
            </ButtonContainer>
        </Container>
    );
}

export default PanelPresenter;
