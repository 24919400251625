import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import {Ease} from "../../../Constants/EasingCurves";

const Button = styled(NavLink)`
    background: transparent;
    color: ${Colours.IckonicPink};
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    border: 0;
    font-size: 10pt;
    padding: 0;
    cursor: pointer;
    transition: color .25s ${ Ease.Smooth };
    :hover{
        color: ${Colours.TextBright};
    }
`;

function NavLinkPinkTextButton(props: {children: string | string[], to:string}){
    return(
        <Button to={props.to}>
            {props.children}
        </Button>
    )
};

export default NavLinkPinkTextButton;