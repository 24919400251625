import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { useEffect, useState } from "react";
import { SignUpHeaderActive } from "../../../Models/Enums/SignUpHeaderActive";
import { Fonts } from "../../../Constants/Fonts";
import {Ease} from "../../../Constants/EasingCurves";

const Container = styled.div`
    display: flex;    
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    max-width: 880px;       
`;

const SubscribeHeaderIcon = styled.div`
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
    font-size: 17px;
    background: black;
    color: #ffffff;
    line-height: 50px;
    width: 46px;
    height: 46px;
    line-height: 40px;
    text-decoration: none;
    font-family: ${ Fonts.Primary };
    letter-spacing: 0.7px;
    margin: 0 auto;
    border: solid ${ Colours.Tertiary };
`;

const SignUpItem = styled.div`
    width: 33.333333%;
    position: relative;
    z-index: 1;
    &.active {
        ${ SubscribeHeaderIcon } {
            //background: ${ Colours.IckonicPink };
            border-color: ${ Colours.IckonicPink };
        }
    }
    
    > div {
        z-index: 1;
        position: relative;
    }
    
    &:nth-child(2) {
        z-index: 0;
        .step-bridge,
        .step-bridge:before {
            z-index: 0;
            position: absolute;
            height: 2px;
            top: 50%;
            background: ${ Colours.Tertiary };
        }
        
        .step-bridge {
            width: calc(100% - 46px);
            left: calc(-50% + (23px));
            
        }
        
        ${ SubscribeHeaderIcon } {
            transition: border .45s ${ Ease.Smooth } 2.25s;
        }
        
        .step-bridge:before {
            content: '';
            width: 0;
            left: 0;
            background: ${ Colours.IckonicPink };
            transition: width 1s ${ Ease.Smooth } 1.25s;

        }
        
        .step-bridge--two {
            left: auto;    
            right: calc(-50% + (23px));
        }
        
        .step-bridge--two:before{
            left: 0;
        }
        
        &.should-use-first-path {
            ${ SubscribeHeaderIcon } {
                 animation-name: pulseHuge;
                 animation-iteration-count: 1;
                 animation-delay: 2.05s;
                 animation-duration: 1s;
                animation-timing-function: ${ Ease.Smooth };
          }
            
            .step-bridge--one:before {
                width: 100%;
            }
        }
        
        &.should-use-both-paths {
            .step-bridge--one:before,
            .step-bridge--two:before {
                width: 100%;
            }
           
            
            .step-bridge--two:before {
                transition: width 1s ${ Ease.Smooth };
            }
        }
    }
    
    &:nth-child(3) {
        ${ SubscribeHeaderIcon } {
            transition: border .45s ${ Ease.Smooth } 0.75s;
        }
        
        &.active {
            ${ SubscribeHeaderIcon } {
                    animation-name: pulseHuge;
                    animation-iteration-count: 1;
                    animation-delay: 0.65s;
                    animation-duration: 1s;
                    animation-timing-function: ${ Ease.Smooth };
                }
        }
    }
   

    h3 {
        margin: 0;
    }
`;

const SignUpHeader = (props: { active: SignUpHeaderActive }) => {
    const [ activeClasses, setActiveClasses ] = useState<string[]>([]);

    useEffect(() => {
        // Set the active classes based on the active prop
        const classes: string[] = [];

        if (
            props.active === SignUpHeaderActive.ChoosePlan ||
            props.active === SignUpHeaderActive.ConfirmSubscription
        ) {
            classes.push('active');
        }

        if (props.active === SignUpHeaderActive.ConfirmSubscription) {
            classes.push('should-use-both-paths');
        } else if (props.active === SignUpHeaderActive.ChoosePlan) {
            classes.push('should-use-first-path');
        }

        setActiveClasses(classes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <SignUpItem className="active">
                <SubscribeHeaderIcon>1</SubscribeHeaderIcon>
            </SignUpItem>
            <SignUpItem
                className={
                    activeClasses.includes('active') ||
                    props.active === SignUpHeaderActive.ConfirmSubscription
                        ? `active ${
                            activeClasses.includes('should-use-both-paths')
                                ? 'should-use-both-paths'
                                : 'should-use-first-path'
                        }`
                        : ''
                }
            >
                <div className="step-bridge step-bridge--one"> </div>
                <SubscribeHeaderIcon>2</SubscribeHeaderIcon>
                <div className="step-bridge step-bridge--two"> </div>
            </SignUpItem>
            <SignUpItem className={ props.active === SignUpHeaderActive.ConfirmSubscription && activeClasses.length > 0 ? 'active' : ''}>
                <SubscribeHeaderIcon>3</SubscribeHeaderIcon>
            </SignUpItem>
        </Container>
    );
};

export default SignUpHeader;
