import { SubscriptionStatus } from "../Models/Enums/SubscriptionStatus";
import { IUserDetails } from "../Models/IUserDetails";

export function IsAuthenticated(userDetails: IUserDetails): boolean {
    if (userDetails.Access_Token == null || userDetails.Access_Token.length === 0) {
        return false;
    }
    return true;
}

export function NeedsRenew(userDetails: IUserDetails): boolean {

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Unpaid:
        case SubscriptionStatus.Incomplete:
            return true;
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Cancelled:
        case SubscriptionStatus.None:
        case SubscriptionStatus.Paused:
        default:
            return false;
    }
}

export function IsActive(userDetails: IUserDetails): boolean {

    if (userDetails.AccessLevel === 99) {
        return true;
    }

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Cancelled:
            return true;
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.None:
        case SubscriptionStatus.Paused:
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Unpaid:
        default:
            return false;
    }
}
