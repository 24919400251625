import { useState } from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
    cursor: pointer;
`;

const Container = styled.div`
    position: relative;
`

const ChildContainer = styled.div`
    display: none;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    position: absolute;
    padding: 10px;
    background: #131313;
    border-radius: 5px;
    right: 0;
    z-index: 1;

    &.active{
        display: flex;
    }
`;

function DropDownOverlay(props: {
    Header: JSX.Element;
    children: JSX.Element | JSX.Element[];
    testId?: string,
    childTestId?: string,
}) {
    const [isActive, setIsActive] = useState<boolean>(false);

    function OnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>){
        setIsActive(!isActive);
    };

    return (
        <Container >
            <HeaderContainer onClick={OnClick} data-testid={props.testId}>{props.Header}</HeaderContainer>
            <ChildContainer className={isActive ? "active" : ""} data-testid={props.childTestId}>
                {props.children}
            </ChildContainer>
        </Container>
    );
}


export default DropDownOverlay;