import { AxiosError } from "axios";
import { Suspense, useEffect } from "react";
import { useContext, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Await, defer, Params, redirect, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { GetEpisodeBySeason, GetEpisodesByYear } from "../../Api/Carousel";
import { GetIsFavourite } from "../../Api/Favourite";
import { GetSeries } from "../../Api/Series";
import { GetLatestVideo } from "../../Api/Video";
import CarouselItem from "../../Components/Carousels/CarouselItem";
import FeaturedLoader from "../../Components/UI/PageLoaders/FeaturedLoader";
import InfiniteScrollLoader from "../../Components/UI/PageLoaders/InfiniteScrollLoader";
import SeriesHeader from "../../Components/UI/Series/SeriesHeader";
import Heading from "../../Components/UI/Text/Heading";
import { RoutePaths } from "../../Constants/RoutePaths";
import { IsNumber, SetTitle } from "../../Helpers/Utility";
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";
import ISeriesDTO from "../../Models/DTOs/ISeriesDTO";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import AuthContext, { UserSnapshot } from "../../Store/auth-context";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;

    .infinite-scroll-component__outerdiv:nth-child(2) {

        padding: 1% 2%;

        h1 {
            font-size: 2.5rem;
            margin: 0;
        }

        .infinite-scroll-component {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-direction: column;
        }
    }
`;

const EpisodeContainer = styled.div`

    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-self: center;
    width: 100%;

    //Makes title's and carousels take up entire row
    h1,
    .swiper-initialized {
        grid-column: 1/-1;
    }

    @media only screen and (max-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1115px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: auto !important;

    }
`;

function SeriesDetailsScreen() {
    //Maps values returned from loader
    const { seriesPromise, latestPromise, favourite, seriesId } = useLoaderData() as {
        seriesPromise: Promise<ISeriesDTO>;
        latestPromise: Promise<IVideoDTO>;
        favourite: boolean;
        seriesId: number;
    };

    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [episodes, setEpisodes] = useState<{ Carousel: ICarouselDTO; DisplayTitle: boolean }[]>(
        []
    );
    const [isFavourite, setIsFavourite] = useState<boolean>(favourite);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentYears, setCurrentYears] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const currentSeason = useRef<number>(1);
    const lastId = useRef<number>(0);
    const iteration = useRef<number>(0);
    const skip = useRef<number>(0);
    const isLoaded = useRef<boolean>(false);
    const loadedSeries = useRef<number>(0);
    const loadingRef = useRef(null);
    function IsYearlyContentTheSame(result: ICarouselDTO) {
        // This function has been written to remove a specific bug with yearly content sometimes loading twice
        const contentId = result?.Content[0]?.Id;
        if (lastId.current === contentId) {
            return true;
        }
        return false;
    }

    function SetLastContent(result: ICarouselDTO) {
        lastId.current = result?.Content[0]?.Id;
    }

    useEffect(() => {
        if (isLoaded.current && loadedSeries.current === seriesId) {
            return;
        }

        async function load() {
            loadedSeries.current = seriesId;
            const series = await seriesPromise;
            setEpisodes([]);
            currentSeason.current = 1;
            GetContent(series);
        }

        load();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesPromise]);

    function SetIsFavourite(value: boolean) {
        setIsFavourite(value);
    }

    async function GetContent(series: ISeriesDTO) {
        if (series === null || series === undefined || loading) {
            return;
        }

        setLoading(true);

        if (series.YearlyEpisodeDisplay) {
            await EpisodesByYear(series.Id);
        } else {
            await EpisodesBySeason(series.Id);
        }

        setLoading(false);
    }

    async function EpisodesBySeason(seriesId: number) {
        const result = await GetEpisodeBySeason(
            seriesId,
            authCtx.UserDetails.CurrentCountryCode,
            controller,
            true,
            currentSeason.current,
            'a'
        );

        if (
            result === null ||
            result === undefined ||
            result instanceof AxiosError ||
            result.Content === null ||
            result.Content === undefined ||
            result.Content.length <= 0
        ) {
            setHasMore(false);
            return;
        }

        currentSeason.current += 1;
        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: true }]);
    }

    async function EpisodesByYear(seriesId: number) {
        let take = 15;
        const result = await GetEpisodesByYear(
            seriesId,
            authCtx.UserDetails.CurrentCountryCode,
            authCtx.UserDetails.AspNetUserId,
            take,
            skip.current,
            iteration.current,
            controller,
            'd'
        );

        if (
            result === null ||
            result === undefined ||
            result instanceof AxiosError ||
            result.Content === null ||
            result.Content === undefined ||
            result.Content.length <= 0
        ) {
            setHasMore(false);
            return;
        }

        if (IsYearlyContentTheSame(result)) {
            iteration.current = iteration.current += 1;
            await EpisodesByYear(seriesId);
            return;
        }
        SetLastContent(result);

        const year = result.Title.toLowerCase().split("from ")[1].trim();
        let displayTitle = true;

        if (!currentYears.includes(year)) {
            skip.current = result.Content.length;
            setCurrentYears((prev) => [...prev, year]);
        } else {
            skip.current += result.Content.length;
            displayTitle = false;
        }

        if (result.Content.length < take) {
            skip.current = 0;
            iteration.current = iteration.current += 1;
        }

        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: displayTitle }]);
    }

    function DisplayEpisodes(carousel: ICarouselDTO) {
        return (
            <>
                {carousel.Content.map((content, index) => {
                    return (
                        <CarouselItem key={index.toString() + content.Id} Content={content} />
                    );
                })}
            </>
        );
    }

    return (
        <Suspense fallback={<FeaturedLoader/>}>
            <Await resolve={seriesPromise}>
                { (seriesData) => {
                    const series = seriesData as ISeriesDTO;
                    return (
                        <Container>
                            <Suspense fallback={ <FeaturedLoader/> }>
                                <Await resolve={ latestPromise }>
                                    { (latestData) => {
                                        const latestEpisode = latestData as IVideoDTO;
                                        return (
                                            <>
                                                <SeriesHeader
                                                    Content={ series }
                                                    IsFavourite={ isFavourite }
                                                    SetIsFavourite={ SetIsFavourite }
                                                    LatestEpisode={ latestEpisode?.Id }
                                                    rating={ series.Rating }
                                                />

                                                <InfiniteScroll
                                                    dataLength={ episodes.length }
                                                    hasMore={ hasMore }

                                                    next={() => GetContent(series) }
                                                    loader={ <InfiniteScrollLoader/> }
                                                >
                                                    <div>
                                                        {episodes.map((item, index) => {
                                                            return (
                                                                // Works for yearly display
                                                                <EpisodeContainer key={index}>
                                                                    {
                                                                        item.DisplayTitle ?
                                                                            (
                                                                                <Heading>{ item.Carousel.Title }</Heading>
                                                                            ) : null
                                                                    }

                                                                    { DisplayEpisodes(item.Carousel) }
                                                                </EpisodeContainer>
                                                            );
                                                        })}
                                                    </div>
                                                </InfiniteScroll>
                                            </>
                                        );
                                    } }
                                </Await>
                            </Suspense>
                        </Container>
                    );
                }}
            </Await>
        </Suspense>
    );
}

export default SeriesDetailsScreen;

export async function Loader ({
                                  params,
                              }: {
    params: Readonly<Params<string>>;
}) {

    const seriesIdString = params.id;

    if (
        seriesIdString === null ||
        seriesIdString === undefined ||
        seriesIdString.length <= 0
    ) {
        return redirect(RoutePaths.Browse);
    }


    if(!IsNumber(seriesIdString)){
        throw new Response("Not a valid series id", { status: 400, statusText: "Bad request" });
    }

    const seriesId = parseInt(seriesIdString);

    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    if(seriesId === 0){
        throw new Response("Not a valid series id", { status: 400, statusText: "Bad request" });
    }

    async function InitSeries() {
        const result = await GetSeries(seriesId, controller);

        if (result === null || result === undefined || result instanceof AxiosError || result.Id === null || result.Id <= 0) {
            return redirect(RoutePaths.Browse);
        }

        SetTitle(result.Title);
        return result;
    }

    async function LatestEpisode() {
        const result = await GetLatestVideo(seriesId, userDetails.CurrentCountryCode, controller);

        if (result === null || result === undefined || result instanceof AxiosError) {
            return;
        }

        return result;
    }

    async function Favourite() {
        if(userDetails === null
            || userDetails === undefined
            || userDetails.AspNetUserId === null
            || userDetails.AspNetUserId === undefined
            || userDetails.AspNetUserId.length <= 0){
            return false;
        }

        const result = await GetIsFavourite(seriesId, userDetails.AspNetUserId, true, controller);

        if(result instanceof AxiosError){
            return false;
        }

        return result;
    }

    return defer({
        seriesId: seriesId,
        seriesPromise: InitSeries(),
        latestPromise: LatestEpisode(),
        favourite: await Favourite(),
    });
}
