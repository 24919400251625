import { useEffect, useRef, useCallback } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { SplitVideoLink } from "../../Helpers/Utility";
import { IPlayerPeakInfo } from "../../Models/IPlayerPeakInfo";
import { AddVideoStat } from "../../Api/Video";

import "video.js/dist/video-js.css";
import "./Theme.css";
import "videojs-contrib-quality-levels";
import "videojs-seek-buttons";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import 'videojs-http-source-selector';
import styled from "styled-components";

const StyledPlayer = styled.video`
    height: 100%;
    width: 100%;
`;

function VideoPlayer(props: {
    src: string;
    poster: string;
    peak?: IPlayerPeakInfo;
    startAt?: number;
    disablePlayback?: boolean;
}) {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const peakTimer = useRef<NodeJS.Timer>();
    const videoId = useRef<number>(props.peak?.videoId ?? 0);
    const controller = new AbortController();
    const isFirstMount = useRef(true);

    // Adjust baseLink and links
    let baseLink: string = props.src ? SplitVideoLink(props.src) : '';

    let links: { src: string; type: string }[];

    if (baseLink.includes('storage.ickonic.com')) {
        // Remove "manifest(format=m3u8-cmaf)" from baseLink
        baseLink = baseLink.replace('manifest(format=m3u8-cmaf)', '');
        // Prevent adding "/manifest(format=m3u8-cmaf)" in the first link
        links = [
            { src: baseLink, type: "application/x-mpegURL" },
            { src: baseLink + "/manifest(format=mpd-time-csf)", type: "application/dash+xml" },
        ];
    } else {
        links = [
            { src: baseLink + "/manifest(format=m3u8-cmaf)", type: "application/x-mpegURL" },
            { src: baseLink + "/manifest(format=mpd-time-csf)", type: "application/dash+xml" },
        ];
    }

    console.log(links);

    const isSeekingRef = useRef(false);

    const options: VideoJsPlayerOptions = {
        autoplay: !!props.peak, // Set autoplay to true only if there is peak data
        controls: props.disablePlayback !== true,
        disablePictureInPicture: true,
        responsive: true,
        controlBar: {
            pictureInPictureToggle: false,
            audioTrackButton: false,
        },
        bigPlayButton: props.disablePlayback !== true,
        poster: props.poster,
        sources: links,
        playbackRates: [0.5, 1, 1.5, 2],
    };

    const clearPeakTimer = useCallback(() => {
        if (peakTimer.current) {
            clearInterval(peakTimer.current);
            peakTimer.current = undefined;
        }
    }, []);

    const onPlay = useCallback(() => {
        if (props.peak && !peakTimer.current) {
            //peakTimer.current = setInterval(addPeakCounter, 30000);
        }
    }, [props.peak]);

    const onPause = useCallback(() => {
        clearPeakTimer();

        setTimeout(() => {
            if (!isSeekingRef.current) {
                const pauseEvent = new CustomEvent('onPauseEvent', { detail: { message: 'Pause event triggered' } });
                document.dispatchEvent(pauseEvent);
            }
        }, 25);
    }, [clearPeakTimer]);

    const onSeekStart = useCallback(() => {
        isSeekingRef.current = true;
    }, []);

    const onSeekEnd = useCallback(() => {
        setTimeout(() => {
            isSeekingRef.current = false;
        }, 50);
    }, []);

    const addPeakCounter = useCallback(async () => {
        if (!props.peak) return;

        const currentTime = Math.round(playerRef.current?.currentTime() ?? 0);

        const result = await AddVideoStat(
            videoId.current,
            props.peak.userId,
            props.peak.session,
            currentTime,
            controller
        );

        if (result === false) {
            props.peak.updateBlockedState(true);
        }
    }, [props.peak, controller]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement || playerRef.current) return;

        const player = playerRef.current = videojs(videoElement, options, () => {
            player.on("play", onPlay);
            player.on("pause", onPause);
            player.on("seeking", onSeekStart);
            player.on("seeked", onSeekEnd);
        });

        // Initialize the http-source-selector plugin
        player.httpSourceSelector();

        // Use contrib-quality-levels
        player.qualityLevels();

        player.seekButtons({ forward: 10, back: 10 });

        if (props.startAt) {
            player.currentTime(props.startAt);
        } else {
            player.currentTime(0);
        }

        if (options.autoplay) {
            player.play();
        }

        return () => {
            clearPeakTimer();
            player.off("play", onPlay);
            player.off("pause", onPause);
            player.off("seeking", onSeekStart);
            player.off("seeked", onSeekEnd);
        };
    }, [options, onPause, onPlay, onSeekEnd, onSeekStart, clearPeakTimer, props.startAt]);

    useEffect(() => {
        // if (isFirstMount.current) {
        //     console.log('first mount');
        //     isFirstMount.current = false;
        //     return; // Skip the first unmount caused by React Strict Mode
        // }

        return () => {
            if (playerRef.current) {
                //console.log('should dispose');
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (!playerRef.current) return;

        // Update the video source
        playerRef.current.src(links);

        // Reset the video time to start from the beginning or specified start time
        if (props.startAt) {
            playerRef.current.currentTime(props.startAt);
        } else {
            playerRef.current.currentTime(0);
        }

        // Autoplay if peak data exists
        if (props.peak) {
            playerRef.current.play();
        }
    }, [props.src]); // Watch for src prop changes

    useEffect(() => {
        if (!playerRef.current || !props.peak) return;

        if (props.peak.blocked) {
            playerRef.current.pause();
        } else {
            playerRef.current.play();
        }
    }, [props.peak?.blocked]);

    return <StyledPlayer ref={videoRef} className="video-js vjs-big-play-centered" />;
}

export default VideoPlayer;
