import React from "react";
import styled from "styled-components";

const Hero = styled.section`
    background: black;

    // Over-rides dark / light mode defaults
    * {
        color: white;
    }

    .layout__inner {
        padding: 1rem;
        margin: 0 auto;
    }

    h1, h2, h3, h4, h5, h6, p {
        &:first-child {
            margin-top: 0;
        }
    }
`;

const HeroOneColumn = (props: {
    body: React.ReactNode,
    backgroundImage?: string,
    maxWidth: number
}) => {
    return (
        <Hero
            className="layout layout--hero-one-column"
            style={{
                background: props.backgroundImage ? `url(${ props.backgroundImage }) center/cover no-repeat #000000` : "#000000",
            }}
        >
            <div className="layout__inner" style={{ maxWidth: `calc(${ props.maxWidth }rem/16)` }}>
                {props.body}
            </div>
        </Hero>
    );
};

export default HeroOneColumn;
