import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import IArticleDTO from "../../../Models/DTOs/IArticleDTO";
import Heading from "../Text/Heading";
import ImageLazyLoad from "../ImageLazyLoad";
import {ContentType} from "../../../Models/Enums/ContentType";
import Socials from "../Socials";
import {IconTime} from "../../../Assets/Icons/Icons";
import CategoryTerm from "./CategoryTerm";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const
    Date = styled.p`
        margin: 0 calc(12rem/16) calc(2rem/16) 0;
        font-weight: 500;
        color: ${ Colours.Text };
        padding: calc(4rem/16) calc(16rem/16);
        border-radius: calc(18rem/16);
        font-size: 10pt;
        box-shadow: 0 0 0 calc(1rem/16) rgba(255,255,255,0.2);
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            font-size: 12pt;
        }
    `,
    Author = styled.p`
        margin: 0;
        font-weight: 400;
        color: ${ Colours.TextBright };
        font-size: 10pt;
        padding: calc(4rem/16);
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            font-size: 12pt;
        }
    `,
    Header = styled.header`
        position: relative;
        overflow: hidden;
        width: 100%;
        box-shadow: 0 calc(1rem/16) 0 0 rgba(0,0,0,0.5);
        background: rgba(0,0,0,0.5);
        .article-header__inner {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        
        > .u-full_cover_absolute {
            opacity: 0.12;
            transform: scale(1.1);
            filter: blur(calc(12rem/16));
        }
        
        h1 ,
        h3 {
            width: 100%;
        }
        
        h1 {
            margin: 0 0 .5rem 0;
            font-weight: normal;
        }
        
        h3 {
            margin: 0.5rem 0 1rem 0;
            font-weight: lighter;
            line-height: 1.6;
        }
        
        .article-header__column {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .article-header__read-time {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: absolute;
                top: calc(8rem/16);
                right: 0;
                padding: calc(5rem/16) calc(10rem/16);
                background: ${ Colours.SecondaryDarker };
                svg {
                    width: calc(16rem/16);
                    height: calc(16rem/16);
                    margin: 0 calc(8rem/16) 0 0;
                    * {
                        fill: ${ Colours.TextBright };
                    }
                }
            }
            
            &:first-child {

                padding: 1rem;
                order: 1;
                max-width: calc(700rem/16);
                margin: 0 auto;
                p {
                    display: inline-flex;
                }
            }
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            .article-header__column {
                &:first-child {
                    padding: 2rem;
                }
            }
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
            .article-header__inner {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: calc(32rem/16);
                padding: 2rem 0;
                margin: 0 auto;
                max-width: calc(1350rem/16);
            }
            
            h1 {
                font-size: 25pt;
            }
            
            .article-header__column {
                &:first-child {
                    width: calc(50% - (16rem/16));
                    order: 0;
                    margin: 0;
                    padding: 2rem;
                }
                
                &:last-child {
                    width: calc(50% - (16rem/16));
                }
            }
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            .article-header__inner {
                padding: 3rem 0;
                .article-header__column {
                    &:first-child {
                        padding: 3rem;
                    }
                }
            }
        }
    `,
    SocialsContainer = styled.div`
        background: rgba(0,0,0,0.15);
        position: relative;
        z-index: 4;
        > div {
            background: none;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            max-width: calc(1285rem/16);
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
            padding: 0 1rem;
            > div {
                justify-content: flex-start;
            }
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            padding: 0 2rem;
        }
    `;


function ArticleHeader(props: { article: IArticleDTO|undefined, ReadTime: number|undefined, DateToString: string|undefined}) {
    return props.article !== undefined ? (
        <Header>
            <div className="u-full_cover_absolute" style={{background: `url('${props.article !== undefined && props.article !== null ? props.article.Header : ""}') center/cover no-repeat`}} />

            <div className="article-header__inner">
                <div className="article-header__column">

                    <Heading>
                        { parse(DOMPurify.sanitize(props.article.Title)) }
                    </Heading>

                    <Heading type={ HeadingType.H3 }>
                        { parse(DOMPurify.sanitize(props.article.SubTitle)) }
                    </Heading>

                    {props.article.Categories && props.article.Categories.length > 0 && (
                        <div style={{width:'100%',margin:'0 0 0.5rem 0'}}>
                            {props.article.Categories.map((category, i) => (
                                <CategoryTerm
                                    category={category}
                                    key={i}
                                />
                            ))}
                        </div>
                    ) }

                    <Date>
                        { props.DateToString }
                    </Date>

                    <Author>
                        By: { props.article.Author.Name }
                    </Author>
                </div>

                <div className="article-header__column">
                    <ImageLazyLoad
                        source={ props.article !== undefined ? props.article.Header : "" }
                    >
                        <p className="article-header__read-time">
                            <>
                                { IconTime() }
                                {`${props.ReadTime} minute read`}
                            </>
                        </p>
                    </ImageLazyLoad>
                </div>
            </div>

            <SocialsContainer>
                <Socials
                    displayText={true}
                    title={props.article.Title}
                    contentType={ContentType.Article}
                    articleUrlTitle={props.article.UrlTitle} />
            </SocialsContainer>
        </Header>
    ) : null
}

export default ArticleHeader;