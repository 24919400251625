import {useEffect, useState} from "react";
import {DifferenceBetweenDates, IsEligibleForSevenExtraDays} from "../../../Helpers/Utility";
import {ISubscriptionDTO} from "../../../Models/DTOs/ISubscriptionDTO";
import Cancel from "../Forms/Cancel";
import ExtraDays from "../Forms/ExtraDays";
import PopUp from "./PopUp";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import styled from "styled-components";
import Card from "../Card";

const CancelModalWrapper = styled.div`
    width: 100%;
    max-width: calc(600rem/16);
    h2,
    p {
        text-align: center;
        margin: 0 0 1rem 0;
    }
`;

function CancelModal(props: {
    display: boolean;
    setDisplay: (value: boolean) => void;
    subscription: ISubscriptionDTO;
}) {
    const [extraDays, setExtraDays] = useState<boolean>(true);
    const [displayExtraDays, setDisplayExtraDays] = useState<boolean>(true);

    useEffect(() => {
        const startDate = new Date(props.subscription.SubscriptionStartDate);
        const endDate = new Date(props.subscription.SubscriptionEndDate);
        const difference = DifferenceBetweenDates(endDate, startDate);
        const result = IsEligibleForSevenExtraDays(startDate, difference);
        SetExtraDays(result);

        if (props.display) {
            SetDisplayExtraDays(result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.display]);

    function SetExtraDays(value: boolean) {
        setExtraDays(value);
    }

    function SetDisplayExtraDays(value: boolean) {
        setDisplayExtraDays(value);
    }

    return (
        <PopUp
            canBeDismissed={ true }
            isFullScreen={ true }
            openEvent="cancelAutoRenewal"
        >
            <Card>
                <CancelModalWrapper>
                    <Heading type={ HeadingType.H2 }>
                        Cancel Auto Renewal
                    </Heading>

                    <PrimaryText>
                        {
                            displayExtraDays
                                ? "Are you sure you want to leave Ickonic?"
                                : "Please give us some feedback so we can improve."
                        }
                    </PrimaryText>

                    {!extraDays || !displayExtraDays ? (
                        <Cancel />
                    ) : (
                        <ExtraDays setExtraDays={SetDisplayExtraDays} />
                    )}
                </CancelModalWrapper>
            </Card>
        </PopUp>
    );
}

export default CancelModal;
