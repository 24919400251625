import styled from "styled-components";
import PrimaryText from "../Text/PrimaryText";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    p {
        user-select: none;
        font-size: 10pt;
        width: calc(100% - (36rem/16));
    }
`;

function CheckBoxWithLabel(props: {text: string, checked: boolean, setChecked: (valueL: boolean) => void}){

    function Toggle(){
        props.setChecked(!props.checked);
    }

    return(
        <Container>
            <input type={"checkbox"} checked={props.checked} onChange={Toggle}/>
            <PrimaryText onClick={Toggle}>{props.text}</PrimaryText>
        </Container>
    )
}

export default CheckBoxWithLabel;