import styled from "styled-components";
import { Colours } from "../../../../Constants/Colours";
import { useState } from "react";
import { HeaderProperties } from "../../../../Constants/HeaderProperties";
import { Ease } from "../../../../Constants/EasingCurves";

const menuWidth = "275px";

const HeaderContainer = styled.div`
    color: ${Colours.Text};
    font-size: 20pt;
    cursor: pointer;
    position: relative;
    margin: 0;
    height: 100%;
    width: ${ HeaderProperties.height };
`;

const ChildContainer = styled.div`
    position: fixed;
    right: -100%;
    top: ${ HeaderProperties.height };
    overflow: visible;
    background: #0b0b0b;
    z-index: 50;
    width: 100%;
    max-width: ${menuWidth};
    box-shadow: 0px 15px 20px rgb(0 0 0 / 5%);
    border: 1px solid rgba(0,0,0, 0.12);
    height: calc(100vh - ${ HeaderProperties.height });
    overflow-y: auto;
    opacity: 0;
    transition: transform .47s ${ Ease.Smooth }, opacity 0s linear .47s;
    &.hamburger-active{
        opacity: 1;
        transition: transform .47s ${ Ease.Smooth }, opacity 0s linear;
        transform: translateX(-100%);
    }
    
    @media screen and (min-width: calc(275em/16)) {
        right: -${menuWidth};
        &.hamburger-active{
            transform: translateX(-${menuWidth});
        }
    }
`;

function HamburgerMenu(props : {
    header: JSX.Element | JSX.Element[];
    children?: JSX.Element | JSX.Element[];
    isActive: boolean,
    setIsActive: (value: boolean) => void,
}) {

    function OnClick() {
        props.setIsActive(!props.isActive);
        setIsLoaded(true);
    }

    function getLoadedClass(): string {
        if (props.isActive) {
            return "hamburger-active";
        }
        else if (isLoaded)
        {
            return "hamburger-loaded";
        }
        return "";
    }

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    return(
        <>
            <HeaderContainer onClick={OnClick}>{props.header}</HeaderContainer>
            <ChildContainer className={getLoadedClass()}>
                {props.children}
            </ChildContainer>
        </>
    );
}

export default HamburgerMenu;
