import { useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import { Colours } from "../../../Constants/Colours";
import { Ease } from "../../../Constants/EasingCurves";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { ReactComponent as AndroidQrCode } from '../../../Assets/Images/QRCodes/qr-code.svg';  // Import as React Component
import AndroidGraphic from '../../../Assets/Images/Backgrounds/android-graphic.svg'; // Import Android graphic image

const NewAndroidAppWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: -200%;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transform: scale(1.05);
  min-height: 100vh;
  overflow-y: auto;
  transition: opacity 0.45s ${Ease.Smooth}, transform 0.45s ${Ease.Smooth}, top 0s linear 0.45s;

  &.is-active {
    opacity: 1;
    top: 0;
    transform: scale(1.01);
    transition: opacity 0.45s ${Ease.Smooth}, transform 0.45s ${Ease.Smooth}, top 0s linear;
  }

  .popup__content {
    width: calc(100% - 2rem);
    max-width: calc(600rem / 16);
    margin: 1rem;
    padding: 2rem;
    background-color: rgba(14, 14, 14, 0.95); /* Dark background with slight transparency */
    color: white;
    border-radius: calc(10rem / 16);
    box-shadow: 0 0 calc(15rem / 16) 0 black;
    text-align: center;
    border: 1px solid purple;
  }

  @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em / 16)) {
    .popup__content {
      margin: 0 2rem;
      width: calc(100% - 4rem);
    }
  }

  @media screen and (min-width: calc(760rem / 16)) {
    .popup__content {
      width: calc(696rem / 16);
    }
  }

  .close-button {
    margin-top: 1rem;
    padding: calc(7rem / 16) calc(10rem / 16);
    font-size: calc(14rem / 16);
    font-family: "Manrope", Helvetica, sans-serif;
    color: white;
    background-color: ${Colours.IckonicPink};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.25s ${Ease.Smooth}, color 0.25s ${Ease.Smooth}, background 0.25s ${Ease.Smooth};

    &:hover {
      background-color: ${Colours.IckonicPinkHighlight};
    }
  }
`;

// Styled component for the Android graphic with a width of 75px
const AndroidGraphicStyled = styled.img`
  width: 75px;   /* Set the width to 75px */
  height: auto;   /* Maintain aspect ratio */
  margin-right: 10px;  /* Add some space between the graphic and the text */
`;

const StyledHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

// Styled for larger "Dear Subscriber" text and added spacing
const LargeSubscriberText = styled.div`
  font-size: 1.75rem;   /* Increase font size */
  font-weight: bold;   /* Make text bold */
  margin-bottom: 1.5rem; /* Add more space below */
`;

const StyledQrCode = styled(AndroidQrCode)`
  width: 200px;   /* Set the width to 200px */
  height: 200px;  /* Set the height to 200px */
  display: block;
  margin: 2rem auto; /* Center the QR code */
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: purple;  /* Purple color to match the border for emphasis */
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 900;
  opacity: 0;
  transition: opacity 0.45s ease-in-out;

  &.is-active {
    opacity: 1;
  }
`;

function NewAndroidAppPopUp({ onClose }: { onClose: () => void }) {
  useEffect(() => {
    const popupRoot = document.createElement("div");
    document.body.appendChild(popupRoot);

    return () => {
      document.body.removeChild(popupRoot); // Cleanup on unmount
    };
  }, []);

  return createPortal(
    <>
      <Overlay className="is-active" onClick={onClose} />
      <NewAndroidAppWrapper className="is-active">
        <div className="popup__content">
          {/* Important Update with Android graphic */}
          <StyledHeadingWrapper>
            <AndroidGraphicStyled src={AndroidGraphic} alt="Android Icon" />
            <Heading>Important Update</Heading>
          </StyledHeadingWrapper>

          {/* Larger "Dear Subscriber" text */}
          <LargeSubscriberText>Dear Subscriber,</LargeSubscriberText>

          <PrimaryText>
            We've launched a new Android app with faster performance,
            <br />
            new features, and security updates! 
            <br />
            <br />
            <HighlightedText>On desktop?</HighlightedText> Scan this QR code with your Android Phone to download:
          </PrimaryText>

          {/* Render the QR Code, now centered and sized at 200px */}
          <StyledQrCode />

          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </NewAndroidAppWrapper>
    </>,
    document.body
  );
}

export default NewAndroidAppPopUp;
