import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import React from "react";
import Heading from "../Text/Heading";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import IArticleDTO from "../../../Models/DTOs/IArticleDTO";
import PrimaryText from "../Text/PrimaryText";
import {NavLink} from "react-router-dom";
import {RoutePaths} from "../../../Constants/RoutePaths";
import {Ease} from "../../../Constants/EasingCurves";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const
    Container = styled.div`
        width: 100%;
        max-width: calc(1350rem/16);
        padding: 1rem;
        background: #171717;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        h2,
        h3 {
            width: 100%;
            
            a {
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
        
        h2 {
            margin: 0 0 2rem 0;
        }
        
        h3 {
            margin: 0 0 1rem 0;
            color: ${Colours.TertiaryHighlight}; 
        }
        
        .next-arrow {
            padding: calc(2rem/16) calc(20rem/16);
            text-decoration: none;
            box-shadow: 0 0 0 calc(1rem/16) ${Colours.IckonicPink};
            transition: background .25s ${Ease.Smooth};
            border-radius: calc(16rem/16);
            &:hover,
            &:focus {
                background: ${Colours.IckonicPink};
            }
        }
        
        p {
            margin: 0 calc(24rem/16) 1rem 0;
            font-weight: 500;
            color: ${ Colours.Text };
            padding: calc(4rem/16) calc(10rem/16);
            font-size: 10pt;
            box-shadow: 0 0 0 calc(1rem/16) rgba(255,255,255,0.2);
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
                font-size: 12pt;
            }
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            padding: 2rem;
        }
        
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            padding: 2rem 3rem;
        }
    `;

function NextAricle(props: { article: IArticleDTO }) {
    return (
        <Container>
            <Heading type={HeadingType.H3}>
                Next Article
            </Heading>

            <Heading type={HeadingType.H2}>
                <NavLink to={RoutePaths.ReadArticle(props.article.UrlTitle)}>{parse(DOMPurify.sanitize(props.article.Title))}</NavLink>
            </Heading>

            <PrimaryText>
                By: {props.article.Author.Name}
            </PrimaryText>

            <NavLink className="next-arrow" to={RoutePaths.ReadArticle(props.article.UrlTitle)}>
                Continue Reading
            </NavLink>
        </Container>
    )
}

export default NextAricle;