import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { IAccordionItem } from "../../Models/IAccordionItem";
import { NavLink } from "react-router-dom";

import Heading from "../../Components/UI/Text/Heading";
import styled from "styled-components";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import Accordion from "../../Components/UI/Accordions/Accordion";
import { Colours } from "../../Constants/Colours";
import { RoutePaths } from "../../Constants/RoutePaths";

const Container = styled.div`
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    width: 95%;

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + 'px'} ;
    }
`;

const Header = styled.div`

    text-align: center;

    h1 {
        font-size: 38px ;
        margin-bottom: 0.5rem;
    }

    h2 {
        font-size: 30px!important;
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 25px;
    }

    p {
        margin-top: 0;
    }
`;

const AccordionContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 40px ;

    p {
        padding-bottom : 20px;
    }
`;

const TosNavLink = styled(NavLink)`
    color: ${Colours.IckonicPink};
    text-decoration: none;
`;

function TermsOfUseScreen() {

    const introductionContent = (
        <>
            <Heading type={HeadingType.H2}>1.1</Heading>
            <PrimaryText >
                What these terms cover. These are the terms and conditions on which we supply digital content to you via one of our subscription services (Service(s)).
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>1.2</Heading>
            <PrimaryText>
                Why you should read them. Please read these terms carefully before subscribing to one of our Services. You should understand that by subscribing to one of our Services, you agree to be bound by these terms and conditions (Terms). These Terms tell you who we are, how we will provide the Services to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these Terms, please contact us to discuss. You should print a copy of these Terms for future reference.
            </PrimaryText>
        </>
    );

    const contactUsInfos = (
        <>
            <Heading type={HeadingType.H2}>2.1</Heading>
            <PrimaryText >
                Who we are. We are Ickonic Media Group Limited of a company registered in England and Wales. Our company registration number is 12246062 and our registered office is at New Enterprise House, St Helen's Street, Derby, DE1 3GY.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>2.2</Heading>
            <PrimaryText>
                How to contact us. You can contact us by writing to our customer service team at support@ickonic.com.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>2.3</Heading>
            <PrimaryText>
                How we may contact you. If we have to contact you regarding your account (e.g. payment authorisations, invoices, changes in password or payment method, confirmation messages, content and feature updates and other service or transactional messages) we will send all information by email to the email address you provide during sign up.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>2.4</Heading>
            <PrimaryText>
                "Writing" includes emails. When we use the words "writing" or "written" in these Terms, this includes emails.
            </PrimaryText>
        </>
    );

    const ourContractWithYou = (
        <>
            <Heading type={HeadingType.H2}>3.1</Heading>
            <PrimaryText >
                More information about the Service. We provide you with the opportunity to view television programmes, feature films, podcasts, trailers and promotional material, emagazines and/or other audio-visual content (Product). The Service we provide enables you to access Product via our website www.ickonic.com (Website), and the Service includes all the features and functionality we make available to you, and any of our user interfaces or software associated with our Service.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>3.2</Heading>
            <PrimaryText>
                How to create an account with us. You will need to create an account with us in order to be able to subscribe for a Service. You will need to submit your email address to us and create a password to be used to access that account. By creating an account with us, you warrant that:-
            </PrimaryText>
            <ul>
                <li>(a) You are legally capable of entering into binding contracts;</li>
                <li>(b) You are 18 years of age or older;</li>
                <li>(c) All registration information you submit is truthful and accurate;</li>
                <li>(d) You are responsible for maintaining the accuracy of your account information;</li>
                <li>(e) You are responsible for the confidentiality of your account and password and for restricting access to your account. You must immediately inform us of any unauthorised use of your password or account or any other breach of security.</li>
            </ul>
            <hr />
            <Heading type={HeadingType.H2}>3.3</Heading>
            <PrimaryText>
                How to order from us. Once you have created your account you can then sign up to a subscription for a Service. You will receive an e-mail from us asking you to confirm your account. Once you have confirmed your account and you have made payment for a subscription for Services.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>3.4</Heading>
            <PrimaryText>
                If we cannot accept your order. If we are unable to accept your order, we will inform you of this in writing and we will not charge you the Service fee. This might be for one of the following reasons:
            </PrimaryText>
            <ul>
                <li>(a) Because the Service is unavailable due to unexpected limits on our resources which we could not reasonably plan for;</li>
                <li>(b) Because we cannot authorise your payment;</li>
                <li>(c) Because you are not allowed to buy the Service from us; (d) because we are not allowed to sell the Service to you;</li>
                <li>(d) Because we have identified an error in the price or description of the Service.</li>
            </ul>
        </>
    );

    const subscriptionsContent = (
        <>
            <Heading type={HeadingType.H2}>4.1</Heading>
            <PrimaryText >
                Free trials. We may offer a free promotional period for the Service from time to time, enabling new subscribers to subscribe to the Service without paying a subscription fee during the free promotional period (“Free Trial”). Any Free Trial which we may offer you shall be subject to these Terms. Free trials are only available to new subscribers and we reserve the right not to provide a Free Trial to any subscriber in our sole discretion to prevent abuse of the Free Trial period. By subscribing to a Free Trial you acknowledge that at the end of your Free Trial, we will commence taking payments from your original method of payment on a monthly or annual basis (as selected by you at the time of creating your account).
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.2</Heading>
            <PrimaryText>
                How the subscription works. We offer monthly or annual subscription options. A month constitutes thirty (30) calendar days and a year constitutes three hundred and sixty-five (365) calendar days. By subscribing to one of our Services you are agreeing to pay an initial charge, followed by recurring period charges for an indefinite time until cancelled by you. You acknowledge that your subscription has an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.3</Heading>
            <PrimaryText>
                Monthly subscription Service (Monthly Service). You will pay for the monthly Service in monthly instalments.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.4</Heading>
            <PrimaryText>
                Yearly subscription Service (Yearly Service). You will pay for the Yearly Service in an upfront one-off payment.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.5</Heading>
            <PrimaryText>
                What is included in your subscription. We offer different levels of access to the Product; ‘Truth Seeker’ gives you permission to stream on one (1) device at one time, ‘Game Changer’ gives you permission to stream on two (2) devices at one time. Both subscription plans include access to all content on the Website.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.6</Heading>
            <PrimaryText>
                How your Service renews. The Service will be automatically renewed at the end of the length of the relevant subscription. You acknowledge and agree that the relevant renewal fee will be taken from your original method of payment either one (1) year or one (1) month from the original date of purchase, depending on the Service signed up to, and continue every year or month from then on until cancelled by you. We cannot refund the Service fee once it has been paid and refunds cannot be claimed for any partial-month or partial-year payment.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.7</Heading>
            <PrimaryText>
                How the Service may change. We may vary the Service fees from time to time by posting new charges on our Website. However, such variations will not affect Services that have already been paid for.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.8</Heading>
            <PrimaryText>
                If you want to change your Service. You can find the specific details regarding your Service at any time via your account. If you wish to make a change to the Service you have ordered (for example you want to update your level of access to Product from ‘Truth Seeker’ to ‘Game Changer’ please contact us) please contact us. We will let you know if the change is possible. If it is possible we will let you know about any changes to the price of the Service, the timing of supply or anything else which would be necessary as a result of your requested change and ask you to confirm whether you wish to go ahead with the change. If we cannot make the change or the consequences of making the change are unacceptable to you, you may want to end the contract (see clause 7).
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>4.9</Heading>
            <PrimaryText>
                Gift subscription. A Yearly Service may be purchased as a gift for a family member or friend (Gift). Gifts are paid for as a one-off upfront payment. Gifts do not include a Free Trial and will not auto renew. There are no refunds or other credits for Gifts that are not redeemed.
            </PrimaryText>
        </>
    );

    const providingProductContent = (
        <>
            <Heading type={HeadingType.H2}>5.1</Heading>
            <PrimaryText >
                When we will provide the Product. We will provide you with access to the Product as soon as we receive your payment for a Service. We will supply the Product to you until either the period of the relevant Service ends or you end the contract as described in clause 7 or we end the contract by written notice to you as described in clause 9.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>5.2</Heading>
            <PrimaryText>
                Technical requirements. We accept no responsibility for any lack of functionality of the Website, the Service and/or for any failure to provide the Product that is due to your equipment (including, but not limited to, your computer, internet connection, operating system or settings and software). It is your responsibility to ensure that that the hardware and software requirements of your computer or device mean that you can access the Product.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>5.3</Heading>
            <PrimaryText>
                We are not responsible for delays outside our control. If our supply of the Product is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay caused by the event, but if there is a risk of substantial delay you may contact us to end the contract.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>5.4</Heading>
            <PrimaryText>
                Reasons we may suspend access to the Product. We may have to suspend access to the Product. We have the right to add or withdraw Product at any time, with or without notice. You acknowledge and agree that the Product is variable and will change from time to time without notice. We may also update or upgrade the Product or Website from time to time to:
            </PrimaryText>
            <ul>
                <li>(a) deal with technical problems or make minor technical changes;</li>
                <li>(b) update the Product to reflect changes in relevant laws and regulatory requirements;</li>
                <li>(c) make changes to the Product as requested by you or notified by us to you (see clause 10.1).</li>
            </ul>
            <PrimaryText>
                We will not be liable to you if the Product is unavailable for a commercially reasonable period of time.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>5.5</Heading>
            <PrimaryText>
                Your rights if we suspend access to the Product. We will contact you in advance to tell you we will be suspending your access to the Product, unless the problem is urgent or an emergency. We will restore the Product as soon as we reasonably can. In the event that the Product is unavailable, please see your rights to cancel in clause 7.5.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>5.6</Heading>
            <PrimaryText>
                We may also suspend access to the Product if you do not pay. If you do not pay us the Service fee when you are supposed to (see clause 11.4) and you still do not make payment of the Service fee within seven (7) days of us reminding you that it is due, we may suspend your access to the Product until you have paid us the outstanding amounts. We will contact you to tell you we are suspending your access to the Product. We will not suspend your access to the Product where you dispute an unpaid invoice (see clause 11.5).
            </PrimaryText>
        </>
    );

    const ownershipContent = (
        <>
            <Heading type={HeadingType.H2}>6.1</Heading>
            <PrimaryText >
                How you will use the Service and/or Product. When you stream Product, you will not own it. Instead we grant you a limited, non-exclusive, non-transferable, licence to access and stream the Product for the purpose of you using and enjoying it according to these Terms.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>6.2</Heading>
            <PrimaryText>
                You acknowledge and agree that the Service and/or Product:
            </PrimaryText>
            <ul>
                <li>(a) is personal to you. You can use it wherever you want in the world but only if you comply with local laws;</li>
                <li>(b) is non-exclusive to you. We may supply the same or similar Product to other users;</li>
                <li>(c) may be used on up to five (5) devices on your account and you will be able to stream the Product on one (1) device at a time.</li>
                <li>(d) may not be: </li>
                <li>(i) copied by you; (ii) changed or modified by you; (iii) distributed, transmitted, displayed or sold by you to any third party; (iv) used for commercial purposes.</li>
                <li>(e) is owned and operated by us or third parties or both. You must not conceal, change or remove any markings which show who owns this information, such as copyright (©), registered trade mark (®) or unregistered trademark (™) markings.</li>
                <li>(f) except where you have permission to use the Product under this clause 6.2, you will not obtain any rights of ownership or other rights (of whatever nature) in the Product.</li>
                <li></li>
            </ul>
            <Heading type={HeadingType.H2}>6.3</Heading>
            <PrimaryText>
                We make no warranties or representations that your use of content and information contained the Product will not infringe rights of third parties. If you believe that your intellectual property rights have been infringed, it is your obligation to notify us immediately. We accept no liability for the infringement of any intellectual property right or any infringement of a third party’s intellectual property rights however we will endeavour to correct and protect any rights owner through reasonably appropriate action should any infringement come to light.
            </PrimaryText>
        </>
    );

    const rightsToEnd = (
        <>
            <Heading type={HeadingType.H2}>7.1</Heading>
            <PrimaryText >
                Ending your Free Trial. You may cancel your Free Trial, and the Service will continue until the end of such Free Trial. There will be no refunds available or any reimbursement for any unused time period remaining from your Free Trial following cancellation by you of the Service, except in accordance with your statutory rights. If you do not cancel in accordance with clause 8.1 below at least two (2) days before the end of the Free Trial, we will commence taking payments from your payment method on a monthly or annual basis (as selected by you at the time of registration).
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.2</Heading>
            <PrimaryText>
                Ending your Monthly Service. You may cancel a Monthly Service at any time. Cancellation is effective at the end of the current paid monthly period and you can continue to use the Product until the end of the current paid monthly period. We must receive your notice to cancel the Monthly Service at least two (2) days before the end of the current paid monthly period. If notice of your intention to cancel the Monthly Service is not received by us at least two (2) days before the end of the current paid monthly period, then your subscription will automatically renew for one (1) month and will terminate at midnight on the last day of that further paid monthly period.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.3</Heading>
            <PrimaryText>
                Ending your Yearly Service. You may cancel a Yearly Service at any time. However cancellation is effective at the end of the applicable paid yearly period and refunds cannot be claimed for any partial subscription period. We must receive your notice to cancel the Yearly Service at least two (2) days before the end of the current paid yearly period. If notice of your intention to cancel the Yearly Service is not received by us at least two (2) days before the end of the current paid yearly period, then your subscription will automatically renew for one (1) year and will terminate at midnight on the last day of that further paid yearly period.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.4</Heading>
            <PrimaryText>
                Exercising your right to change your mind (Consumer Contracts Regulations 2013). You have a legal right to change your mind within fourteen (14) days of your order for a Service (Cooling-off Period). If you are exercising your right to change your mind within fourteen (14) days of ordering a Service, we will refund you for the unused paid period from the date you told us you had changed your mind.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.5</Heading>
            <PrimaryText>
                Your right to cancel if we change the price of the Service. If we notify you of a price change and you do not want to continue to with the Service at the new price, you can cancel the Service either: (i) before the start of the next payment period by following the steps in clause 8.1 below; or (ii) at any time before the price change becomes effective by providing us with notice at the details set out in clause 8.1, below.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.6</Heading>
            <PrimaryText>
                You right to cancel if we make suspend access to the Product and/or make material changes to the Service. We reserve the right to suspend your access to the Product. You can cancel your Service before the next Monthly or Yearly Subscription Service period begins. If we have to make a materially adverse change to the Service, we endeavour to give you 30 days’ notice. If you do not cancel your Service after we have notified you about any changes and before these changes take place, we will take that as your acceptance of the changes.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>7.7</Heading>
            <PrimaryText>
                Your right to cancel if we tell you about an upcoming change to these Terms which you do not agree with. We may make amendments to these Terms to reflect changes in the law or in how we conduct our business. If any change to these Terms will materially disadvantage you, or materially reduce the Service provided, we endeavour to give you at least thirty (30) days’ notice before the changes become effective and you can choose to cancel your Service before the changes become effective. If you do not cancel your Service following any change to these Terms, we will take that as your acceptance of the changes.
            </PrimaryText>
        </>
    );

    const howToEnd = (
        <>
            <Heading type={HeadingType.H2}>8.1</Heading>
            <PrimaryText >
                Tell us you want to end the contract. Just stopping using the Service doesn’t cancel your contract with us. To end the contract with us, please let us know by doing one of the following:
            </PrimaryText>
            <ul>
                <li>(a) Cancel via your account. You can manage your Service in your account. Go to the ‘Settings’ page and follow the instructions.</li>
                <li>(b) Contact us. Please email us at support@ickonic.com, Please provide your name, home address, details of the order and, where available, your phone number and email address.</li>
            </ul>
            <hr />
            <Heading type={HeadingType.H2}>8.2</Heading>
            <PrimaryText>
                How we will refund you. We will refund you the price you paid for the Service by the method you used for payment. However, we may make deductions from the price, as described below.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>8.3</Heading>
            <PrimaryText>
                Deductions from refunds if you are exercising your right to change your mind. If you are exercising your right to change your mind within the Cooling-off Period we may deduct from any refund an amount for the supply of the Service for the period for which it was supplied, ending with the time when you told us you had changed your mind. The amount will be in proportion to what has been supplied, in comparison with the full coverage of the contract.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>8.4</Heading>
            <PrimaryText>
                When your refund will be made. We will make any refunds due to you as soon as possible. If you are exercising your right to change your mind within the Cooling-off Period then your refund will be made within fourteen (14) days of your telling us you have changed your mind.
            </PrimaryText>
        </>
    );

    const ourRightsToEnd = (
        <>
            <Heading type={HeadingType.H2}>9.1</Heading>
            <PrimaryText >
                We may end the contract if you break it. We may end the contract and therefore either terminate or suspend your access to the Service at any time if:
            </PrimaryText>
            <ul>
                <li>(a) you do not make any payment to us when it is due and you still do not make payment within three (3) days of us reminding you that payment is due;</li>
                <li>(b) we consider that you are in breach of any obligation under these Terms. A breach of these Terms, includes without limitation, any use of the Product as set out in clause 6.2.</li>
            </ul>
            <PrimaryText>
                Such termination or suspension of Service may be immediate and without notice to you.
            </PrimaryText>
        </>
    );

    const ifThereIsAProblem = (
        <>
            <Heading type={HeadingType.H2}>10.1</Heading>
            <PrimaryText >
                How to tell us about problems. If you have any questions or complaints about the Service or Product, please contact us. You can write to us at support@ickonic.com.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>10.2</Heading>
            <PrimaryText>
                Summary of your legal rights. The Consumer Rights Act 2015 gives you certain legal rights (also known as ‘statutory rights’), for example, that the Service:
            </PrimaryText>
            <PrimaryText>
                (a) is of satisfactory quality; (b) is fit for purpose; and (c) matches its description.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>10.3</Heading>
            <PrimaryText>
                We must provide you with a Service that complies with your statutory rights. Nothing in these Terms is intended to affect your statutory rights. For more information about your statutory rights contact your local Citizens Advice Bureau, Trading Standards Office or Consumer Protection Office.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>10.4</Heading>
            <PrimaryText>
                When we supply the Product:
            </PrimaryText>
            <ul>
                <li>(a) we will use all reasonable efforts to ensure that it is free from defects; viruses and other malicious content; and</li>
                <li>(b) we do not promise that it is compatible with any third-party software or equipment except where we have said that it is on our Website; and</li>
                <li>(c) you acknowledge that there may be minor errors or bugs in it.</li>
            </ul>
        </>
    );

    const priceAndPaymentContent = (
        <>
            <Heading type={HeadingType.H2}>11.1</Heading>
            <PrimaryText >
                Where to find the price for the Service. The price of the Service (which includes VAT) will be the price indicated on the Website at the time when you signed up to a Service and may be varied by us from time to time on reasonable notice to you. We take all reasonable care to ensure that the price of the Service advised to you on our Website is correct.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>11.2</Heading>
            <PrimaryText>
                We will pass on changes in the rate of VAT. If the rate of VAT changes between your order date and the date we supply the Product, we will adjust the rate of VAT that you pay, unless you have already paid for the Service in full before the change in the rate of VAT takes effect.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>11.3</Heading>
            <PrimaryText>
                When you must pay and how you must pay. You can pay using a credit or debit card through our secure third party payment service provider, Stripe. We do not accept cash. Your credit card or debit card will be charged when we confirm your order has been accepted and the Service fee will be due. You can update your payment method at any time via your account by going to the ‘Settings’ section and updating your payment method. We do not store or have access to your credit or debit card details.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>11.4</Heading>
            <PrimaryText>
                Secure payments. All our payments are processed securely by Stripe. n the absence of negligence on our part, we will not be legally responsible to you for any loss that you may suffer if a third party gains unauthorised access to any information that you provide to any third party payment service provider.
            </PrimaryText>
        </>
    );

    const ourResponsibility = (
        <>
            <Heading type={HeadingType.H2}>12.1</Heading>
            <PrimaryText >
                What we are responsible to you for: If we fail to comply with these Terms, we shall only be liable to you for loss that is a reasonably foreseeable consequence of such a breach. Loss is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen. Our total liability to you for any loss or damage arising out of or in connection with these Terms shall be limited to the sum of the Service fee in question paid by you in the twelve (12) month period preceding the date on which the claim arose.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>12.2</Heading>
            <PrimaryText>
                We do not accept any liability for loss or damage incurred by you or loss of data from your computer system that results from:
                (a) your negligence; (b) your breach of these Terms; (c) anything which is beyond our reasonable control which prevents us from fulfilling our obligations under these Terms and includes, but is not limited to, fire, flood, storm, riot, civil disturbance, war, nuclear accident, terrorist activity and acts of God.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>12.3</Heading>
            <PrimaryText>
                We are not liable for business losses. We only supply the Product for domestic and private use. If you use the Product for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>12.4</Heading>
            <PrimaryText>
                Nothing in this clause 12 or otherwise in these Terms shall exclude or in any way limit our liability for: fraud; death or personal injury caused by negligence; or liability to the extent the same may not be excluded or limited as a matter of law.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>12.5</Heading>
            <PrimaryText>
                You agree to compensate us against the reasonable costs we incur in defending claims or legal proceedings brought against us by any other person as a result of your breach of these Terms (and that may include money we pay to them to settle claims). If we take legal action against you for any breach of these Terms and a court makes an award in our favour, you will be responsible for all costs allowable by the courts.
            </PrimaryText>
        </>
    );

    const disclaimer = (
        <>
            <Heading type={HeadingType.H2}>13.1</Heading>
            <PrimaryText >
                The information contained in and/or content of the Product supplied represents the views and opinions of the original creators of such Product and does not necessarily represent the views or opinions of Ickonic Media Group Limited or any of its officers, staff or employees. The mere supply of Product does not constitute an endorsement by us or our affiliates of any such views and opinions contained in such Product.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>13.2</Heading>
            <PrimaryText>
                The Products and their content are otherwise provided on an “as is” and “as available” basis and we do not make any representation or warranties with respect to the accuracy, applicability, fitness, or completeness of the Product. Further we do not warrant the performance, effectiveness or applicability of any websites listed or linked to in any Product.
            </PrimaryText>
        </>
    );

    const howWeMayUseInformation = (
        <>
            <Heading type={HeadingType.H2}>14.1</Heading>
            <PrimaryText >
                How we may use your personal information. Your privacy and personal information are important to us. Any personal information that you provide to us in the course of your use of the Product or when you create your account with us will be dealt with in line with our <TosNavLink to={RoutePaths.PrivacyPolicy}>Privacy Policy</TosNavLink>, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities in the event you have a query or complaint about the use of your personal information.
            </PrimaryText>
        </>
    );

    const otherImnportantTerms = (
        <>
            <Heading type={HeadingType.H2}>15.1</Heading>
            <PrimaryText >
                We may transfer this agreement to someone else. We may transfer our rights and obligations under these Terms to another organisation.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.2</Heading>
            <PrimaryText>
                You need our consent to transfer your rights to someone else (except that you can always transfer our guarantee). You may only transfer your rights or your obligations under these Terms to another person if we agree to this in writing.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.3</Heading>
            <PrimaryText>
                Nobody else has any rights under the contract (except someone you pass your guarantee on to). The contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract or make any changes to these Terms.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.4</Heading>
            <PrimaryText>
                If a court finds part of these Terms illegal, the rest will continue in force. Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.5</Heading>
            <PrimaryText>
                Even if we delay in enforcing these Terms, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaking these Terms, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss payment of the Service fee and we do not chase you but we continue to provide the Service, we can still require you to make payment of the missed Service fee at a later date.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.6</Heading>
            <PrimaryText>
                Which laws apply to the contract and where you may bring legal proceedings. These Terms are governed by English law and you can bring legal proceedings in respect of the Service in the English courts. If you live in Scotland you can bring legal proceedings in respect of the Service in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the Service in either the Northern Irish or the English courts.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>15.7</Heading>
            <PrimaryText>
                Alternative dispute resolution. Alternative dispute resolution is a process where an independent body considers the facts of a dispute and seeks to resolve it, without you having to go to court. If you are not happy with how we have handled any complaint, you may want to contact the alternative dispute resolution provider. In addition, please note that disputes may be submitted for online resolution to the European Commission Online Dispute Resolution platform.
            </PrimaryText>
        </>
    );

    const liveEventsContent = (
        <>
            <Heading type={HeadingType.H2}>16.1</Heading>
            <PrimaryText >
                Can I watch Live Events? Existing subscribers may have a right to attend or watch Live Events as part of their subscription.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.2</Heading>
            <PrimaryText>
                What if I don’t have an Ickonic account? If you do not have an Ickonic account, then you will need to provide us with your name and an email address for use to access to attend or watch an upcoming Live Event. Alternatively you can create an account with us. Please see Clause 3, which sets out how to create an account with us.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.3</Heading>
            <PrimaryText>
                Purchasing a ticket to a Live Event. The price for the Live Event ticket will be detailed on the Events page at the time of booking. Accepted methods of payments include Visa, Mastercard and American Express, debit and credits cards.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.4</Heading>
            <PrimaryText>
                Cancellation of Live Events. In the event of the cancellation of a Live Event, a full refund for the ticket price paid will be processed within fourteen (14) days of the cancellation. Refunds may take five (5) to ten (10) working days to reach your bank account.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.5</Heading>
            <PrimaryText>
                Changes to Live Events. In the event of a change of venue or change of major details within the Live Event, such as a change to the main speaker, then a refund option will be offered, if you no longer want to attend a Live Event after being notified of a change to the Live Event then you will need to redeem the refund option within seven (7) days of the announcement of such chance.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.6</Heading>
            <PrimaryText>
                If I set up an Ickonic account, can I watch a Live Event and then cancel my subscription for the Service? You have a legal right to change your mind within fourteen (14) days of your order for a Service (“Cooling-off Period”). If you are exercising your right to change your mind within the Cooling-off Period and the Live Event has been watched or attended within the Cooling-off Period, we will refund you for the unused Service. We reserve the right to deduct a sum from the refunded amount to include the price of a ticket to the Live Event.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.7</Heading>
            <PrimaryText>
                Changes to Live Events out of our control. Any Live Event with an audience that is subject to change depending on changing government guidance and regulation will be subject to change out of our control.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>16.8</Heading>
            <PrimaryText>
                Exercising your right to change your mind (Consumer Contracts Regulations 2013). You have a legal right to change your mind within fourteen (14) days of your order for a service or product (Cooling-off Period). We will refund you for the unused service within 14 days of the purchase. Exercising your right is only effective before the date of the event.
            </PrimaryText>
        </>
    );

    const refferalScheme = (
        <>
            <Heading type={HeadingType.H2}>17.1</Heading>
            <PrimaryText >
                How to participate in the Ickonic Referral Scheme. To participate as a referrer in the Ickonic Referral Scheme, you must:
            </PrimaryText>
            <ul>
                <li>(a) be aged 18 or over;</li>
                <li>(b) already have an account with us and have an active subscription for the Service;</li>
                <li>(c) have received a unique referral link from a current member of our team via email [or via your account] which you can share with your friends and colleagues as much as you want.</li>
            </ul>
            <hr />
            <Heading type={HeadingType.H2}>17.2</Heading>
            <PrimaryText>
                What is a “Successful Referral”. A referral will be a “Successful Referral” where:
            </PrimaryText>
            <ul>
                <li>(a) A new customer clicks on your unique referral link and creates an account with us and subscribes to the Service.</li>
                <li>(b) A new customer is a customer that:</li>
                <li>(i) does not already have an account with us; and (ii) as not previously had an account with us.</li>
                <li>(c) You have received an email notification that your referral is validated and therefore a “Successful Referral”.</li>
                <li>(d) (d) We will determine at our sole discretion and have final say as to whether a Successful Referral has been made.</li>
            </ul>
            <Heading type={HeadingType.H2}>17.3</Heading>
            <PrimaryText>
                Successful Referral Reward. You are eligible to receive [insert] (“Reward”) for each Successful Referral you make. Each new customer that is the result of a Successful Referral will also be eligible to receive the Reward. We reserve the right to substitute another voucher or item of equal value to the Reward. The Reward may not be sold, transferred, auctioned or used for commercial gain.
            </PrimaryText>
            <Heading type={HeadingType.H2}>17.4</Heading>
            <PrimaryText>
                Changes to the Ickonic Referral Scheme. We reserve the right to amend, withdraw or restrict the Ickonic Referral Scheme at any time, with or without notice. To the fullest extent permissible by law, we will not be liable for any loss or damage whatsoever which is suffered or sustained as a result of participation in the Ickonic Referral Scheme or use of the Reward.
            </PrimaryText>
        </>
    );

    const competitionContent = (
        <>
            <Heading type={HeadingType.H2}>18.1</Heading>
            <PrimaryText>
                Closing Date. The prize draw is eligible to anyone who received our survey email, who has previously held an Ickonic subscription in the past and aged 18 years or over on the date of entry. You must complete the survey by 11.02.2022 11:59pm (UK time).
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.2</Heading>
            <PrimaryText>
                How to Enter. You may enter the Prize Draw by clicking on the link provided in the newsletter emailed to you. You must complete all questions accurately and provide your email address. Any surveys with missing information or junk character answers will be disqualified.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.3</Heading>
            <PrimaryText>
                How the Winner is Chosen. Winners are chosen at random.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.4</Heading>
            <PrimaryText>
                Prize Details. 1st Prize will win a free 3-month subscription with Ickonic. 2nd and 3rd prize will win a free 1-month subscription with Ickonic.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.5</Heading>
            <PrimaryText>
                How We Will Contact You. Winner's will be contacted via email on 15.02.2022.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.6</Heading>
            <PrimaryText>
                Subscription details. The subscription will be gifted to the winner's account unless agreed otherwise within 7 days of the competition end date. The subscription prize is only eligible to the competition winner and cannot be gifted.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.7</Heading>
            <PrimaryText>
                Entry Restrictions. Entry restrictions require the same details to be followed as section 3.2 within the Terms of Use.
            </PrimaryText>
            <hr />
            <Heading type={HeadingType.H2}>18.8</Heading>
            <PrimaryText>
                If you hold a current subscription agreement with us under a different account. Full or prorated refunds cannot be requested for any current subscription under a different email address/account.
            </PrimaryText>
        </>
    );

    const tosContent: IAccordionItem[] = [
        { Title: "1. Introduction", Content: introductionContent },
        { Title: "2. Information about us and how to contact us", Content: contactUsInfos },
        { Title: "3. Our contract with you", Content: ourContractWithYou },
        { Title: "4. Subscriptions", Content: subscriptionsContent },
        { Title: "5. Providing the products", Content: providingProductContent },
        { Title: "6. Ownership, use and intellectual property rights", Content: ownershipContent },
        { Title: "7. Your rights to end the contract", Content: rightsToEnd },
        { Title: "8. How to end the contract with us (including if you have changed your mind)", Content: howToEnd },
        { Title: "9. Our rights to end the contract", Content: ourRightsToEnd },
        { Title: "10. If there is a problem with the service and/or product", Content: ifThereIsAProblem },
        { Title: "11. Price and payment", Content: priceAndPaymentContent },
        { Title: "12. Our responsibility for loss and damage suffered by you", Content: ourResponsibility },
        { Title: "13. Disclaimer", Content: disclaimer },
        { Title: "14. How we may use your personal information", Content: howWeMayUseInformation },
        { Title: "15. Other important terms", Content: otherImnportantTerms },
        { Title: "16. Live events", Content: liveEventsContent },
        { Title: "17. Ickonic Referral Scheme", Content: refferalScheme },
        { Title: "18. Competition", Content: competitionContent },
    ];

    return (
        <Container>
            <Header>
                <Heading>Ickonic Media Group</Heading>
                <Heading type={HeadingType.H2}>
                    Terms of Use
                </Heading>
                <PrimaryText>Last updated: June 2021</PrimaryText>
            </Header>
            <AccordionContainer>
                <Accordion
                    data={tosContent}
                />
            </AccordionContainer>
        </Container>
    );
}

export default TermsOfUseScreen;