import React, { useState } from "react";
import { Colours } from "../../../Constants/Colours";
import { IAccordionItem } from "../../../Models/IAccordionItem";
import styled from "styled-components";
import AccordionItem from "./AccordionItem";

const AccordionContainer = styled.div`
    color: ${Colours.Text};
    border-radius: 5px;
    border: 2px solid #242424;
`;

function Accordion(props : {
    data: IAccordionItem[],
    multiToggle?: false | true,
}){

    const [openRowIndex, setOpenRowIndex] = useState<number>(-1);
    
    function newOpenRowIndex(value : number) {
        setOpenRowIndex(value);
    }

    return (
        <AccordionContainer>
            {props.data.map((data, index) => {
                return(
                    <React.Fragment key={index}>
                        <AccordionItem
                            data={data}
                            setOpenRowIndex={newOpenRowIndex}
                            index={index}
                            currentOpenRowIndex={openRowIndex}
                            multiToggle={props.multiToggle}
                        />
                    </React.Fragment>
                )
            })}
        </AccordionContainer>
    )
}

export default Accordion;