import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Colours } from "../../../Constants/Colours";
import PrimaryText from "../Text/PrimaryText";
import ImageLazyLoad from "../ImageLazyLoad";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { Fonts } from "../../../Constants/Fonts";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import React from "react";

const Container = styled.div`
    padding: 2rem 1rem;
    background: #191919;
    .sub {
        font-weight: bold;
    }

    h2 {
        margin: 0 0 2rem 0;
    }

    li,
    p {
        margin: 10px 0 15px 0;
        font-family: ${ Fonts.Primary };
        font-size: 12pt;
        line-height: 1.5;
        color: ${ Colours.Text };
        &:last-of-type {
            margin: 0;
        }
    }

    li::marker {
        color: ${ Colours.IckonicPink };
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        padding: 3rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        padding: 6rem 3rem;
    }  
`;

const ImageContainer = styled.div`
    height: calc(56vw - 22px);
    max-width: 776px;
    max-height: 394px;
    margin: 0 auto;

    > div {
        background-color: transparent;
    }
    
    div {
        height: 100%;
        width: 100%;
    }
    
    img {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        height: calc(28vw - 20px);
        max-height: 385px;
        margin: 0;
        width: calc(50% - 1.5rem);
    }
`;

const TextContainer = styled.div`
    max-width: 700px;
    margin: 2rem auto 0 auto;
    order: 1;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        margin: 0 auto 2rem auto;
        order: 0;
        margin: 0;
        width: calc(50% - 1.5rem);
    }
`;

const ContentContainer = styled.div`
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        flex-direction: row;
        justify-content: space-between;
        
    }
`;

const ButtonContainer = styled.div`
    a,
    button {
        margin-top: 2rem;
    }
`;

function PanelImage(props: { panel: IPanelDTO; freeDaysTrial: number }) {
    const properties = props.panel.Properties;

    const background = properties.find(
        (x) => x.PropertyType === PanelPropertyType.Image
    );

    return (
        <Container>
            <ContentContainer className="panel-50-50__content-container">
                <TextContainer>
                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.Title
                    ).map((property, index) => {
                        if (index === 0) {
                            return (
                                <Heading key={"T" + property.Id} type={ HeadingType.H2 }>
                                    {property.Value.replace(
                                        "{freetrialdays}",
                                        props.freeDaysTrial.toString()
                                    )}
                                </Heading>
                            );
                        }

                        return (
                            <PrimaryText class={"sub"} key={"T" + property.Id}>
                                {property.Value.replace(
                                    "{freetrialdays}",
                                    props.freeDaysTrial.toString()
                                )}
                            </PrimaryText>
                        );
                    })}

                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.BulletPoints
                    ).map((property, index) => {
                        const buttonText = property.Value.split(";");

                        return (
                            <ul key={"i" + index}>
                                {buttonText.map((text) => {
                                    return <li key={text}>{text}</li>;
                                })}
                            </ul>
                        );
                    })}

                    {props.panel.Properties.filter(
                        (x) => x.PropertyType === PanelPropertyType.Text
                    ).map((property) => {
                        return (
                            <PrimaryText key={"T" + property.Id}>
                                  {parse(
                                    DOMPurify.sanitize(
                                        property.Value.replace(
                                            "{freetrialdays}",
                                            props.freeDaysTrial.toString()
                                        )
                                    )
                                )}
                            </PrimaryText>
                        );
                    })}

                    <ButtonContainer>
                        {props.panel.Properties.filter(
                            (x) =>
                                x.PropertyType === PanelPropertyType.ButtonText
                        ).map((property) => {
                            return (
                                <NavLinkPinkButtonFat
                                    key={property.Id}
                                    to={RoutePaths.CreateYourAccount}
                                >
                                    {property.Value.replace(
                                        "{freetrialdays}",
                                        props.freeDaysTrial.toString()
                                    )}
                                </NavLinkPinkButtonFat>
                            );
                        })}
                    </ButtonContainer>
                </TextContainer>

                {background ? (
                    <ImageContainer>
                        <ImageLazyLoad source={background.Value} />
                    </ImageContainer>
                ) : null}
            </ContentContainer>
        </Container>
    );
}

export default PanelImage;
