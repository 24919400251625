import React, { useEffect } from "react";
import styled from "styled-components";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import { RxCross2 } from "react-icons/rx";
import { Colours } from "../../../Constants/Colours";

const Background = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition-duration: 300ms;
    transition-property: visibility, opacity;
    transition-timing-function: ease-in-out;
    z-index: 1000;

    &.active{
        visibility: visible;
        opacity: 1;
    }
`;

const Container = styled.div`
    flex-basis: 60%;
    padding: 20px;
    border-radius: 10px;
    background: #1D1D1D;
    max-width: 60%;
    max-height: 60%;
    overflow-y:auto; 
    overflow-x:hidden; 


    @media only screen and (max-width: 800px) {
        flex-basis: 85%;
        max-height: 90vh;
        max-width: 80%;
        overflow: scroll;
    }

    > p{
        text-align: center;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > h1{
        text-align: center;
        width: 100%;
        font-size: 26pt;
        margin: 0 0 1rem 0;
    }

    svg {
        color: ${Colours.Text};
        cursor: pointer;
    }
`;

function Modal(props: {
    display: boolean,
    setDisplay: (value: boolean) => void,
    title: string,
    subText?: string | (string | undefined)[],
    children?: React.ReactNode,
    closeOnClickOutside?: boolean,
    displayClosebutton?: boolean,
    testId?: string
}) {

    const closeOnClickOutside: boolean = props.closeOnClickOutside ?? true;
    const displayCloseButton: boolean = props.displayClosebutton ?? true;

    useEffect(() => {

        if (props.display) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.display]);

    function ContainerOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        //Overrides parent on click
        e.stopPropagation();
    }

    function HandleCrossButtonClick() {
        props.setDisplay(false);
    }

    function HandleOutsideClick() {
        if (closeOnClickOutside) {
            props.setDisplay(false);
        }
    }

    return (
        <Background className={props.display ? "active" : ""} onClick={HandleOutsideClick}>
            <Container onClick={ContainerOnClick} data-testid={props.testId}>
                <TitleContainer>
                    <Heading>{props.title}</Heading>
                    {displayCloseButton ? <RxCross2 onClick={HandleCrossButtonClick} /> : null}
                </TitleContainer>
                {props.subText !== undefined ? (
                    Array.isArray(props.subText) ? (
                        props.subText.map((text, i) => {
                            return <PrimaryText key={ i }>{text}</PrimaryText>;
                        })
                    ) : (
                        <PrimaryText>{props.subText}</PrimaryText>
                    )
                ) : null}
                {props.children !== undefined ? props.children : null}
            </Container>
        </Background>
    );
}

export default Modal;