import { Colours } from "../../../../Constants/Colours";
import { useState, useEffect } from "react";
import { GetLatestFour } from '../../../../Api/Video';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from '../../../../Constants/RoutePaths';
import { AddToPersistentStorage } from '../../../../Helpers/Utility';
import { LocalStorageKeys } from '../../../../Constants/LocalStorageKeys';
import { HowManyDifferentInArray } from '../../../../Helpers/Utility';

import styled from "styled-components";
import DropDownDialog from '../../DropDowns/DropDownDialog';
import PrimaryText from '../../Text/PrimaryText';
import playIcon from '../../../../Assets/Icons/play-icon.png';
import moment from 'moment';
import lscache from "lscache";
import { HeaderProperties } from "../../../../Constants/HeaderProperties";
import { IconAlertBell } from "../../../../Assets/Icons/Icons";

import ILatestContentDTO from '../../../../Models/DTOs/ILatestContentDTO';
import { AxiosError } from 'axios';
import { Ease } from "../../../../Constants/EasingCurves";
import { Fonts } from "../../../../Constants/Fonts";

const HeaderContainer = styled.div`
    color: ${Colours.Text};
    font-size: 28px;
    position:relative;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
`;

const Container = styled.div`
  position:relative;  
`;

const NotificationCount = styled.span<{display?: number}>`
    display: ${p => p.display === 1 ? 'block' : 'none'};
    right: 12px;
    top: 12px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    line-height: 20px;
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
    background: ${Colours.IckonicPink};
    font-family: ${ Fonts.Primary };
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

const ContentContainer = styled.div`
    padding:12px;
    width: 250px;
    box-shadow: rgb(27, 27, 27) 0px 20px 30px -10px;

    > p {
    //text-transform: uppercase;
    color: #f0f0f0;
    width: 100%;
    font-weight: 400;
    font-size: 14pt;
    width: 100%;
    text-align: left;
    //border-bottom: 2px solid ${Colours.IckonicPink};
    padding: 8px 12px;
    margin: 0 0 4px 0;
  }
`;

const PlayIcon = styled.img`
    width: 20px;
    height: 20px;
    padding: 0;
    position: absolute;
    top: calc(50% - 10px);
    right: 12px;
    transform: scale(1);
    opacity: 0.4;
    transition: opacity .45s ${ Ease.Smooth }, transform .45s ${ Ease.Smooth };
`;

const LatestTitle = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
`;

const LatestContent = styled(NavLink)`
    text-decoration: none;
    position: relative;
    padding: 12px 40px 12px 12px;
    border-radius: 5px;
    display: block;
    margin-bottom: 8px;
    transition: background .15s ${ Ease.Smooth };
    &:last-child {
        margin: 0;
        &:after {
            display: none;
        }
    }

    
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(100% - 24px);
        left: 12px;
        bottom: 0;
        background: rgba(255,255,255,0.065);
    }
    
    
    &:hover,
    &:active {
        background: rgba(255,255,255,0.05);
        img {
            transform: scale(1.2);
            //top: calc(50% - 23px);
            opacity: 1;
        }
        
        & > div {
            //transform: translateX(30px);
        }
    }
`;

const LatestSingleItemWrapper = styled.div`
    
`;

const DateContainer = styled.span`
    font-family: ${ Fonts.Primary };
    font-size: 9pt;
    color: #bbb;
       box-sizing: border-box;
    display: flex;
    //border-bottom: 1px solid ${Colours.SecondaryHighlight};
`;

const styledIcon = `
  cursor: pointer;
  width: 30px;
  padding: 5px;
  margin: 0 12px 0 3px;
  height: ${ HeaderProperties.height };
  box-sizing: border-box;
`;

const AlertIcon = styled.div`
  ${ styledIcon }
  svg {
    * {
      fill: #bcbcbc;
      transition: fill .15s ${ Ease.Smooth };
    }
  }
  
  &:hover,
  &:active {
      svg {
        * {
          fill: white;
        }
      }
  }
`;

function Notifications() {

    const controller = new AbortController();
    const [isActive, setIsActive] = useState<boolean>(false);
    const [latestFour, setLatestFour] = useState<ILatestContentDTO[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [notifyNumber, setNotifyNumber] = useState<number>(0);

    useEffect(() => {

        if (isLoaded) {
            return;
        }

        async function FetchLatest() {
            const result = await GetLatestFour(controller);

            let data;

            if(result instanceof AxiosError){
                data = [] as ILatestContentDTO[];
            }
            else{
                data = result;
            }

            setLatestFour(data);

            const cachedMovies = lscache.get(LocalStorageKeys.LatestMovies);
            let latestFourIds : number[] = data.map(x => x.Id);
            let differences = HowManyDifferentInArray(cachedMovies, latestFourIds);
            setNotifyNumber(differences);
        }

        FetchLatest();
        setIsLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function OnActiveToggle(value: boolean) {
        setIsActive(value);
    }

    function HeaderClick() {
        const ids : number[] = latestFour.map(x => x.Id);
        AddToPersistentStorage(LocalStorageKeys.LatestMovies, ids);
        setNotifyNumber(0);
    };

    return (
        <Container>
            <DropDownDialog
                isActive={isActive}
                setIsActive={OnActiveToggle}
                Header={
                    <>
                        <HeaderContainer onClick={HeaderClick}>
                            <AlertIcon>
                                <IconAlertBell />
                            </AlertIcon>

                            <NotificationCount display={notifyNumber > 0 ? 1 : 0}>{ /**notifyNumber**/ }</NotificationCount>
                        </HeaderContainer>
                    </>
                }>

                <ContentContainer>
                    <PrimaryText>Latest</PrimaryText>
                        { latestFour.map((content, index) => {
                            return (
                                <LatestContent key={index} onClick={() => {OnActiveToggle(false)}} to={RoutePaths.Watch(content.Id)}>
                                    <PlayIcon src={playIcon}/>
                                    <LatestSingleItemWrapper>
                                        <LatestTitle>
                                            <PrimaryText>{content.Title}</PrimaryText>
                                        </LatestTitle>
                                        <DateContainer>{moment(content.ReleaseDate).format("DD/MM/YYYY").toString()}</DateContainer>
                                    </LatestSingleItemWrapper>
                                </LatestContent>
                            );
                        }) }
                </ContentContainer>

            </DropDownDialog>
        </Container>
    );
}

export default Notifications;
