import { AxiosError } from "axios";
import { useContext, useState } from "react";
import styled from "styled-components";
import { AddExtraDays } from "../../../Api/Stripe";
import AuthContext from "../../../Store/auth-context";
import GreyButton from "../Buttons/GreyButton";
import PinkButton from "../Buttons/PinkButton";
import ErrorText from "../Text/ErrorText";
import PrimaryText from "../Text/PrimaryText";
import SuccessText from "../Text/SuccessText";

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;

    > p{
        text-align: center;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    float: right;

    button:first-child {
        flex-basis: 35%;
    }
    button:last-child {
        flex-basis: 65%;
    }
`;


function ExtraDays(props: {setExtraDays: (value: boolean) => void}){
    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [Success, setSuccess] = useState<boolean>(false);
    const [allowsMessages, setAllowsMessages] = useState<boolean>(false);

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsLoading(true);

        const result = await AddExtraDays(authCtx.UserDetails.AspNetUserId, controller);

        let success;

        if(result instanceof AxiosError){
            success = false;
        }
        else{
            success = result;
        }

        authCtx.Update(authCtx.UserDetails.Access_Token);
        
        setSuccess(success);
        setAllowsMessages(true);
        setIsLoading(false);
    }

    return (
        <Container onSubmit={OnSubmit}>
            <PrimaryText>Have seven extra days on us!</PrimaryText>
            {allowsMessages ? (
                Success ? (
                    <SuccessText>
                        Seven extra days were added to your trial.
                    </SuccessText>
                ) : (
                    <ErrorText>
                        Failed to add extra days. Please contact support
                    </ErrorText>
                )
            ) : null}
            <ButtonContainer>
                <GreyButton
                    disabled={isLoading}
                    onClick={() => props.setExtraDays(false)}
                    type={"button"}
                >
                    No, I wish to cancel
                </GreyButton>
                <PinkButton type={"submit"} disabled={isLoading || Success}>
                    Redeem Extra Days
                </PinkButton>
            </ButtonContainer>
        </Container>
    );
}

export default ExtraDays;