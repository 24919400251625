import { useContext, useState } from "react";
import styled from "styled-components";
import { Colours } from "../../../../Constants/Colours";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../../../Store/auth-context";
import DropDownDialog from "../../DropDowns/DropDownDialog";
import { RoutePaths } from "../../../../Constants/RoutePaths";
import { IconProfile, IconExit, IconLifeRing, IconGear } from "../../../../Assets/Icons/Icons";
import { Ease } from "../../../../Constants/EasingCurves";
import { Fonts } from "../../../../Constants/Fonts";

const Container = styled.div`
    position:relative;
`;

const Text = styled.div`
    color: ${Colours.Text};
    text-decoration: none;
    font-family: ${ Fonts.Primary };
    font-size: 10pt;
    text-transform: uppercase;
    align-self: center;
    margin-right: 8px;
    transition: color .15s ${ Ease.Smooth };
`;

const MenuLink = styled(NavLink)`
    color: ${Colours.Text};
    padding: 15px;
    font-size: 10pt;
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
    transition: all .25s ${ Ease.Smooth };
    border-radius: 5px;
    &:hover,
    &:active {
        background: rgba(255,255,255,0.05);
    }
    
    svg {
        transform: translateX(-4px);
    }
`;

const MenuLi = styled.li``;

const MenuUl = styled.ul`
    list-style: none;
    margin: 0px;
    padding: 16px;
`;

const MenuIcon = styled.span`
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    display: block;
    svg {
        padding: calc(2rem/16);
        * {
            fill: white;
        }
    }
`;

const MenuA = styled.a`
    color: ${Colours.Text};
    padding: 15px;
    font-size: 10pt;
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 0 1px ${ Colours.ErrorDark };
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    transition: background .25s ${ Ease.Smooth };
    &:hover,
    &:active {
        background: ${ Colours.ErrorDark };
        
    }
`;

const StyledIcon = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: black;
    //box-shadow: 0 0 0 2px #777;
    padding: 0;
    margin: 1px 0 0 0;
    transform: translateY(-1px);
    box-shadow: 0 0 0 2px #333;
    transition: box-shadow .45s ${ Ease.LateSnap }, background .45s ${ Ease.Smooth };
    span {
        font-family: ${ Fonts.Primary };
        line-height: 1;
        display: block;
        font-weight: bold;
        width: 100%;
        color: #afafaf;
        text-align: center;
        height: 27px;
        width: 27px;
        padding: 6px 0 0 0;
       
    }
    
    svg {

            * {
                transition: fill .25s ${ Ease.Smooth };
                fill: #151515;
            }
        }
`;

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1px 9px 1px 11px;
    border-radius: 5px;
    height: calc(100% - 20px);
    margin: 10px 1px 0 6px;
    box-shadow: 0 0 0 1px rgba(197,61,194, 0.7);
    transition: box-shadow .25s ${ Ease.Smooth };
    overflow: hidden;
    
    // targeting the direct child avoids styling the 
    // uppercase first letter of first name.
    & > div {
        line-height: 1;
        &:first-child {
            position: relative;
            z-index: 1;
            transition: color .15s ${ Ease.Smooth } .15s; 
        }
        
    }
    
    &:hover,
    &:active {
        box-shadow: 0 0 0 2px rgba(197,61,194, 0.8);
         svg {
            * {
                fill: black;
            }
        }
        
        & > div {
            &:first-child {
                //color: black;
            }
            
            &:nth-child(2) {
                //background: black;
                box-shadow: 0 0 0 90px rgba(197,61,194, 0.8);
            }
        }
    }
`;

function ProfileButton() {

    const authCtx = useContext(AuthContext);

    // Accessing UserDetails from AuthContext
    const { UserDetails } = authCtx;
    const [isActive, setIsActive] = useState<boolean>(false);
    const navigate = useNavigate();

    function OnActiveToggle(value: boolean) {
        setIsActive(value);
    }

    async function OnLogout(){
        await authCtx.LogOut();
        navigate(RoutePaths.Root);
    }

    return (
        <Container>
            <DropDownDialog
                isActive={isActive}
                setIsActive={OnActiveToggle}
                Header={
                    <StyledWrapper>
                        <Text>Account</Text>

                        <StyledIcon>
                            {
                                UserDetails.Person && UserDetails.Person?.FirstName ?
                                    <span>{ UserDetails.Person?.FirstName.substring(0, 1).toUpperCase() }</span>
                                    :
                                    <IconProfile />
                            }
                        </StyledIcon>
                    </StyledWrapper>
                }
            >
                <MenuUl>
                    <MenuLi>
                        <MenuLink onClick={() => OnActiveToggle(false)} to={RoutePaths.Account}>
                            <MenuIcon>
                                <IconGear />
                            </MenuIcon>

                            Settings
                        </MenuLink>
                    </MenuLi>
                    <MenuLi>
                        <MenuLink onClick={() => OnActiveToggle(false)} to={RoutePaths.HelpCentre}>
                            <MenuIcon>
                                <IconLifeRing />
                            </MenuIcon>

                            Help Centre
                        </MenuLink>
                    </MenuLi>
                    <MenuLi>
                        <MenuA onClick={OnLogout} data-testid={"logoutBtn"}>
                            <MenuIcon>
                                <IconExit />
                            </MenuIcon>

                            Log Out
                        </MenuA>
                    </MenuLi>
                </MenuUl>

            </DropDownDialog>
        </Container>
    );
}

export default ProfileButton;
