import {useState} from "react";
import {Params, redirect, useParams} from "react-router-dom";
import styled from "styled-components";
import SubAlert from "../../../Components/UI/Account/SubAlert";
import ResetPasswordForm from "../../../Components/UI/Forms/ResetPasswordForm";
import Heading from "../../../Components/UI/Text/Heading";
import SuccessText from "../../../Components/UI/Text/SuccessText";
import {RoutePaths} from "../../../Constants/RoutePaths";
import {UnauthOnly} from "../../../Helpers/RouteAuth";
import {SetTitle} from "../../../Helpers/Utility";
import {UserSnapshot} from "../../../Store/auth-context";
import {Colours} from "../../../Constants/Colours";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import Card from "../../../Components/UI/Card";
import {HeadingType} from "../../../Models/Enums/HeadingType";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };
    
    > div {
        margin: 75px 0 35px 0;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        @media screen and (min-height: calc(1000em/16)) {
            padding: 0 0 5vh 0;
        }
    }
`;

const FormContainer = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    hr {
        border-color: #7d7d7d;
        margin: 0;
    }

    h2 {
        font-size: 2rem;
        margin: 0 0 1rem 0;
    }

    > p {
        font-size: 11pt;
        margin: 0;
        line-height: 1.5;
    }
`;

function ResetPasswordScreen() {
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const params = useParams();
    const code = params.code;

    if(code === null || code === undefined){
        return null;
    }

    function SetFormSuccess(value: boolean) {
        setFormSuccess(value);
    }

    return (
        <Container>
            <Card>
                <FormContainer>
                    <Heading type={ HeadingType.H2 }>Reset your password</Heading>

                    {code === null || code === undefined ? null : (
                        <ResetPasswordForm
                            code={code}
                            success={formSuccess}
                            setSuccess={SetFormSuccess}
                        />
                    )}

                    <SubAlert>
                        Password must contain at least 8 characters (both upper and lowercase), a number and 'special' character (e.g. !@#$%^&*).
                    </SubAlert>

                    {formSuccess ? (
                        <SuccessText>
                            You can now login to your account using your new password.
                        </SuccessText>
                    ) : null}
                </FormContainer>
            </Card>
        </Container>
    );
}

export default ResetPasswordScreen;

export async function Loader({ params }: { params: Readonly<Params<string>> }) {
    const userDetails = await UserSnapshot;
    const code = params.code;
    const responce = UnauthOnly(userDetails);

    if( code === null || code === undefined || code.length <= 0){
        return redirect(RoutePaths.Login());
    }

    if (responce !== undefined) {
        return responce;
    }

    SetTitle("Reset Password");

    return null;
}
