import axios, { AxiosError } from "axios";
import IAuthor from "../Models/DTOs/IAuthorDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.author;

export async function GetFrontPageAuthors(abortController: AbortController) {
    const responce = await axios.get(BACKEND_URL + "GetFrontPageAuthors",{signal: abortController.signal}).then((result) => {
        return result.data as IAuthor[];
    }).catch((error: AxiosError) => {
        console.log("GetFrontPageAuthors");
        return error;
    });

    return responce
};