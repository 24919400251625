import { PaymentMethod } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { forwardRef, useContext, useState } from "react";
import styled from "styled-components";
import { AddPaymentMethod } from "../../../Api/Stripe";
import { Colours } from "../../../Constants/Colours";
import { PaymentType } from "../../../Models/Enums/PaymentType";
import AuthContext from "../../../Store/auth-context";
import StripeCardForm from "../Forms/StripeCardForm";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const TypeContainer = styled.div`
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;

    > h1 {
        cursor: pointer;
        transition-duration: 200ms;
        transition-property: color, opacity;
        transition-timing-function: ease-in-out;
    }

    > h1.active {
        color: ${Colours.IckonicPink};
    }

    > h1:hover {
        opacity: 0.5;
        color: ${Colours.IckonicPink};
    }
`;

const SelectPaymentMethodExternal = forwardRef(
    (props: {
        setSelectedPaymentMethod?: (paymentMethod: string) => void;
        CardLast4?: string;
        hideExisting?: boolean;
        onSubmit?: (newPaymentmethod?: PaymentMethod) => Promise<void>;
        setIsValid?: (value: boolean) => void;
        setLoading?: (value: boolean) => void;
    }, ref: React.ForwardedRef<HTMLFormElement>) => {
        const authCtx = useContext(AuthContext);
        const controller = new AbortController();
        const [paymentType, setPaymentType] = useState<PaymentType>(
            PaymentType.New
        );

        function OnPaymentTypeClick(type: PaymentType) {
            setPaymentType(type);

            switch (type) {
                case PaymentType.New:
                    break;
                case PaymentType.Existing:
                    if (props.setIsValid !== undefined) {
                        props.setIsValid(true);
                    }
                    break;
                case PaymentType.None:
                default:
                    break;
            }
        }

        async function AddStripePaymentMethod(paymentMethod: PaymentMethod) {
            const result = await AddPaymentMethod(
                authCtx.UserDetails.AspNetUserId,
                paymentMethod.id,
                controller
            );

            if (result instanceof AxiosError) {
                return false;
            }

            await authCtx.Update(authCtx.UserDetails.Access_Token);

            if (props.setSelectedPaymentMethod !== null && props.setSelectedPaymentMethod !== undefined) {
                props.setSelectedPaymentMethod(paymentMethod.id);
            }

            return result;
        }

        function GetComponent() {
            switch (paymentType) {
                case PaymentType.Existing:
                    if (props.hideExisting) {
                        return null;
                    }

                    if (
                        props.CardLast4 !== undefined &&
                        props.CardLast4.length > 0
                    ) {
                        return (
                            <PrimaryText>
                                Use card ending in: {props.CardLast4}
                            </PrimaryText>
                        );
                    }
                    return null;
                case PaymentType.New:
                    return (
                        <StripeCardForm
                            ref={ref}
                            setLoading={props.setLoading}
                            setIsValid={props.setIsValid}
                            OnSubmit={props.onSubmit}
                            setPaymentMethod={AddStripePaymentMethod}
                            hideSubmitBtn={true}
                        />
                    );
                default:
                case PaymentType.None:
                    return null;
            }
        }

        return (
            <Container>
                <TypeContainer>
                    <Heading
                        className={
                            paymentType === PaymentType.New
                                ? "active"
                                : undefined
                        }
                        onClick={() => OnPaymentTypeClick(PaymentType.New)}
                    >
                        New Card
                    </Heading>

                    {props.hideExisting === true ? null : (
                        <Heading
                            className={
                                paymentType === PaymentType.Existing
                                    ? "active"
                                    : undefined
                            }
                            onClick={() =>
                                OnPaymentTypeClick(PaymentType.Existing)
                            }
                        >
                            Existing Card
                        </Heading>
                    )}
                </TypeContainer>
                {GetComponent()}
            </Container>
        );
    }
);

export default SelectPaymentMethodExternal;
