import Modal from "./Modal";
import { useState } from 'react';
import styled from "styled-components";
import ImageLazyLoad from "../ImageLazyLoad";
import { RoutePaths } from "../../../Constants/RoutePaths";
import NavLinkPinkButton from "../Buttons/NavLinkPinkButton";

const Container = styled.div`
    max-width: 560px;

    div > h1 {
        margin-left:auto;
        margin-right:auto;
    }

    div > a {
        font-size: 15pt;
        margin-top:20px;
        max-width: 700px;
        margin-left:auto;
        margin-right:auto;
    }

    div > img {
        max-height: 400px;
        max-width: 100%;
        width: auto;
        margin-left:auto;
        margin-right:auto;
        border-radius: 5px;
    }
`;

function MarketingPopup() {

    const [display, setDisplay] = useState<boolean>(true);
    
    return (
        <Container>
            <Modal
                display={display}
                setDisplay={setDisplay}
                title="Streaming Now"
                closeOnClickOutside={true}
                displayClosebutton={true}
                testId={"marketing-popup"}
            >
                <ImageLazyLoad source="https://ickesaimage.blob.core.windows.net/compressed/promo65962b30-52c4-40ba-a5bf-37204b6eb23c.jpg"/>
                <NavLinkPinkButton to={RoutePaths.Watch(1801)}>WATCH NOW</NavLinkPinkButton>
            </Modal>
        </Container>
    );
}

export default MarketingPopup;