import { Helmet } from "react-helmet";

function SharingMetatags(props: {
    url: string,
    type: string,
    title: string,
    image: string,
    description: string
}) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
            <meta name="twitter:site" content="@ickonic" data-react-helmet="true" />
            <meta name="twitter:title" content={props.title} data-react-helmet="true" />
            <meta name="twitter:description" content={props.description} data-react-helmet="true" />
            <meta name="twitter:image" content={props.image} data-react-helmet="true" />
            <meta name="twitter:image:alt" content={props.title} data-react-helmet="true" />

            <meta property="og:url" content={props.url} data-react-helmet="true"/>
            <meta property="og:type" content={props.type} data-react-helmet="true" />
            <meta property="og:locale" content="en_GB" data-react-helmet="true" />
            <meta property="og:title" content={props.title} data-react-helmet="true" />
            <meta property="og:image" content={props.image} data-react-helmet="true"/>
            <meta property="og:image:secure_url" content={props.image} data-react-helmet="true" />
            <meta property="og:description" content={props.description} data-react-helmet="true" />
        </Helmet>
    );
}

export default SharingMetatags;