import axios, { AxiosError } from "axios";
import ICarouselContentDTO from "../Models/DTOs/ICarouselContentDTO";
import { FilterBy } from "../Models/Enums/SearchFilters/FilterBy";
import config from "../Constants/Config";

const SEARCH_BACKEND_URL = process.env.REACT_APP_API_URL + config.search;
const SEARCH_COUNT_URL = process.env.REACT_APP_API_URL + config.searchCount;

export async function GetSearch(search: string, 
    filterBy: FilterBy, 
    isOriginal: boolean, 
    videoSkip: number, 
    seriesSkip: number, 
    countryCode: string, 
    abortController: AbortController, 
    orderBy : boolean) {

    const responce = await axios.get(SEARCH_BACKEND_URL + "/" + 
    search + "/" + 
    filterBy + "/" + 
    isOriginal + "/" + 
    videoSkip + "/" + 
    seriesSkip  + "/" + 
    countryCode + 
    "/web/"
    + orderBy, 
    {signal: abortController.signal}
    ).then((responce) => {
        return responce.data as ICarouselContentDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetSearch error");
        return error;
    });

    return responce;
}

export async function GetSearchCount(
    search: string,
    filterBy: FilterBy,
    isOriginal: boolean,
    countryCode: string,
    abortController: AbortController
) {
    const responce = await axios.get(SEARCH_COUNT_URL + "?"
    + "search=" + search + "&"
    + "filterby=" + filterBy + "&"
    + "isOriginal=" + isOriginal + "&"
    + "countryCode=" + countryCode,
    {signal: abortController.signal}
    ).then(responce => {
        return responce.data as number;
    }).catch((error: AxiosError) => {
        console.log("GetSearchCount error");
        return error;
    });

    return responce;
}