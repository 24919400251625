import styled from "styled-components";

const ItemContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-self: center;
    @media only screen and (max-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1115px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: auto !important;
    }
`;

function ListContainer(props:{children: React.ReactNode}){
    return(
        <ItemContainer>
            { props.children }
        </ItemContainer>
    )
}

export default ListContainer;