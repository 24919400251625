import { useState, useRef, useEffect } from "react";
import { Colours } from "../../../Constants/Colours";
import { IoIosArrowDown } from "react-icons/io";
import { IAccordionItem } from "../../../Models/IAccordionItem";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import Heading from "../Text/Heading";
import styled from "styled-components";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const AccordionTitle = styled.div`
    padding: 15px;
    background-color: ${Colours.Tertiary};
    font-family: ${ Fonts.Secondary };
    display: flex;
    justify-content: space-between;
    border-radius:5px;
    cursor: pointer;

    svg {
        font-size: 25px;
        transition: transform 180ms;

        &.accordion-arrow {
            transform: rotate(180deg);
        }
    }

    h3 {
        margin:0;
        user-select: none;
        font-weight: 500;
        font-size: 1.25rem;
    }
`;

const AccordionContent = styled.div`
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 500ms ${ Ease.Smooth };

    &.expanded {
        grid-template-rows: 1fr;

        > div{
            visibility: visible;
        }
    }
`;

const ChildContainer = styled.div`
    min-height: 0;
    transition: visibility 1s;
    visibility: hidden;

    font-family: ${ Fonts.Primary };
    background-color: #4b4b4b;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    border-bottom: 2px solid #242424;
    padding: 0 16px;
    user-select: none;

    img {
        width: 100%;
    }

    a {
        color: ${Colours.IckonicPink};
        text-decoration: none;
    }

`;



function AccordionItem(props : {
    data: IAccordionItem,
    index: number,
    setOpenRowIndex: (value: number) => void,
    currentOpenRowIndex: number,
    multiToggle?: false | true,
}){

    const [displayContent, setDisplayContent] = useState<boolean>(false);
    const ref = useRef(null as null | HTMLDivElement);
    useEffect(() => {
        if (!props.multiToggle && props.currentOpenRowIndex !== props.index) {
            setDisplayContent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentOpenRowIndex])

    function toggleDisplayContent() {
        props.setOpenRowIndex(props.index);
        setDisplayContent(!displayContent);
    }

    return (
        <>
            <AccordionTitle onClick={toggleDisplayContent}>
                <Heading type={ HeadingType.H3 }>{props.data.Title}</Heading>
                <IoIosArrowDown className={displayContent ? "accordion-arrow" : ""} />
            </AccordionTitle>
            <AccordionContent className={displayContent ? "expanded" : ""} ref={ref}>
                <ChildContainer>{props.data.Content}</ChildContainer>
            </AccordionContent>
        </>
    );
}

export default AccordionItem;