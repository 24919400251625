import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GetLatestArticles } from "../../../Api/Carousel";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import ArticleCarouselItem from "../../Carousels/ArticleCarouselItem";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Colours } from "../../../Constants/Colours";
import React from "react";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { AxiosError } from "axios";
import { Fonts } from "../../../Constants/Fonts";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import { HeadingType } from "../../../Models/Enums/HeadingType";

const Container = styled.div`
    padding: 2rem 1rem;
   
    & > h2,
    & > p {
        text-align: center;
        max-width: 700px;
        margin: 0 auto 1rem auto;
    }

    p {
        font-family: ${ Fonts.Primary };
        font-size: 12pt;
        line-height: 1.5;
        color: ${Colours.Text};
    }
   
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        padding: 3rem 1rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        padding: 3rem;
    }
`;

const ButtonContainer = styled.div`
    margin: 3rem 0 0 0;
    a {
        display: table;
        margin: 0 auto;
    }
`;

const ArticleContainer = styled.div`
    margin: 2rem auto 0 auto;
    max-width: 1600px;
    p {
        &:first-of-type {
            margin: 0 0 2px 0;
        }
    }
    
     & > a,
     & > div {
        box-sizing: border-box;
        display: block;
        margin: 0 0 1rem 0;
     }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        & > a,
        & > div {
            width: calc(50% - 8px);
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        & > a,
        & > div {
            width: calc(25% - 12px);
        }
    }
`;



function PanelArticles(props: { panel: IPanelDTO; freeDaysTrial: number }) {
    const isLoaded = useRef<boolean>(false);
    const controller = new AbortController();
    const [articles, setArticles] = useState<ICarouselContentDTO[]>([]);

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        async function LoadData() {
            await Promise.all([InitArticles()]);
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function InitArticles() {
        const take = 4;
        const result = await GetLatestArticles(take, controller);

        if (result === null || result === undefined || result instanceof AxiosError || result.length <= 0) {
            return;
        }

        setArticles(result);
    }

    return (
        <Container>
            {props.panel.Properties.filter(
                (x) => x.PropertyType === PanelPropertyType.Title
            ).map((property) => {
                return (
                    <Heading key={"T" + property.Id} type={ HeadingType.H2 }>
                        {property.Value.replace(
                            "{freetrialdays}",
                            props.freeDaysTrial.toString()
                        )}
                    </Heading>
                );
            })}

            {props.panel.Properties.filter(
                (x) => x.PropertyType === PanelPropertyType.Text
            ).map((property) => {
                return (
                    <React.Fragment key={"T" + property.Id}>
                        {parse(
                            DOMPurify.sanitize(
                                property.Value.replace(
                                    "{freetrialdays}",
                                    props.freeDaysTrial.toString()
                                )
                            )
                        )}
                    </React.Fragment>
                );
            })}

            <ArticleContainer>

                {
                    /**
                     Check if articles have loaded yet. Use ArticleCarouselItem in "dummy"
                     mode if not so the layout doesn't 'pop' when the promise returns articles.
                     **/
                }

                { articles && articles.length > 0 ?
                    articles.map((article) => {
                        return (
                            <ArticleCarouselItem
                                key={article.Id}
                                Content={article}
                                transparent={true}
                                isLoaded={ true }
                                borderHoverEffect={true}
                            />
                        );
                    })
                    :
                    (
                        Array.from({ length: 4 }, (_, i) => <ArticleCarouselItem key={i} isLoaded={ false } />)
                    )
                }
            </ArticleContainer>

            <ButtonContainer>
                {props.panel.Properties.filter(
                    (x) => x.PropertyType === PanelPropertyType.ButtonText
                ).map((property) => {
                    return (
                        <NavLinkPinkButtonFat key={"B" + property.Id} to={RoutePaths.BrowseArticles}>
                            {property.Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </NavLinkPinkButtonFat>
                    );
                })}
            </ButtonContainer>
        </Container>
    );
}

export default PanelArticles;
