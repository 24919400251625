import styled from "styled-components";

const HeaderContainer = styled.div`
    cursor: pointer;
    max-width: fit-content;
`;

const ChildContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0;
    height: 0;
    opacity: 0;
    border-radius: 5px;
    transition-duration: 300ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;
    flex-basis: 100%;

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        * {
            display: none;
        }
    }
`;

function DropDownPushDown(props: {
    Header: JSX.Element | JSX.Element[];
    children?: JSX.Element | JSX.Element[];
    isActive: boolean,
    setIsActive: (value: boolean) => void,
}) {

    function OnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        props.setIsActive(!props.isActive);
    }

    return (
        <>
            <HeaderContainer onClick={OnClick}>{props.Header}</HeaderContainer>
            <ChildContainer className={props.isActive ? "active" : ""}>
                {props.children}
            </ChildContainer>
        </>
    );
}


export default DropDownPushDown;