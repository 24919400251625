import { Helmet } from "react-helmet";

function MarketingScripts() {

    if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "development") {
        return null;
    }

    return (
        <Helmet>

            {/* Start of facebook domain verification */}
            <meta name="facebook-domain-verification" content="eg4owwti5nb3dcx09hiysl8vjnubds" />
            {/* End of facebook domain verification */}

            {/* Start of HubSpot Embed Code */}
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/26476912.js"></script>
            {/* End of HubSpot Embed Code */}

        </Helmet>
    );
}

export default MarketingScripts;