import axios from "axios";
import ICategoryDTO from "../Models/DTOs/ICategoryDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.category;

export async function GetCategory(id: number, abortController: AbortController) {
    const category = await axios
        .get(BACKEND_URL + "get/" + id, { signal: abortController.signal})
        .then((result) => {
            if (result == null) {
                return null;
            }

            let categoryResult = result.data as ICategoryDTO;            
            return categoryResult;
        })
        .catch((error) => {
            console.log("Get category error");
            return null;
        });        

    return category;
}