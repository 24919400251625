import { AxiosError } from "axios";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UpdateDailyNewsLetter } from "../../../Api/Account";
import AuthContext from "../../../Store/auth-context";
import PrimaryText from "../Text/PrimaryText";
import HalfForm from "./HalfForm";
import PinkTextButton from "../Buttons/PinkTextButton";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const CheckContainer = styled.div`
    margin: calc(20rem/16) 0;
    > div {
        display: flex;
        align-items: flex-start;
        input {
            margin: calc(2rem/16) calc(12rem/16) 0 0;
        }
    }
    
    p {
        cursor: pointer;
        user-select: none;
        width: calc(100% - (32rem/16));
        -webkit-user-select: none; /*Safari*/
        -moz-user-select: none; /*Firefox*/
    }
    
    button {
        width: 100%;
        text-align: right;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }rem/16)) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        > div,
        button {
            width: calc(50% - (8rem/16));
        }
    }
`;

function EditDailyNewsletter(props: { value: boolean }) {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const controller = new AbortController();
    const [dailyNewsletter, setDailyNewsletter] = useState<boolean>(
        props.value
    );

    function ToggleSpecialsOffers() {
        setDailyNewsletter(!dailyNewsletter);
    }

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsLoading(true);

        const result = await UpdateDailyNewsLetter(dailyNewsletter, authCtx.UserDetails.Access_Token, controller);

        let success;

        if (result instanceof AxiosError) {
            success = false;
        } else {
            success = true;
        }

        if (success) {
            toast.success("Success! Daily newsletter preference updated.")
        }
        else {
            toast.error("Preference failed to update");
            setIsLoading(false);
            return;
        }

        authCtx.Update(authCtx.UserDetails.Access_Token);

        setIsLoading(false);
    }
    
    return (
        <HalfForm onSubmit={OnSubmit}>
            <CheckContainer>
                <div>
                    <input
                        type={"checkbox"}
                        checked={dailyNewsletter}
                        onChange={ToggleSpecialsOffers}
                    />

                    <PrimaryText onClick={ToggleSpecialsOffers}>
                        Receive Ickonic Daily Download
                    </PrimaryText>
                </div>

                <PinkTextButton disabled={isLoading || dailyNewsletter === props.value}>
                    Update Newsletter Preference
                </PinkTextButton>
            </CheckContainer>
        </HalfForm>
    );
}

export default EditDailyNewsletter;
