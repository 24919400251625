import styled from "styled-components";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    width: 100%;

    margin-left:auto;
    margin-right:auto;
`

const Featured = styled.div`
    height: 400px;
    background-color: ${Colours.SecondaryHighlight};
`;

const ElemContainer = styled.div`
    display: flex;
    margin-bottom: 40px;
`;

const Elem = styled.div`
    height: 175px;
    width: 313px;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;
    margin: 0px 20px;
`;

const Title = styled.div`
    height: 40px;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;
    width: 40%;
    margin-left:20px;
    margin-right:auto;
    margin-top: 20px;
    margin-bottom:40px;
`;

function FeaturedLoader() {

    const { width } = useWindowDimensions();

    function DisplayElems() {
        if (width > ResponsiveBreakpoints.MobileNavbar) {
            return [...Array(5)].map((e, i) => <Elem key={i}/>);
        }
        return [...Array(2)].map((e, i) => <Elem key={i}/>);
    }

    return (
        <Container>
            <Featured/>
            <Title/>
            <ElemContainer>
                {DisplayElems()}
            </ElemContainer>
            <Title/>
            <ElemContainer>
                {DisplayElems()}
            </ElemContainer>
        </Container>
    );
}

export default FeaturedLoader;