import styled from "styled-components";

const DummyText = styled.p`
    position: relative;
    height: calc(40rem/16);
    &.is-half-width {
        width: 50%;
    }
`;

function HeadingLoader(props: { paddingTop: string }) {
    return (
        <DummyText style={ { margin: `${ props.paddingTop }px 0 4px 0` } } className="loader--side-to-side is-half-width" />
    );
}

export default HeadingLoader;