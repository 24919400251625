import styled from "styled-components";

const Container = styled.div`
    padding-top: calc(16rem/16);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calc(8rem/16);
`;

const Thumbnail = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border-radius: calc(10rem/16);
`;

const DummyText = styled.p`
    position: relative;
    height: calc(20rem/16);
    border-radius: calc(10rem/16);
    margin-top: calc(16rem/16);
    margin: 0;
    &.is-half-width {
        width: 50%;
    }
`;

function VideoSingleLoader(props: { paddingTop: string }) {
    return (
        <Container style={ { paddingTop: props.paddingTop } }>
            <Thumbnail className="loader--side-to-side" />

            <DummyText className="loader--side-to-side" />

            <DummyText className="loader--side-to-side is-half-width" />
        </Container>
    );
}

export default VideoSingleLoader;