import { GetCorrectPanelType, SetTitle } from "../Helpers/Utility";
import { PanelType } from "../Models/Enums/PanelType";
import styled from "styled-components";
import PanelApp from "../Components/UI/Panel/PanelApp";
import PanelArticles from "../Components/UI/Panel/PanelArticles";
import PanelBanner from "../Components/UI/Panel/PanelBanner";
import PanelButton from "../Components/UI/Panel/PanelButton";
import PanelImage from "../Components/UI/Panel/PanelImage";
import PanelMain from "../Components/UI/Panel/PanelMain";
import PanelPresenter from "../Components/UI/Panel/PanelPresenter";
import PanelPricing from "../Components/UI/Panel/PanelPricing";
import PanelVideo from "../Components/UI/Panel/PanelVideo";
import PanelVideoCentred from "../Components/UI/Panel/PanelVideoCentred";
import PanelFaq from "../Components/UI/Panel/PannelFaq";
import IPanelDTO from "../Models/DTOs/IPanelDTO";
import { redirect, useLoaderData } from "react-router-dom";
import { UserSnapshot } from "../Store/auth-context";
import LocalContext from "../Store/local-context";
import { useContext } from "react";
import Chatbot from "../Components/UI/Chatbots/Chatbot";
import { PanelSelector } from "../Models/Enums/PanelSelector";
import PanelImageCentered from "../Components/UI/Panel/PanelImageCentered";
import { ResponsiveBreakpoints } from "../Constants/ResponsiveBreakpoints";
import { Helmet } from "react-helmet";
import { RoutePaths } from "../Constants/RoutePaths";

const ChatContainer = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Container = styled.section`
    & > div {
        &:nth-child(2) {
            .panel-50-50__content-container {

                @media screen and (min-width: calc(${ResponsiveBreakpoints.DesktopBreakpoint}em/16)) {
                    max-width: 1690px;

                    background: rgb(16,16,16);
                    background: linear-gradient(90deg, rgba(9,9,9,1) 0%, rgba(15,15,15,1) 100%);
                    transform: translateY(-50px);
                    margin: 0 auto -50px auto;                    
                    box-sizing: border-box;
                    box-shadow: inset 0 0 0 2px rgba(197,61,194,0.4), 0 0 30px rgba(0,0,0,0.5);
                    padding: 2rem 2rem 2rem 3rem;
                    transform: translateY(-50px);
                    width: calc(100% - 4rem);
                }
            }
        }
    }
`;

function LandingScreen() {
    const { panels } = useLoaderData() as { panels: IPanelDTO[] };
    const localCtx = useContext(LocalContext);
    const freeDays = localCtx.Affiliate !== undefined ? localCtx.Affiliate.FreeTrialDays : 7;

    return (
        <Container>
            <Helmet>
                <meta property="og:image" content={"https://ickesaimage.blob.core.windows.net/images/logo.png"} />
            </Helmet>
            {panels !== null && panels !== undefined && panels.length > 0 ? (
                panels.map((panel) => {
                    switch (panel.PanelType) {
                        case PanelType.Apps:
                            return (
                                <PanelApp key={panel.Id} panel={panel} freeDaysTrial={freeDays} />
                            );
                        case PanelType.Articles:
                            return (
                                <PanelArticles
                                    key={panel.Id}
                                    panel={panel}
                                    freeDaysTrial={freeDays}
                                />
                            );
                        case PanelType.Banner:
                            return <PanelBanner key={panel.Id} panel={panel} />;
                        case PanelType.ImageCentered:
                            return <PanelImageCentered key={panel.Id} panel={panel} />;
                        case PanelType.Button:
                            return (
                                <PanelButton
                                    key={panel.Id}
                                    panel={panel}
                                    freeDaysTrial={freeDays}
                                />
                            );
                        case PanelType.Faq:
                            return (
                                <PanelFaq key={panel.Id} panel={panel} freeDaysTrial={freeDays} />
                            );
                        case PanelType.Image:
                            return (
                                <PanelImage key={panel.Id} panel={panel} freeDaysTrial={freeDays} />
                            );
                        case PanelType.Main:
                            return (
                                <PanelMain key={panel.Id} panel={panel} freeDaysTrial={freeDays} />
                            );
                        case PanelType.Presenter:
                            return (
                                <PanelPresenter
                                    key={panel.Id}
                                    panel={panel}
                                    freeDaysTrial={freeDays}
                                />
                            );
                        case PanelType.Pricing:
                            return (
                                <PanelPricing
                                    key={panel.Id}
                                    panel={panel}
                                    freeDaysTrial={freeDays}
                                />
                            );
                        case PanelType.Video:
                            return (
                                <PanelVideo key={panel.Id} panel={panel} freeDaysTrial={freeDays} />
                            );
                        case PanelType.VideoCentered:
                            return <PanelVideoCentred key={panel.Id} panel={panel} />;
                        default:
                            return null;
                    }
                })
            ) : (
                <></>
            )}
            <ChatContainer>
                <Chatbot />
            </ChatContainer>
        </Container>
    );
}

export default LandingScreen;

export async function Loader(selector: PanelSelector) {
    const userDetails = await UserSnapshot;

    if (userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId !== '')
        return redirect(RoutePaths.Browse);

    const controller = new AbortController();


    SetTitle("");

    return {
        panels: await GetCorrectPanelType(selector, userDetails, controller),
    };
}
