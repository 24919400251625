import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GetPlans } from "../../../Api/Subscription";
import { Colours } from "../../../Constants/Colours";
import { GetPlanType } from "../../../Helpers/Utility";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { ContentAccessType } from "../../../Models/Enums/ContentAccessType";
import { PlanPeriod } from "../../../Models/Enums/PlanPeriod";
import { PlanType } from "../../../Models/Enums/PlanType";
import AuthContext from "../../../Store/auth-context";
import LocalContext from "../../../Store/local-context";
import PinkButton from "../Buttons/PinkButton";
import Plan from "../Plan";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

const PlanContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    max-width: 400px;
    margin: 1rem auto 2rem auto;    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        max-width: none;
        > div {
            width: calc(50% - 16px);
        }
    }
`;

const PeriodContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    button {
        flex-grow: 1;
        font-size: 15pt;
    }

    button:not(.active) {
        background-color: ${Colours.Tertiary};
    }

    > h1 {
        cursor: pointer;
        transition-duration: 200ms;
        transition-property: color, opacity;
        transition-timing-function: ease-in-out;
    }

    > h1.active {
        color: ${Colours.IckonicPink};
    }

    > h1:hover {
        opacity: 0.5;
        color: ${Colours.IckonicPink};
    }
`;

function SelectPlan(props: {
    setSelectedPlan: (plan: IPlanDTO) => void;
    selectedPeriod: PlanPeriod;
    setSelectedPeriod: (period: PlanPeriod) => void;
    showSameLevelPlans?: boolean;
}) {
    const [plans, setPlans] = useState<IPlanDTO[]>([]);
    const [bundlePlans, setBundlePlans] = useState<boolean>(false);

    const authCtx = useContext(AuthContext);
    const localCtx = useContext(LocalContext);
    const controller = new AbortController();
    const [planPeriods, setPlanPeriods] = useState<PlanPeriod[]>([]);
    const showSameLevelPlans : boolean = props.showSameLevelPlans ?? true;

    useEffect(() => {
        Init();   

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.Update]);

    async function Init() {

        const country =
            authCtx.UserDetails.CountryCode !== null &&
            authCtx.UserDetails.CountryCode !== undefined &&
            authCtx.UserDetails.CountryCode.length > 0
                ? authCtx.UserDetails.CountryCode
                : "GB";

        const currency =
            authCtx.UserDetails.Currency !== null &&
            authCtx.UserDetails.Currency !== undefined &&
            authCtx.UserDetails.Currency.length > 0
                ? authCtx.UserDetails.Currency
                : "GBP";

        const result = await GetPlans(country, controller, authCtx.UserDetails.AspNetUserId, currency);


        if (result === null || result instanceof AxiosError) {
            return;
        }
        let filtered = Filter(result);
        const apiPlanTypes = filtered.map((x) => x.Type).filter((v, i, a) => a.indexOf(v) === i);
        const newPlanTypes = [PlanType.TruthSeeker, PlanType.GameChanger];

        //Will tell component to bundle plans together if result contains new plans
        const shouldBundle = apiPlanTypes.some(element => newPlanTypes.includes(element));
        setBundlePlans(shouldBundle);

        setPlans(filtered);
        setPlanPeriods(
            filtered.map((x) => x.Period).filter((v, i, a) => a.indexOf(v) === i)
        );
    }

    function Filter(plans: IPlanDTO[]) {
        if (!showSameLevelPlans) {
            return RemoveSameAccessLevelPlans(plans);
        }

        switch (authCtx.UserDetails.ContentAccessType) {
            case ContentAccessType.OneDevice:
            case ContentAccessType.TwoDevices:
            case ContentAccessType.FourDevices:
            case ContentAccessType.GameChanger:
            case ContentAccessType.TruthSeeker:
                return RemovePlans(plans);
            case ContentAccessType.None:
            default:
                return plans;
        }
    }

    function RemoveSameAccessLevelPlans(plans: IPlanDTO[]) {
        switch (authCtx.UserDetails.ContentAccessType) {
            case ContentAccessType.OneDevice:
                return plans = plans.filter(x => x.Type !== 3 && x.Type !== 2);
            case ContentAccessType.TwoDevices:
                return plans = plans.filter(x => x.Type !== 0 && x.Type !== 1);
            case ContentAccessType.GameChanger:
                return plans = plans.filter(x => x.Type !== PlanType.GameChanger);
            case ContentAccessType.TruthSeeker:
                return plans = plans.filter(x => x.Type !== PlanType.TruthSeeker);
        }
        return plans;
    }

    function RemovePlans(plans: IPlanDTO[]) {
        switch (authCtx.UserDetails.Period) {
            case PlanPeriod.None:
                return plans;
            default:
                break;
        }
        const planType = GetPlanType(authCtx.UserDetails.ContentAccessType, authCtx.UserDetails.Period);

        switch(planType){
            case PlanType.MonthlyOne:
                props.setSelectedPeriod(PlanPeriod.Monthly);
                return plans.filter(x => x.Type !== PlanType.MonthlyOne);
            case PlanType.MonthlyTwo:
                props.setSelectedPeriod(PlanPeriod.Monthly);
                return plans.filter(x => x.Type !== PlanType.MonthlyOne && x.Type !== PlanType.MonthlyTwo && x.Type !== PlanType.YearlyOne);
            case PlanType.MonthlyFour:
                props.setSelectedPeriod(PlanPeriod.Yearly);
                return plans.filter(x => x.Period === PlanPeriod.Yearly && x.Type !== PlanType.YearlyOne && x.Type !== PlanType.YearlyTwo);
            case PlanType.TruthSeeker:
                props.setSelectedPeriod(PlanPeriod.Yearly);
                return plans.filter(x => x.Type === PlanType.GameChanger);
            case PlanType.Quarterly:
                props.setSelectedPeriod(PlanPeriod.Yearly);
                return plans.filter(x => x.Period === PlanPeriod.Yearly );
            case PlanType.YearlyOne:
                props.setSelectedPeriod(PlanPeriod.Yearly);
                return plans.filter(x => x.Period === PlanPeriod.Yearly && x.Type !== PlanType.YearlyOne);
            case PlanType.YearlyTwo:
                props.setSelectedPeriod(PlanPeriod.Yearly);
                return plans.filter(x => x.Period === PlanPeriod.Yearly && x.Type !== PlanType.YearlyOne && x.Type !==PlanType.YearlyTwo);
            case PlanType.YearlyFour:
            case PlanType.GameChanger:
                return [] as IPlanDTO[];
            default:
                return plans;
        }
    }

    function OnPlanClick(plan: IPlanDTO) {
        props.setSelectedPlan(plan);
    }

    function OnPeriodClick(period: PlanPeriod) {
        props.setSelectedPeriod(period);
    }

    return (
        <>
            { !bundlePlans ? (
                <PeriodContainer>
                    {planPeriods.map((period, index) => {
                        return (
                            <PinkButton
                                key={index}
                                className={period === props.selectedPeriod ? "active" : undefined}
                                onClick={() => OnPeriodClick(period)}
                            >
                                {PlanPeriod[period]}
                            </PinkButton>
                        );
                    })}
                </PeriodContainer>
            ) : null}

            <PlanContainer>
                {plans
                    .filter((x) => (!bundlePlans ? x.Period === props.selectedPeriod : x))
                    .map((plan) => {
                        return (
                            <Plan
                                key={plan.Title}
                                plan={plan}
                                onClick={OnPlanClick}
                                freeDays={
                                    authCtx.UserDetails.FreeTrialAvailable
                                        ? localCtx.Affiliate !== undefined
                                            ? localCtx.Affiliate.FreeTrialDays
                                            : 7
                                        : 0
                                }
                            />
                        );
                    })}
            </PlanContainer>
        </>
    );
}

export default SelectPlan;
