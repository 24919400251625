import { AxiosError } from "axios";
import moment from "moment";
import { Suspense, useContext, useRef, useState, useEffect } from "react";
import { Await, defer, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CheckPaymentIntentStatus, GetRenewDetails, PayInvoice } from "../../../Api/Stripe";
import SelectPaymentMethodExternal from "../../../Components/UI/Subscription/SelectPaymentMethodExternal";
import PinkButton from "../../../Components/UI/Buttons/PinkButton";
import Modal from "../../../Components/UI/Modals/Modal";
import Spinner from "../../../Components/UI/Spinner";
import Heading from "../../../Components/UI/Text/Heading";
import ErrorText from "../../../Components/UI/Text/ErrorText";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { RenewAuth } from "../../../Helpers/RouteAuth";
import { GetAuthCompleteLink, SetTitle, SymbolFromCurrency } from "../../../Helpers/Utility";
import IPayInvoiceDTO from "../../../Models/DTOs/IPayInvoiceDTO";
import IPaymentIntentResultDTO from "../../../Models/DTOs/IPaymentIntentResultDTO";
import IUnpaidDTO from "../../../Models/DTOs/IUnpaidDTO";
import { PaymentIntentResult } from "../../../Models/Enums/PaymentIntentResult";
import AuthContext, { UserSnapshot } from "../../../Store/auth-context";
import { Fonts } from "../../../Constants/Fonts";
import { PaymentMethod } from "@stripe/stripe-js";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    padding: 2% 0;
    max-height: 65%;

    > * {
        width: 100%;
    }

    > button {
        margin-top: auto;
    }

    h1 {
        font-size: 2.5rem;
        margin: 0;
    }

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + "px"};
    }

    @media only screen and (max-width: 1115px) {
        width: 95%;
        max-height: 45%;
    }
`;

const IFrameContainer = styled.div`
    width: 100%;
    min-width: 300px;
    height: 640px;
`;

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
`;

const Table = styled.table`
    width: 100%;
    height: auto;
    background: ${Colours.SecondaryHighlight};

    font-family: ${Fonts.Primary};
    color: ${Colours.Text};
    font-size: 12pt;
    margin: 0;
    line-height: 1.5;
    border-radius: 5px;

    th{
        color: white;
        text-align: start;
        padding: 5px;
    }

    td {
        text-align: start;
        padding: 5px;
    }
`;

function RenewScreen() {
    //Maps values returned from loader
    const { invoicesPromise } = useLoaderData() as {
        invoicesPromise: Promise<IUnpaidDTO>;
    };

    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const navigate = useNavigate();
    const [formValid, setFormValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [needsBankAuth, setNeedsBankAuth] = useState<boolean>(false);
    const [iFrameUrl, setIFrameUrl] = useState<string>("");
    const formRef = useRef<HTMLFormElement | null>(null);
    const renewDetails = useRef<IUnpaidDTO>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);

    useEffect(() => {
        async function on3DSComplete() {
            const result = await CheckPaymentIntentStatus(authCtx.UserDetails.AspNetUserId, controller);

            if (result === true) {
                await PaymentSucessful();
            }
            else {
                setErrorMessage(
                    "Error: Failed to verify details with your bank. Please try again or use a different card."
                );
            }
            setNeedsBankAuth(false);
            setIFrameUrl("");
        }

        if (!pageLoaded) {
            window.addEventListener('message', function (ev) {
                if (ev.data === '3DS-authentication-complete') {
                    on3DSComplete();
                }
            }, false);
            setPageLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function OnSubmit() {
        if (formRef.current !== null && formRef.current !== undefined) {
            //PayInvoice is called within this, Loading is also defined within component
            formRef.current.requestSubmit();
        } else {
            setLoading(true);
            await SubmitPayInvoice();
            setLoading(false);
        }
    }

    async function SubmitPayInvoice(newPaymentmethod?: PaymentMethod) {
        setErrorMessage(undefined);

        if (renewDetails.current === null || renewDetails.current === undefined) {
            return;
        }

        let returnUrl = GetAuthCompleteLink();

        const payInvoiceData: IPayInvoiceDTO = {
            InvoiceId: renewDetails.current.LatestUnpaidInvoice.id,
            PaymentMethodId: newPaymentmethod?.id ?? authCtx.UserDetails.PaymentMethodId,
            ReturnUrl: returnUrl,
            StripeId: authCtx.UserDetails.StripeId,
            UserId: authCtx.UserDetails.AspNetUserId,
        };

        const result = await PayInvoice(payInvoiceData, controller);

        if (!result || result instanceof AxiosError || result.PaymentIntentResult === PaymentIntentResult.Failure) {
            setErrorMessage(
                "Error: Failed to pay invoice. Please ensure you have enough funds in your account or try a new card."
            );
            return;
        }

        if (result.PaymentIntentResult === PaymentIntentResult.NeedsAuth) {
            DisplayAuthForm(result);
            return;
        }

        await PaymentSucessful();
    }

    async function PaymentSucessful() {
        toast.success("Your subscription has been successfully renewed.");
        await authCtx.Update(authCtx.UserDetails.Access_Token);
        navigate(RoutePaths.Browse);
    }

    function SetFormValid(value: boolean) {
        setFormValid(value);
    }

    function DisplayAuthForm(result: IPaymentIntentResultDTO) {
        setNeedsBankAuth(true);
        if (result.AuthReturnUrl !== undefined && result.AuthReturnUrl !== null) {
            setIFrameUrl(result.AuthUrl as string);
        }
    }

    return (
        <Suspense
            fallback={
                <LoadingContainer>
                    <Spinner fontSize={100} />
                </LoadingContainer>
            }
        >
            <Await resolve={invoicesPromise}>
                {(invoiceData) => {
                    const invoices = invoiceData as IUnpaidDTO;
                    renewDetails.current = invoices;

                    return (
                        <Container>
                            <Heading>Your payment was unsuccessful</Heading>
                            {invoices !== undefined ? (
                                <>
                                    <PrimaryText>
                                        It seems we are having trouble collecting your payment from your card
                                        ending in {invoices.CardLast4}. This could be due your bank requiring
                                        a security check to proceed,  an unauthorised payment, insufficient funds,
                                        or incorrect payment details. Please try again by entering your payment details.
                                    </PrimaryText>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Due</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {moment(
                                                        invoices.LatestUnpaidInvoice
                                                            .period_end * 1000
                                                    )
                                                        .format("Do MMMM YYYY")
                                                        .toString()}
                                                </td>
                                                <td>
                                                    {invoices !== null &&
                                                        invoices !== undefined
                                                        ? SymbolFromCurrency(
                                                            invoices.LatestUnpaidInvoice
                                                                .currency
                                                        ) +
                                                        invoices.LatestUnpaidInvoice
                                                            .amount_due /
                                                        100
                                                        : 0}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <SelectPaymentMethodExternal
                                        ref={formRef}
                                        setIsValid={SetFormValid}
                                        onSubmit={SubmitPayInvoice}
                                        setLoading={setLoading}
                                        CardLast4={invoices?.CardLast4}
                                        hideExisting={
                                            authCtx.UserDetails.PaymentMethodId === null ||
                                            authCtx.UserDetails.PaymentMethodId === undefined ||
                                            authCtx.UserDetails.PaymentMethodId.length <= 0
                                        }
                                    />

                                    {loading ? (
                                        <Spinner fontSize={50} />
                                    ) : (
                                        <PinkButton disabled={!formValid} onClick={OnSubmit}>
                                            Renew Subscription
                                        </PinkButton>
                                    )}

                                    {errorMessage !== null &&
                                        errorMessage !== undefined &&
                                        errorMessage.length > 0 ? (
                                        <ErrorText>{errorMessage}</ErrorText>
                                    ) : null}
                                </>
                            ) : null}
                            <Modal
                                title=""
                                closeOnClickOutside={false}
                                displayClosebutton={false}
                                display={needsBankAuth}
                                setDisplay={setNeedsBankAuth}
                            >
                                <IFrameContainer>
                                    <iframe title={"card"} src={iFrameUrl} width="100%" height="640px" />
                                </IFrameContainer>

                            </Modal>
                        </Container>
                    );
                }}
            </Await>
        </Suspense>
    );
}

export default RenewScreen;


export async function Loader() {
    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    async function InitInvoices() {
        const result = await GetRenewDetails(
            userDetails.AspNetUserId,
            userDetails.PaymentMethodId,
            controller
        );

        if (result === null || result === undefined || result instanceof AxiosError) {
            return redirect(RoutePaths.Root);
        }

        return result;
    }

    const responce = RenewAuth(userDetails);

    if (responce !== undefined) {
        return responce;
    }

    SetTitle("Renew Your Subscription");

    return defer({
        invoicesPromise: InitInvoices(),
    });
}