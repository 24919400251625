import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";

const Text = styled.p`
    font-family: ${ Fonts.Primary };
    color: ${Colours.SuccessText};
    font-size: 10pt;
    margin: calc(10rem/16) 0 !important;
    line-height: 1.5;
`;

function ErrorText(props: {children: string | string[] | number}){
    return(
        <Text>{props.children}</Text>
    )
}

export default ErrorText;