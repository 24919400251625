import styled from "styled-components";
import {Colours} from "../../../Constants/Colours";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import IArticleBrowseDTO from "../../../Models/DTOs/IArticleBrowseDTO";
import Heading from "../Text/Heading";
import NavLinkBlackButton from "../Buttons/NavLinkBlackButton";
import PrimaryText from "../Text/PrimaryText";
import ImageLazyLoad from "../ImageLazyLoad";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import {Ease} from "../../../Constants/EasingCurves";
import ArticleGridItemSingle from "./ArticleGridItemSingle";
import { NavLink } from "react-router-dom";
import {RoutePaths} from "../../../Constants/RoutePaths";

const
    ArticlesGrid = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem;
        > .heading {
            margin: 2rem 0 1rem 0;
            width: 100%;
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.MediumMobileBreakpoint}rem/16)) {
            .featured-articles__secondary-grouping {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                > div {
                    width: calc(50% - (8rem/16));
                }
            }
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            padding: 2rem 2rem 0 2rem;
            .featured-articles__secondary-grouping {
                > div {
                    width: calc(50% - (16rem/16));
                }
            }
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
            .featured-articles__secondary-grouping {
                width: calc(30% - (16rem/16));
                > div {
                    width: 100%;
                }
            }
        }
    `,
    ArticleFeaturedSingle = styled.div`
        width: 100%;
        margin: 0 0 2rem 0;
        p {
            margin: calc(16rem/16) 0 0 0;
            color: ${ Colours.TertiaryHighlight };
        }
        
        h3 {
            font-weight: 400;
            margin: calc(5rem/16) 0 0 0;
            font-size: 14pt;
            a {
                color: white;
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            margin: 0 0 3rem 0;
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
            width: calc(70% - (16em/16));
        }
    `,
    ImageContainer = styled(NavLink)`
        position: relative;
        overflow: hidden;
        border-radius: calc(10rem/16);
        background: ${ Colours.IckonicPinkSubtleHightlight };
        display: block;
        a,
        button {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 82px);
            opacity: 0;
            transform: translateY(20px);
            transition: transform .15s ${ Ease.LateSnap }, opacity .15s ${ Ease.LateSnap }, background .25s ${ Ease.Smooth }, box-shadow .15s ${ Ease.Smooth };
        }
        
        img {
            transition: transform .5s ${ Ease.Smooth }, opacity .5s ${ Ease.Smooth };
            transform: scale(1.02);
        }
        
        &:hover,
        &:active {
            img {
                opacity: 0.5;
                transform: scale(1.05);
            }
            
            a,
            button {
                opacity: 1;
                transform: translateY(0);
            }
        }
    `;

function FeaturedArticles(props: { Articles: IArticleBrowseDTO | undefined }) {
    const {Articles} = props;
    return (
        <ArticlesGrid>
            { Articles && Articles.Latest && Articles.Latest.length > 0 ?
                <ArticleFeaturedSingle>
                    <ImageContainer to={ RoutePaths.ReadArticle(Articles.Latest[0].Link) }>
                        <ImageLazyLoad source={Articles.Latest[0].Thumbnail} />

                        <NavLinkBlackButton>
                            Read Article
                        </NavLinkBlackButton>
                    </ImageContainer>

                    <PrimaryText>{ Articles.Latest[0].Title }</PrimaryText>
                    <Heading type={ HeadingType.H3 }>
                        <NavLink to={ RoutePaths.ReadArticle(Articles.Latest[0].Link) }>
                            { Articles.Latest[0].Text }
                        </NavLink>
                    </Heading>
                </ArticleFeaturedSingle>
                :
                null
            }

            { Articles && Articles.Latest && Articles.Latest.length > 0 ?
                <div className="featured-articles__secondary-grouping">
                    {
                        Articles.Latest.map((article, i) =>
                            i > 0 && (
                                <ArticleFeaturedSingle
                                    key={ i }
                                >
                                    <ImageContainer to={ RoutePaths.ReadArticle(article.Link) }>
                                        <ImageLazyLoad source={article.Thumbnail} />

                                        <NavLinkBlackButton>
                                            Read Article
                                        </NavLinkBlackButton>
                                    </ImageContainer>

                                    <PrimaryText>{ article.Title }</PrimaryText>
                                    <Heading type={ HeadingType.H3 }>
                                        <NavLink to={ RoutePaths.ReadArticle(article.Link) }>
                                            { article.Text }
                                        </NavLink>
                                    </Heading>
                                </ArticleFeaturedSingle>
                            )
                        )
                    }
                </div>
                :
                null
            }

            { Articles && Articles.Picks && Articles.Picks.length > 0 ?
                <>
                    <Heading
                        type={ HeadingType.H3 }
                        className="heading"
                    >
                        Staff Picks
                    </Heading>

                    {
                        Articles.Picks.map((article, i) => (
                            <ArticleGridItemSingle
                                key={ i }
                                article={ article }
                            />
                        ))
                    }
                </>
                :
                null
            }
        </ArticlesGrid>
    )
}

export default FeaturedArticles;