import { AxiosError } from "axios";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styled from "styled-components";
import { Register } from "../../../Api/Account";
import {
    ContainsAt,
    ContainsSpecialChars,
    ContainsUpperCase,
    IsNameValid,
} from "../../../Helpers/Utility";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { IRegisterDTO } from "../../../Models/DTOs/IRegisterDTO.";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import AuthContext from "../../../Store/auth-context";
import SubAlert from "../Account/SubAlert";
import PinkButton from "../Buttons/PinkButton";
import CheckBoxWithLabel from "../Inputs/CheckBoxWithLabel";
import InputWithIcon from "../Inputs/InputWithIcon";
import InputWithLabel from "../Inputs/InputWithLabel";
import Spinner from "../Spinner";
import PrimaryText from "../Text/PrimaryText";
import { Colours } from "../../../Constants/Colours";
import { NavLink } from "react-router-dom";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { Fonts } from "../../../Constants/Fonts";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.form`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
    .form__next {
        min-height: 38px;
        margin: 13px 0 22px 0;
        width: 100%;
        position: relative;
    }
    
    > p {
        margin: 1rem 0 0 0;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
    > div {
        width: 100%;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        > div {
            width: 48%;
        }
    }
`;

const Text = styled.p`
    font-family: ${ Fonts.Primary };
    color: ${Colours.Error};
    font-size: 12pt;
    margin: 0;
    line-height: 1.5;
`;

const Link = styled(NavLink)`
    color: ${Colours.IckonicPink};
    font-family: ${ Fonts.Primary };
    text-decoration: none;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-basis: 100%;
    width: 100%;
    align-items: center;
`;

function CreateUser(props: { 
    setSuccess: (value: boolean) => void,
    setLoading: (value: boolean) => void
    success: boolean 
}) {
    const controller = new AbortController();
    const authCtx = useContext(AuthContext);

    const emailInput = useRef<HTMLInputElement | null>(null);
    const firstNameInput = useRef<HTMLInputElement | null>(null);
    const passwordInput = useRef<HTMLInputElement | null>(null);
    const confirmInput = useRef<HTMLInputElement | null>(null);
    const [ commericalEmails, setCommericalEmails ] = useState<boolean>(true);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [seePassword, setSeePassword] = useState<boolean>(false);
    const [seeConfirm, setSeeConfirm] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [confirmError, setConfirmError] = useState<string>();
    const [apiError, setApiError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    function SetCommericalEmails(value: boolean) {
        setCommericalEmails(value);
    }

    function GetEmailValidState(text: string) : InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("E-mail cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("Not a valid email address");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    }

    function GetEmailKeyUpState(text: string) : InputIsValid {
        if (emailError === '' || emailError === undefined || emailError === null) {
            return InputIsValid.Valid;
        }
        return GetEmailValidState(text);
    }

    function GetFirstNameValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setNameError("First name cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!IsNameValid(text)) {
            setNameError("First name cannot be empty");
            return InputIsValid.Invalid;
        }

        setNameError(undefined);
        return InputIsValid.Valid;
    }

    function GetNewValidState(text: string) : InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setPasswordError("Password cannot be empty");
            return InputIsValid.Invalid;
        }

        if (text.length < 8 || text.trim().length < 8) {
            setPasswordError("Password must be at least 8 characters");
            return InputIsValid.Invalid;
        }

        if (!ContainsUpperCase(text)) {
            setPasswordError(
                "Password must contain at least 1 upper case character"
            );
            return InputIsValid.Invalid;
        }

        if (!ContainsSpecialChars(text)) {
            setPasswordError("Password must contain a special character");
            return InputIsValid.Invalid;
        }

        setPasswordError(undefined);
        return InputIsValid.Valid;
    }

    function GetPasswordKeyUpState(text: string) : InputIsValid {
        if (passwordError === '' || passwordError === undefined || passwordError === null) {
            return InputIsValid.Valid;
        }
        return GetNewValidState(text);
    }

    function GetConfirmValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setConfirmError("Confirm password cannot be empty");
            return InputIsValid.Invalid;
        }

        if (text !== passwordState.Value) {
            setConfirmError("Passwords do not match");
            return InputIsValid.Invalid;
        }

        setConfirmError(undefined);
        return InputIsValid.Valid;
    }

    function GetConfirmKeyUpState(text: string) : InputIsValid {
        if (confirmError === '' || confirmError === undefined || confirmError === null) {
            return InputIsValid.Valid;
        }
        return GetConfirmValidState(text);
    }

    function nameReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetFirstNameValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetFirstNameValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function emailReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetEmailKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetEmailValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function passwordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetPasswordKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetNewValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function confirmPasswordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetConfirmKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetConfirmValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [nameState, dispatchName] = useReducer(nameReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [confirmPwdState, dispatchConfirm] = useReducer(confirmPasswordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function nameChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchName({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function passwordChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchPassword({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function confirmChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchConfirm({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function validateEmailHandler() {
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function validateNameHandler() {
        dispatchName({
            Value: nameState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function validatePasswordHandler() {
        dispatchPassword({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function validateConfirmHandler() {
        dispatchConfirm({
            Value: nameState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function TogglePassword() {
        setSeePassword(!seePassword);
    }

    function ToggleConfirm() {
        setSeeConfirm(!seeConfirm);
    }

    const emailIsValid =
        emailState.IsValid === InputIsValid.Valid;

    const nameIsValid =
        nameState.IsValid === InputIsValid.Valid;

    const passwordIsValid =
        passwordState.IsValid === InputIsValid.Valid;

    const confirmIsValid =
        confirmPwdState.IsValid === InputIsValid.Valid;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(
                emailIsValid && nameIsValid && passwordIsValid && confirmIsValid
            );
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [emailIsValid, nameIsValid, passwordIsValid, confirmIsValid]);

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        props.setLoading(true);
        setApiError(undefined);

        GetEmailValidState(emailState.Value);
        GetNewValidState(passwordState.Value);
        GetConfirmValidState(confirmPwdState.Value);
        
        if (!formIsValid) {
            return;
        }

        dispatchEmail({
            Type: InputState.Input_Blur,
            Value: emailState.Value,
        } as IInputDTO);

        dispatchName({
            Type: InputState.Input_Blur,
            Value: nameState.Value,
        } as IInputDTO);

        dispatchPassword({
            Type: InputState.Input_Blur,
            Value: passwordState.Value,
        } as IInputDTO);

        dispatchConfirm({
            Type: InputState.Input_Blur,
            Value: confirmPwdState.Value,
        } as IInputDTO);

        if (!formIsValid) {
            return;
        }

        const data = {
            Email: emailState.Value,
            Firstname: nameState.Value,
            Password: passwordState.Value,
            ConfirmPassword: confirmPwdState.Value,
            AllowCommercialEmails: commericalEmails

        } as IRegisterDTO

        const result = await Register(data, controller);

        if(result === null || result instanceof AxiosError || result.length === 0){
            ResetInputs();
            setLoading(false);
            props.setLoading(false);
            props.setSuccess(false);
            setApiError("There was an issue creating your account. Please ensure your E-mail is not already in use.");
            return;
        }

        await authCtx.LogIn(emailState.Value, passwordState.Value, true);
        props.setSuccess(true);
        props.setLoading(false);
        setLoading(false);
    }

    function ResetInputs(){
        dispatchEmail({
            Type: InputState.Not_Set,
            Value: "",
        } as IInputDTO);

        dispatchName({
            Type: InputState.Not_Set,
            Value: "",
        } as IInputDTO);

        dispatchPassword({
            Type: InputState.Not_Set,
            Value: "",
        } as IInputDTO);

        dispatchConfirm({
            Type: InputState.Not_Set,
            Value: "",
        } as IInputDTO);

    }

    if(loading){
        return(
            <Spinner fontSize={"50pt"}/>
        )
    }

    return (
        <Container onSubmit={OnSubmit} id="ickonicCreateAccountForm">
            <InputContainer>
                <InputWithLabel
                    ref={emailInput}
                    placeholder={"you@example.com"}
                    type={"text"}
                    isValid={emailIsValid || emailState.IsValid === InputIsValid.NotSet}
                    value={emailState.Value}
                    onChange={emailChangeHandler}
                    onBlur={validateEmailHandler}
                    label={"Email"}
                    errorMessage={emailError}
                />

                <InputWithLabel
                    ref={firstNameInput}
                    placeholder={"What can we call you?"}
                    type={"text"}
                    isValid={nameIsValid || nameState.IsValid === InputIsValid.NotSet}
                    value={nameState.Value}
                    onChange={nameChangeHandler}
                    onBlur={validateNameHandler}
                    label={"First name"}
                    errorMessage={nameError}
                />

                <InputWithIcon
                    ref={passwordInput}
                    placeholder={"****"}
                    type={seePassword ? "text" : "password"}
                    isValid={passwordIsValid || passwordState.IsValid === InputIsValid.NotSet}
                    value={passwordState.Value}
                    onChange={passwordChangeHandler}
                    onBlur={validatePasswordHandler}
                    label={"Set Password"}
                    onIconClick={TogglePassword}
                    errorMessage={passwordError}
                >
                    {seePassword ? <FaEye /> : <FaEyeSlash />}
                </InputWithIcon>

                <InputWithIcon
                    ref={confirmInput}
                    placeholder={"Re-type password"}
                    type={seeConfirm ? "text" : "password"}
                    isValid={confirmIsValid || confirmPwdState.IsValid === InputIsValid.NotSet}
                    value={confirmPwdState.Value}
                    onChange={confirmChangeHandler}
                    onBlur={validateConfirmHandler}
                    label={"Confirm Password"}
                    onIconClick={ToggleConfirm}
                    errorMessage={confirmError}
                >
                    {seeConfirm ? <FaEye /> : <FaEyeSlash />}
                </InputWithIcon>
            </InputContainer>

            <SubAlert>
                Password must contain at least 8 characters (both upper and lowercase), a number and 'special' character (e.g. !@#$%^&*).
            </SubAlert>

            <CheckBoxWithLabel
                checked={commericalEmails}
                setChecked={SetCommericalEmails}
                text={
                    "We'll keep you up to date with the latest news, programs, releases and new features. Click to not receive these e-mails."
                }
            />

            <PrimaryText>By clicking "Next", you agree to our <Link style={{color: Colours.IckonicPinkHighlight}} to={RoutePaths.TermsOfUse}>Terms of Use</Link> including the use of cookies.</PrimaryText>

            {apiError !== undefined ? <ErrorContainer><Text>{apiError} If you already have an account please <Link to={RoutePaths.Login()}>Login</Link></Text></ErrorContainer> : null}

            <PinkButton
                className="form__next"
                disabled={ !formIsValid }
            >
                Next
            </PinkButton>
        </Container>
    );
}

export default CreateUser;
