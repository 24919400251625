import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";

const Container = styled.div`
    width: 100%;
`;

const Featured = styled.div`
    height: 400px;
    position: relative;
    background-color: ${ Colours.SecondaryDarker };
`;

function FeaturedSliderLoader() {
    return (
        <Container>
            <Featured>
                <div className="loader--side-to-side"> </div>
            </Featured>
        </Container>
    );
}

export default FeaturedSliderLoader;