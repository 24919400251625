import { AxiosError } from "axios";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ChangeEmail } from "../../../Api/Account";
import { ContainsAt } from "../../../Helpers/Utility";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import AuthContext from "../../../Store/auth-context";
import PinkButton from "../Buttons/PinkButton";
import Input from "../Inputs/Input";
import PrimaryText from "../Text/PrimaryText";
import SuccessText from "../Text/SuccessText";
import styled from "styled-components";
import SubAlert from "./SubAlert";

const Form = styled.form`
    width: 100%;
    max-width: calc(600rem/16);
    margin: 0 auto;
    > div {   
        margin: 0 0 calc(8rem/16) 0;
    }
    
    > p {
        line-height: 1;
        margin: 0 0 calc(8rem/16) 0;
    }
    
    > button {
        width: 100%;
        border-radius: calc(7rem/16);
        font-size: 12pt;
        margin-bottom: 1rem;
        padding: calc(8rem/16) calc(10rem/16);
    }
`;

function EditEmail() {
    const emailRef = useRef<HTMLInputElement | null>(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const controller = new AbortController();
    const authCtx = useContext(AuthContext);

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: authCtx.UserDetails.Email,
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const emailIsValid =
        (emailState.IsValid === InputIsValid.Valid ||
        emailState.IsValid === InputIsValid.NotSet) && errorMessage === undefined;

    function GetValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setErrorMessage("E-mail must have a value.");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setErrorMessage("E-mail is not valid.");
            return InputIsValid.Invalid;
        }

        setErrorMessage(undefined);
        return InputIsValid.Valid;
    }

    function emailReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(emailIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [emailIsValid]);

    function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    const validateFirstNameHandler = () => {
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    };

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsLoading(true);

        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        if (formIsValid && emailState.Type !== InputState.Not_Set) {
            const result = await ChangeEmail(
                emailState.Value.trim(),
                authCtx.UserDetails.Access_Token,
                controller
            );

            let success;

            if(result instanceof AxiosError){
                success = false;
                setSuccess(false);
            }
            else{
                success = result;
                setSuccess(result);
            }
            
            if (!success) {
                setErrorMessage("E-mail failed to update.");
                setIsLoading(false);
                return;
            }

            await authCtx.Update(authCtx.UserDetails.Access_Token);
        } else if (!emailIsValid) {
            emailRef?.current?.focus();
        }
        setErrorMessage(undefined);
        setIsLoading(false);
    }

    return (
        <Form onSubmit={OnSubmit}>
            <PrimaryText>E-mail:</PrimaryText>

            <Input
                ref={emailRef}
                isValid={emailIsValid}
                onBlur={validateFirstNameHandler}
                onChange={emailChangeHandler}
                defaultValue={emailState.Value}
                type={"email"}
                placeholder={"Enter E-mail here..."}
                errorMessage={errorMessage}
            />

            <PinkButton disabled={isLoading || !emailIsValid || emailState.Value === authCtx.UserDetails.Email}>Update E-mail</PinkButton>

            {success ? (
                <SuccessText>Success! E-mail changed successfully! Please check your inbox to confirm</SuccessText>
            ) : false}

            <SubAlert>
                After clicking 'Update E-mail', you'll receive an email with a
                link that you need to click to confirm the change!
            </SubAlert>
        </Form>
    );
}

export default EditEmail;
