import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    
    width: 95%;

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + 'px'} ;
    }

    margin-left:auto;
    margin-right:auto;
    margin-top:40px;
`

const Title = styled.div`
    height: 400px;
    margin: 25px 0px;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;
`;

const Elem = styled.div`
    height: 40px;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;
    width: 80%;
    margin: 40px auto;
`;

const Secondary = styled.div`
    height: 40px;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;
    width: 100%;
    margin: 40px auto;
`;


function TextLoader() {
    return (
        <Container>
            <Title/>
            <Elem/><Elem/><Elem/><Elem/>
            <Secondary/>
            <Elem/><Elem/>
        </Container>
    );
}

export default TextLoader;