import styled from "styled-components";
import { useState, useEffect } from 'react';
import IPaymentMethodInformation from "../../../Models/DTOs/IPaymentMethodInformation";
import { GetPaymentMethodInformation } from "../../../Api/Stripe";
import { AxiosError } from "axios";
import PrimaryText from "../Text/PrimaryText";

const Container = styled.div`
    display: block ;
    text-align: center;
`;

function CardDetails(props: {
    userId: string,
    paymentMethodId: string
}) {

    const controller = new AbortController();
    const [paymentMethodInfo, setPaymentMethodInfo] = useState<IPaymentMethodInformation>();

    useEffect(() => {

        async function LoadData() {
            const result = await GetPaymentMethodInformation(props.userId, props.paymentMethodId, controller);

            if (!(!result || result instanceof AxiosError)) {
                setPaymentMethodInfo(result);
            }
        }

        LoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <Container>
            {paymentMethodInfo?.Issuer !== null && paymentMethodInfo?.Issuer !== undefined ?
                (<>
                    <PrimaryText>{paymentMethodInfo?.Issuer}</PrimaryText>
                </>) :
                null}
            {paymentMethodInfo?.LastFour !== null && paymentMethodInfo?.LastFour !== undefined ?
                (<>
                    <PrimaryText>Card ending with {paymentMethodInfo?.LastFour}</PrimaryText>
                </>) :
                null}
            {paymentMethodInfo?.ExpiryMonth !== null && paymentMethodInfo?.ExpiryMonth !== undefined &&
                paymentMethodInfo?.ExpiryYear !== null && paymentMethodInfo?.ExpiryYear !== undefined ?
                (<>
                    <PrimaryText>Expiry {paymentMethodInfo?.ExpiryMonth}/{paymentMethodInfo?.ExpiryYear}</PrimaryText>
                </>) :
                null}
        </Container>
    );
}

export default CardDetails;