export enum PanelType{
    Main,
    Image,
    Video,
    Presenter,
    Pricing,
    Faq,
    Banner,
    VideoCentered,
    Button,
    Apps,
    Articles,
    ImageCentered
}