import styled from "styled-components";
import IAuthor from "../../Models/DTOs/IAuthorDTO";
import ImageLazyLoad from "../UI/ImageLazyLoad";

const Container = styled.div`
    margin: 0 auto 1rem auto;
    max-width: 249px;
`;

function AuthorCarouselItem(props: {author: IAuthor}){
    return(
        <Container>
            <ImageLazyLoad source={props.author.HeaderImg}/>
        </Container>
    )
}

export default AuthorCarouselItem;