import axios, { AxiosError } from "axios";
import IPanelDTO from "../Models/DTOs/IPanelDTO";
import { PanelSelector } from "../Models/Enums/PanelSelector";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.panel;

export async function GetPanels(liveMode: boolean, countryCode: string,  abortController: AbortController){
    const responce = await axios.get(BACKEND_URL + liveMode + "/" + countryCode,{signal: abortController.signal}).then((result) => {
        return result.data as IPanelDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetPanels error ");
        return error;
    });

    return responce
}

export async function GetLandingPanel(panelSelector: PanelSelector, abortController: AbortController){
    const responce = await axios.get(BACKEND_URL + 'GetLandingPanels/' + panelSelector,{signal: abortController.signal}).then((result) => {
        return result.data as IPanelDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetLandingPanelsBrowse error ");
        console.log(error.response);
        return error;
    });

    return responce
}