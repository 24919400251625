import styled from "styled-components";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

const Section = styled.div<{centered: number}>`
    text-align: ${p => p.centered === 1 ?  'center' : 'left'};
    margin: 0 0 2rem 0;
    h2,
    .heading {
        margin: 0 0 1rem 0;
    }
    
    > h2,
    .heading > h2 {
        font-size: 16pt;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }rem/16)) {
        display: flex;
        align-items: start;
        justify-content: space-between;
        max-width: calc(1300rem/16);
        > h2
        .heading {
            width: calc(230rem/16);
        }
        
        .account-section__inner {
            width: calc(100% - (262rem/16));
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }rem/16)) {
        margin: 2rem auto;
    }
`;

function AccountSection(props: {
    children?: JSX.Element | JSX.Element[];
    headingSibling?: JSX.Element | JSX.Element[]|null;
    title: string;
    text?: string;
    titledCentred?: boolean;
}) {

    return (
        <Section centered={props.titledCentred === true ? 1 : 0}>

            { props.headingSibling === undefined ?
                <Heading type={ HeadingType.H2 }>{props.title}</Heading>
                :
                <div className="heading">
                    <Heading type={ HeadingType.H2 }>{props.title}</Heading>

                    { props.headingSibling }
                </div>
            }

            <div className="account-section__inner">
                {props.text !== undefined && props.text.length > 0 ? (
                    <PrimaryText>{props.text}</PrimaryText>
                ) : null}

                {props.children}
            </div>
        </Section>
    );
}

export default AccountSection;
