import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";
import React from "react";

const Button = styled.button`
    color: ${Colours.Text};
    font-size: 10pt;
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    padding: 12px 15px;
    line-height: 1;
    font-weight: 600;
    border: 0;
    left: 0;
    color: ${ Colours.SecondaryDarker };
    font-size: 11pt;
    text-align: center;
    background: ${ Colours.Tertiary };
    box-shadow: 0 0 0 calc(2rem/16) ${ Colours.Tertiary };
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    transition: width .25s ${ Ease.Smooth }, left .25s ${ Ease.Smooth }, background .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth };
    box-sizing: border-box;
    :disabled {
        opacity: 0.7;
    }
    
    &:not(:disabled) {
        background: white;
        box-shadow: 0 0 0 calc(2rem/16) #ffffff;
    }
    
    svg {
        margin: 0 calc(8rem/16) 0 0;
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background: none;
        color: white;
    }
`;

function WhiteButton(props: {
    children: React.ReactNode,
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void,
    disabled?: boolean,
    type?: 'submit' | 'reset' | 'button' | undefined,
    className?: string,
    testId?: string
}){
    return(
        <Button className={props.className ?? ""} onClick={props.onClick} disabled={props.disabled} type={props.type} data-testid={props.testId}>
            {props.children}
        </Button>
    )
}

export default WhiteButton;