import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import {Fonts} from "../../../Constants/Fonts";

const CustomRadio = styled.span`
    position: absolute;
    top: 5px;
    left: 5px;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 5px;
        height: 5px;
        width: 5px;
        background-color: white;
        border-radius: 100%;
        border-width: 5px 5px 5px 5px;
    }
`;

const Container = styled.label`
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 7px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 12pt;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + ${CustomRadio} {
            background-color: ${Colours.IckonicPink};

            &:after {
                display:block;
            }
        }
    }

    &:hover ${CustomRadio} {
        background-color: ${Colours.Text};
    }
`;

const InputText = styled.span`
    color: ${Colours.Text};
    font-family: ${ Fonts.Primary };
    font-size: 12pt;
`;

function RadioButton(props : {
    id: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    label: string,
    value: string,
    name: string,
    checked?: boolean,
}) {

    return (
        <Container>
            <InputText>{props.label}</InputText>
            <input id={props.id} 
                type="radio" 
                name={props.name} 
                value={props.value} 
                onChange={props.onChange}
                checked={props.checked ?? false}

            ></input>
            <CustomRadio></CustomRadio>
        </Container>
    );
}

export default RadioButton;