import { AxiosError } from "axios";
import { useContext, useEffect, useRef } from "react";
import { Params, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetAffiliate } from "../Api/Affiliate";
import { LocalStorageKeys } from "../Constants/LocalStorageKeys";
import { RoutePaths } from "../Constants/RoutePaths";
import { AddToPersistentStorage, HoursToMinutes } from "../Helpers/Utility";
import IAffiliateDTO from "../Models/DTOs/IAffiliateDTO";
import { SubscriptionStatus } from "../Models/Enums/SubscriptionStatus";
import { UserSnapshot } from "../Store/auth-context";
import LocalContext from "../Store/local-context";

function AffiliateScreen(){
    const localCtx = useContext(LocalContext);
    const { affiliate } = useLoaderData() as {
        affiliate: IAffiliateDTO;
    };

    const navigate = useNavigate();
    const isLoaded = useRef<boolean>(false);

    useEffect(() => {

        if(isLoaded.current){
            return;
        }

        if(!affiliate.IsActive){
            toast.error("Affiliate does not exists.");
            navigate(RoutePaths.Root);
            isLoaded.current = true;
            return;
        }

        toast.success("Affiliate successfully redeemed.");
        localCtx.SetAffiliate(affiliate);
        AddToPersistentStorage(LocalStorageKeys.Affiliate, affiliate, HoursToMinutes(1))
        navigate(RoutePaths.Root);

        isLoaded.current = true;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //Does not need a style. Feedback is provided via toasts
    return(
        <></>
    )
}

export default AffiliateScreen;

export async function Loader({ params }: { params: Readonly<Params<string>> }) {
    const controller = new AbortController();
    const userDetails = await UserSnapshot;
    const link = params.link;

    if(link === null || link === undefined || link.length <= 0){
        toast.error("Affiliate does not exists.");
        return redirect(RoutePaths.Root);
    }

    const hasSub = userDetails.SubscriptionStatus === SubscriptionStatus.Active 
    || userDetails.SubscriptionStatus === SubscriptionStatus.Cancelled  
    || userDetails.SubscriptionStatus === SubscriptionStatus.Trialing 

    //If logged in or has sub
    if (
        (userDetails.AspNetUserId !== null &&
        userDetails.AspNetUserId !== undefined &&
        userDetails.AspNetUserId.length > 0 &&
        !userDetails.FreeTrialAvailable )|| hasSub
    ) {
        toast.error("You are not eligable to redeem this affiliate.");
        return redirect(RoutePaths.Root);
    }

    const affiliate = await GetAffiliate(link, controller);

    if(affiliate instanceof AxiosError){
        throw affiliate.response;
    }

    if(affiliate === null || affiliate === undefined || affiliate.Id <= 0){
        toast.error("Affiliate does not exists.");
        return redirect(RoutePaths.Root);
    }

    return ({affiliate: affiliate});
}