//import { useEffect } from 'react';
//import { IsDev } from '../../../Helpers/Utility';

function Chatbot() {

    // ADDED: Remporary disabling it as of 26/04/2023 test for other chatbot
    return null;
    /*
    function LoadChatbot() {

        const queryString = '?cid=166913449483924307-166913449493716152'
        var s = document.createElement('script');
        s.src = "https://chatwidget.software/widget/build.js" + queryString;
        s.id = "appyWidget";
        document.body.appendChild(s);
    }

    function UnloadChatbot() {
        let wrappers = document.getElementById("Appypie");
        let chatbotWrapper = document.getElementsByClassName("chatbotWrapper");

        if (wrappers !== null) {
            let parent = wrappers.parentElement;
            if (parent !== null) {
                parent.innerHTML = '';
            }
        }

        for (var i = 0; i < chatbotWrapper.length; i++) {

            chatbotWrapper[i].innerHTML = "";
            chatbotWrapper[i].remove();
        }

        let script = document.getElementById("chatbot");
        let widget = document.getElementById("appyWidget");
        if (widget !== null) {
            widget.remove();
        }
        if (script !== null) {
            script.remove();
        }
    }

    useEffect(() => {

        if (IsDev()) {
            return;
        }

        LoadChatbot();

        return () => {
            UnloadChatbot();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    return (<></>);
    */
}

export default Chatbot;