import styled from "styled-components";
import RadioButton from "../RadioButtons/RadioButton";
import { useState } from 'react';
import { primitiveToBoolean } from "../../../Helpers/Utility";
import PinkButton from "../Buttons/PinkButton";
import { UpdateCommercialEmails } from "../../../Api/Account";

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 30px;
    align-items: center;

    button {
        width: 100%;
        margin: 10px 0px;
    }
`;

function CommercialEmailForm(props: {
    token: string,
    formSubmitted: () => void,
}) {

    const [allowCommercialEmails, setAllowCommercialEmail] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const controller = new AbortController();

    function onRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value as string;
        let valueBool = primitiveToBoolean(value);
        setAllowCommercialEmail(valueBool);
    }

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setLoading(true);
        await UpdateCommercialEmails(allowCommercialEmails, props.token, controller);
        setLoading(false);
        props.formSubmitted();
    }

    return (
        <Container onSubmit={OnSubmit}>

            <RadioButton
                id="1"
                label="Yes: Sign me up!"
                onChange={onRadioChange}
                value={'1'}
                checked={allowCommercialEmails}
                name="allowCommercialEmails"
            />
            <RadioButton
                id="2"
                label="No: Maybe next time"
                onChange={onRadioChange}
                value={'0'}
                checked={!allowCommercialEmails}
                name="allowCommercialEmails"
            />

            <PinkButton disabled={loading}>Submit</PinkButton>

        </Container>
    );
}

export default CommercialEmailForm;