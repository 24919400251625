import styled from "styled-components";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    width: 100%;
    margin-left:auto;
    margin-right:auto;
`;

const ElemContainer = styled.div`
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
`;

const Elem = styled.div`
    aspect-ratio: 16 / 9;
    width: 100%;
    background-color: ${Colours.SecondaryHighlight};
    border-radius: 10px;    
`;

const ElemWrapper = styled.div`
    width: 100%;
    margin: 0px 10px;
`;

const Text = styled.div`
    height: 20px;
    width: 90%;
    background-color: ${Colours.SecondaryHighlight};
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
`;

const TextSecondary = styled.div`
    height: 10px;
    width: 40%;
    background-color: ${Colours.SecondaryHighlight};
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
`;

function InfiniteScrollLoader() {

    const { width } = useWindowDimensions();

    function DisplayElems() {
        if (width > ResponsiveBreakpoints.MobileNavbar) {
            return [...Array(5)].map((e, i) =>
                <ElemWrapper key={i}>
                    <Elem />
                    <Text />
                    <TextSecondary />
                </ElemWrapper>
            )
        }

        return [...Array(2)].map((e, i) =>
            <ElemWrapper key={i}>
                <Elem />
                <Text />
                <TextSecondary />
            </ElemWrapper>
        )
    }

    return (
        <Container>
            <ElemContainer>
                {DisplayElems()}
            </ElemContainer>
        </Container>
    );
}

export default InfiniteScrollLoader;