import styled from "styled-components";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

const Row = styled.div`
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallMobileBreakpoint }rem/16)) {
        display: flex;
        justify-content: space-between;
    }
`;

function AccountRow(props: {
    children?: JSX.Element | JSX.Element[];
}) {

    return <Row>{props.children}</Row>;
}

export default AccountRow;
