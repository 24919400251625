import { AxiosError } from "axios";
import { Suspense, useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Await, defer, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { GetFilms } from "../../../../Api/Carousel";
import CarouselItem from "../../../../Components/Carousels/CarouselItem";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import { SetTitle } from "../../../../Helpers/Utility";
import ICarouselContentDTO from "../../../../Models/DTOs/ICarouselContentDTO";
import AuthContext, { UserSnapshot } from "../../../../Store/auth-context";
import { PopUpType } from "../../../../Models/Enums/PopUpType";
import PopUp from "../../../../Components/UI/Modals/PopUp";
import Banner from "../../../../Components/UI/Banner";
import { BannerType } from "../../../../Models/Enums/BannerType";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";

const
    Container = styled.section`
        padding: 1rem;
        width: 100%;
        max-width: calc(1616rem/16);
        margin: 0 auto;
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
            padding: 1.5rem;
        }
    `,
    take = 30;

function FilmsScreenFree() {
    const { filmsPromise } = useLoaderData() as {
        filmsPromise: Promise<ICarouselContentDTO[]>;
    };

    const
        authCtx = useContext(AuthContext),
        [films, setFilms] = useState<ICarouselContentDTO[]>([]),
        [hasMore, setHasMore] = useState<boolean>(true),
        Films = async (skip: number) => {
            const result = await GetFilms(
                authCtx.UserDetails.CurrentCountryCode,
                authCtx.UserDetails.AspNetUserId,
                take,
                skip
            );

            if (result === null || result === undefined || result instanceof AxiosError || result.length <= 0) {
                setHasMore(false);
                return;
            }

            setFilms((prev) => [...prev, ...result]);
        };

    return (
        <>
            <PopUp
                canBeDismissed={true}
                apiEnum={PopUpType.Films}
                isFullScreen={true}
            />

            <Banner apiEnum={BannerType.Films} />

            <Container>
                <Suspense fallback={
                    <ListContainer>
                        {[...Array(take)].map((e, i) => (
                            <VideoSingleLoader paddingTop="0" key={i} />
                        ))}
                    </ListContainer>
                }>
                    <Await resolve={filmsPromise}>
                        {(filmsData) => {
                            const initFilms = filmsData as ICarouselContentDTO[];
                            const mergedLists = [...initFilms, ...films];

                            return (
                                <InfiniteScroll
                                    dataLength={mergedLists.length}
                                    hasMore={hasMore}
                                    next={() => Films(mergedLists.length)}
                                    loader={
                                        <ListContainer>
                                            {[...Array(5)].map((e, i) => (
                                                <VideoSingleLoader paddingTop="calc(16rem/16)" key={i} />
                                            ))}
                                        </ListContainer>
                                    }
                                >
                                    <ListContainer>
                                        {mergedLists.map((content, index) => {
                                            return (
                                                <CarouselItem
                                                    key={index.toString() + content.Id}
                                                    Content={content}
                                                    disableOverlay={true}
                                                />
                                            );
                                        })}
                                    </ListContainer>
                                </InfiniteScroll>
                            );
                        }}
                    </Await>
                </Suspense>
            </Container>
        </>
    );
}

export default FilmsScreenFree;

export async function Loader() {
    const
        userDetails = await UserSnapshot;

    async function InitFilms() {
        const result = await GetFilms(
            userDetails.CurrentCountryCode,
            userDetails.AspNetUserId,
            take,
            0
        );

        if (result === null || result instanceof AxiosError) {
            return [] as ICarouselContentDTO[];
        }

        // console.log(result);

        return result;
    }

    // Sets the html document <title>
    SetTitle("Films");

    return defer({
        filmsPromise: InitFilms(),
    });
}
