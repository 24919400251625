import { useState } from "react";
import styled from "styled-components";
import { Colours } from "../../Constants/Colours";
import {Fonts} from "../../Constants/Fonts";

const Container = styled.div``;

const Text = styled.p`
    color: ${Colours.Text};
    margin: 0;
    display: inline;
    font-family: ${ Fonts.Primary };
`;

const ReadMore = styled.a`
    cursor: pointer;
    color: ${Colours.IckonicPink};
    display: inline;
    font-family: ${ Fonts.Primary };

    :hover {
        opacity: 0.8;
    }
`;

function ReadMoreText(props: { children: string; limit: number }) {
    const [readMore, setReadMore] = useState<boolean>(false);

    function ToggleReadMore() {
        setReadMore(!readMore);
    }

    return (
        <Container>
            {props.children.length <= props.limit ? (
                <Text>{props.children}</Text>
            ) : (
                <Text>
                    {!readMore
                        ? props.children.slice(0, props.limit).trim() + "... "
                        : props.children + "... "}
                </Text>
            )}

            {props.limit < props.children.length ? (
                <ReadMore onClick={ToggleReadMore}>
                    {readMore ? "Read Less" : "Read more"}
                </ReadMore>
            ) : null}
        </Container>
    );
}

export default ReadMoreText;
