function SocialMediaButton(props: {
    link: string,
    children: React.ReactNode
}){
    return(
        <a target="_blank" href={props.link}>
            {props.children}
        </a>
    )
}

export default SocialMediaButton;