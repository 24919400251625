import { MdPlayCircleOutline } from "react-icons/md";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";


const Original = styled(MdPlayCircleOutline)`
    color: white;
    background-color: ${Colours.IckonicPink};
    font-size: 24px;
    padding: 1px;
    border-radius: 10px;
`;

export default Original;