import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GetHelpTopics from "../../../Api/HelpTopics";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { HelpTopicType } from "../../../Models/Enums/HelpTopicType";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import { IAccordionItem } from "../../../Models/IAccordionItem";
import Accordion from "../Accordions/Accordion";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Colours } from "../../../Constants/Colours";
import { AxiosError } from "axios";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    padding: 2rem 1rem;
    h2 {
        max-width: 1600px;
        margin: 0 auto 2rem auto;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        padding: 3rem 1rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        padding: 4rem 1rem;
    }  
`;

const AccordionContainer = styled.div`
    max-width: 1605px;
    margin: 0 auto;
    figure {
        @media only screen and (max-width: 1200px) {
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }

        table {
            background-color:${Colours.SecondaryHighlight}!important;
            width: 100%;
            table-layout:fixed;

            td {
                background-color: #262626 !important;
                padding-left: 20px;
                padding-right: 20px;
            }

            a {
                color: ${Colours.IckonicPink};
                text-decoration: none;
                word-break: break-all;
            }
        }
    }
`;

function PanelFaq(props: { panel: IPanelDTO, freeDaysTrial: number }) {
    const isLoaded = useRef<boolean>(false);
    const controller = new AbortController();
    const [faq, setFaq] = useState<IAccordionItem[]>([]);

    const title = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Title
    );

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        async function LoadData() {
            await Promise.all([InitFaq()]);
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function InitFaq() {
        const result = await GetHelpTopics(controller, HelpTopicType.FAQ);

        if (result === null || result === undefined || result instanceof AxiosError || result.length <= 0) {
            return;
        }

        setFaq(result.sort(x => x.Order).map<IAccordionItem>((faq) => {
            return { Title: faq.Title, Content: parse(DOMPurify.sanitize(faq.Content)) };
        }));
    }

    return (
        <Container>
            {title !== null && title !== undefined ? (
                <Heading key={"T" + title.Id} type={ HeadingType.H2 }>
                    {title.Value.replace(
                        "{freetrialdays}",
                        props.freeDaysTrial.toString()
                    )}
                </Heading>
            ) : null}
            <AccordionContainer>
                <Accordion data={faq}/>
            </AccordionContainer>
        </Container>
    );
}
export default PanelFaq;
