import { ReactNode } from "react";
import styled from "styled-components";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { Fonts } from "../../../Constants/Fonts";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { Colours } from "../../../Constants/Colours";

const H1 = styled.h1`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: 600;
    font-size: 22pt;
    line-height: 1.35;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        font-size: 25pt;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        font-size: 30pt;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        font-size: 35pt;
    }
`;

const H2 = styled.h2`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: 600;
    font-size: 18pt;
    line-height: 1.35;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        font-size: 20pt;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        font-size: 24pt;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        font-size: 27pt;
    }
`;

const H3 = styled.h3`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    font-weight: 700;
    font-size: 16pt;
    line-height: 1.35;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        font-size: 18pt;
    }
`;

const H4 = styled.h4`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: 700;
    line-height: 1.35;
`;

const H5 = styled.h5`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: 700;
    line-height: 1.35;
`;

const H6 = styled.h6`
    color: ${ Colours.TextBright };
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: 700;
    line-height: 1.35;
`;

function Heading(props: {
    children: string | ReactNode;
    type?: HeadingType;
    className?: string;
    onClick?: () => void;
    testId?: string;
}) {
    switch (props.type) {
        case HeadingType.H2:
            return (
                <H2 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H2>
            );
        case HeadingType.H3:
            return (
                <H3 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H3>
            );
        case HeadingType.H4:
            return (
                <H4 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H4>
            );
        case HeadingType.H5:
            return (
                <H5 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H5>
            );
        case HeadingType.H6:
            return (
                <H6 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H6>
            );
        case HeadingType.H1:
        default:
            return (
                <H1 className={props.className} onClick={props.onClick} data-testid={props.testId}>
                    {props.children}
                </H1>
            );
    }
}

export default Heading;
