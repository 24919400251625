import { useEffect, useRef } from "react";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import styled from "styled-components";

const Container = styled.div`
    margin: 25px;

    p {
        font-size: 25px ;
    }
`;

function Renew3DSConfirmScreen() {

    const isLoaded = useRef<boolean>(false);

    useEffect(() => {
        if (!isLoaded.current) {
            if (window?.top != null) {
                window.top.postMessage('3DS-authentication-complete');
                isLoaded.current = true;
            }
        }
    });

    return(
        <Container>
            <PrimaryText>Thank you for authenticating your card, you will be redirected shortly.</PrimaryText>
        </Container>
    );
}

export default Renew3DSConfirmScreen;