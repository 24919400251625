import React, {useState, forwardRef, useEffect} from 'react';
import styled from "styled-components";

import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';

import {
    StripeCardNumberElementOptions,
    StripeCardExpiryElementOptions,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
    StripeCardCvcElementChangeEvent
} from "@stripe/stripe-js";
import {StripeInputType} from "../../../Models/Enums/StripeInputType";
import {zipValidation} from "../../../Helpers/Utility";

interface CreditCardInputProps {
    name: string;
    value: string;
    label: string;
    onFocus: (name: string) => void;
    onBlur: (name: string) => void;
    hasError?: boolean;
    errorMessage?: string;
    required?: boolean;
    setCardComplete: (complete: boolean) => void;
    updateZipCode: (newZip: string) => void;
}

const FormCreditCard = styled.div`
    padding: calc(16rem/16) calc(14rem/16);
    margin: 0 0 2rem 0;
    background: #202020;
    border-radius: calc(3rem/16);
    box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.12);
    transition: box-shadow .25s cubic-bezier(.84,0,.13,.98);
    width: 100%;
    &:hover {
        box-shadow: inset 0 0 calc(2rem/16) calc(1rem/16) rgba(255,255,255,0.2);
    }

    &.has-error {
        box-shadow: inset 0 0 0 calc(2rem/16) #da2626;
    }

    .StripeElement {
        &#cardNumber {
            width: 100%;
            margin: 0 0 calc(10rem/16) 0;
        }

        &#expiry {
            width: 33.333334%;
        }

        &#cvc {
            width: 33.333334%;
        }

        input {
            color: #e6e6e6;
        }
    }

    .form__zip-code {
        width: 33.333334%;
        padding: 0;
        box-shadow: none !important;
        border: none;
        background: none;
        line-height: 1;
        height: calc(16.8rem/16);
        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
            outline: none;
            border: none;
        }
    }

    @media screen and (min-width: 36.5em) {
        .StripeElement {
            &#cardNumber {
                width: calc(100% - (160rem / 16));
                margin: 0;
            }

            &#expiry {
                width: calc(70rem / 16);
            }

            &#cvc {
                width: calc(40rem / 16);
            }
        }

        .form__zip-code {
            width: calc(50rem / 16);
            transform: translateY(calc(-0.5rem/16));
        }
    }
`;

const InputsStripe = forwardRef<HTMLInputElement, CreditCardInputProps>(({
    name,
    value,
    label,
    onFocus,
    onBlur,
    hasError,
    errorMessage,
    required,
    setCardComplete,
    updateZipCode
}, ref) => {
    const

        /**
         * Local state
         */
        [focusedInput, setFocusedInput] = useState<string>(""),
        [stripeCardNumberComplete, setStripeNumberComplete] = useState<boolean>(false),
        [stripeExpiryComplete, setStripeExpiryComplete] = useState<boolean>(false),
        [stripeCardCvcComplete, setStripeCvcComplete] = useState<boolean>(false),
        [zipCode, setZipCode] = useState<string>(""),
        [stripeCardZipComplete, setStripeZipComplete] = useState<boolean>(false),

        /**
         * Stripe input options
         */
        modeSpecificStyles = {
            base: {
                color: 'white',
                '::placeholder': {
                    color: '#888888',
                    fontSize: '13px'
                }
            },
        },

        options: StripeCardNumberElementOptions = {
            iconStyle: 'solid',
            showIcon: true,
            style: modeSpecificStyles
        },

        optionsNoIcon: StripeCardExpiryElementOptions = {
            style: modeSpecificStyles
        },

        /**
         * Handlers
         */
        handleStripeChange = (
            event:
                | StripeCardNumberElementChangeEvent
                | StripeCardExpiryElementChangeEvent
                | StripeCardCvcElementChangeEvent
        ) => {
            let typeText = "";
            let InputType;

            switch (event.elementType) {
                case "cardNumber":
                    typeText = "number";
                    InputType = StripeInputType.Number;
                    setStripeNumberComplete(event.complete);
                    break;
                case "cardCvc":
                    typeText = "security code";
                    InputType = StripeInputType.Cvc;
                    setStripeCvcComplete(event.complete);
                    break;
                case "cardExpiry":
                    typeText = "expiration date";
                    InputType = StripeInputType.Expire;
                    setStripeExpiryComplete(event.complete);
                    break;
            }
        },

        handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const
                zip = event.target.value,
                validationResult = zipValidation(zip);

            setZipCode(zip);
            updateZipCode(zip);

            if (validationResult === "valid") {
                setStripeZipComplete(true);
            } else {
                setStripeZipComplete(false);
            }
        },

        handleFocus = () => {
            setFocusedInput(name);
            onFocus(name);
        },

        handleBlur = () => {
            setFocusedInput(name);
            onBlur(name);
        };

    useEffect(() => {

        /**
         * If each element in the stripe form has a complete status,
         * we can alert the parent component that the form is ready
         * to submit.
         */
        setCardComplete(stripeCardNumberComplete && stripeCardCvcComplete && stripeExpiryComplete && stripeCardZipComplete);
    }, [stripeCardNumberComplete, stripeCardCvcComplete, stripeExpiryComplete, stripeCardZipComplete]);

    return (
        <FormCreditCard className={`form__input-wrapper form__credit-card ${hasError ? 'has-error' : 'has-no-error'} ${focusedInput ? "is-focused" : "is-not-focused"} ${value !== '' ? 'has-value' : 'has-no-value'}`}>

            {/** Credit Card # **/}
            <CardNumberElement
                id="cardNumber"
                options={options}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {/** Credit Card Expiry **/}
            <CardExpiryElement
                id="expiry"
                options={optionsNoIcon}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {/** Credit Card CVC **/}
            <CardCvcElement
                id="cvc"
                options={optionsNoIcon}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            <input
                type="text"
                className="form__zip-code"
                onChange={handleZipChange}
                value={zipCode}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="ZIP"
            />
        </FormCreditCard>
    );
});

export default InputsStripe;
