import React, {
    useRef,
    useImperativeHandle,
    forwardRef,
    HTMLInputTypeAttribute,
} from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import ErrorText from "../Text/ErrorText";
import { Fonts } from "../../../Constants/Fonts";

const Container = styled.form`
    margin: 0 0 1rem 0;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;

    input {
        flex: 3;
        padding: 0.65rem 0.65rem;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid #444444;
        background: #444444;
        color: ${Colours.Text};
    }

    input::placeholder {
        color: ${Colours.Text};
    }

    input:focus {
        outline: none;
        border-color: #646464;
        background: #646464;
        transition-duration: 100ms;
        transition-property: color, background-color, border;
        transition-timing-function: ease-in-out;
    }

    .invalid {
        border-color: ${Colours.Error};
    }

    input {
        display: block;
    }

    @media (min-width: 768px) {
        align-items: center;
        flex-direction: row;
    }
`;

const Button = styled.button`
    background: ${Colours.IckonicPink};
    color: ${Colours.Text};
    font-family: ${ Fonts.Primary };
    font-size: 10pt;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 2.35rem;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    border: 0;

    :disabled {
        opacity: 0.7;
    }

    &:not(:disabled):hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

const ErrorContainer = styled.div`
    flex-basis: 100%;
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;

    p {
        font-size: 12pt;
    }

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
        height: 0;
    }
`;

const InputWithButton = forwardRef(
    (
        props: {
            isValid: boolean;
            id?: string;
            type: HTMLInputTypeAttribute;
            value?: string;
            placeholder?: string;
            required?: boolean;
            children: string | JSX.Element;
            disabled?: boolean;
            disableInput?: boolean,
            errorMessage?: string;
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
            onBlur: () => void;
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        function activate() {
            inputRef.current?.focus();
        }

        useImperativeHandle(ref, () => {
            return {
                focus: activate,
            };
        });

        return (
            <Container onSubmit={props.onSubmit}>
                <input
                    ref={inputRef}
                    className={`${props.isValid === false ? "invalid" : ""}`}
                    type={props.type}
                    id={props.id}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    required={props.required}
                    disabled={props.disableInput}
                />
                <Button disabled={props.disabled} type={"submit"}>
                    {props.children}
                </Button>
                <ErrorContainer
                    className={`${props.isValid === false ? "active" : ""}`}
                >
                    {props.errorMessage !== undefined ? (
                        <ErrorText>{props.errorMessage}</ErrorText>
                    ) : null}
                </ErrorContainer>
            </Container>
        );
    }
);

export default InputWithButton;
