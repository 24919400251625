import { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import ErrorText from "../Text/ErrorText";
import { Fonts } from "../../../Constants/Fonts";

const Container = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 100%;

    .invalid {
        border-color: ${Colours.Error};
    }

    @media (min-width: 768px) {
        align-items: center;
        flex-direction: row;
    }
`;

const Input = styled.textarea`
    font-family: ${ Fonts.Primary };
    border-radius: 4px;
    border: 1px solid #444444;
    background: #444444;
    color: ${Colours.Text};
    outline: 0;
    resize: none;
    padding: 10px;
    font-size: 12pt;
    min-height: 150px;
    width: 100%;
    box-sizing: border-box;

    :focus {
        outline: none;
        border-color: #646464;
        background: #646464;
        transition-duration: 100ms;
        transition-property: color, background-color, border;
        transition-timing-function: ease-in-out;
    }

    ::placeholder {
        color: ${Colours.Text};
    }
`;

const ErrorContainer = styled.div`
    flex-basis: 100%;
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;

    p {
        font-size: 12pt;
    }

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
        height: 0;
    }
`;

const TextArea = forwardRef(
    (
        props: {
            isValid: boolean;
            id?: string;
            value?: string;
            placeholder?: string;
            required?: boolean;
            errorMessage?: string;
            defaultValue?: string;
            disabled?: boolean;
            onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
            onBlur: () => void;
        },
        ref
    ) => {
        const inputRef = useRef<HTMLTextAreaElement | null>(null);

        function activate() {
            inputRef.current?.focus();
        }

        useImperativeHandle(ref, () => {
            return {
                focus: activate,
            };
        });

        return (
            <Container>
                <Input
                    ref={inputRef}
                    className={`${props.isValid === false ? "invalid" : ""}`}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    disabled={props.disabled}
                />
                <ErrorContainer
                    className={`${props.isValid === false ? "active" : ""}`}
                >
                    {props.errorMessage !== undefined ? (
                        <ErrorText>{props.errorMessage}</ErrorText>
                    ) : null}
                </ErrorContainer>
            </Container>
        );
    }
);

export default TextArea;
