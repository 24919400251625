export enum PlanType{
    MonthlyTwo,
    Quarterly,
    YearlyOne,
    MonthlyOne,
    MonthlyFour,
    YearlyTwo,
    YearlyFour,
    TruthSeeker,
    GameChanger 
}