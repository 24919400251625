import React from "react";

function PlayButtonFractalLines() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="576"
            height="576"
            viewBox="0 0 576 576"
            className="vector--play-button-fractal-lines"
        >
            <g fill="none" stroke="#010101">
                <path d="M290.64 239.62c-.9.4-1.4 9.8-.6 10.3.9.6 9.2-3.6 9.3-4.7 0-1-7.8-6.1-8.7-5.6"></path>
                <path
                    d="M287.24 233.42c-2 1.1-2.7 21.3-.8 22.5 1.9 1.2 19.8-8.3 19.9-10.5.1-2.3-17.1-13-19.1-12"></path>
                <path
                    d="M283.64 227.42c-3 1.7-3.6 32.9-.6 34.6 3 1.8 30.2-13.3 30.3-16.8.1-3.4-26.7-19.5-29.7-17.8"></path>
                <path
                    d="M279.84 221.52c-4 2.4-4 44.4 0 46.8 4 2.3 40.5-18.7 40.5-23.4 0-4.7-36.4-25.7-40.5-23.4"></path>
                <path d="M275.84 215.82c-5.1 3-4.1 56 1 58.8 5.2 2.9 50.6-24.4 50.5-30.3s-46.5-31.6-51.5-28.5"></path>
                <path
                    d="M271.64 210.12c-6 3.8-3.8 67.7 2.5 71 6.2 3.3 60.4-30.5 60.2-37.6-.3-7.1-56.7-37.1-62.7-33.4"></path>
                <path
                    d="M267.24 204.62c-7 4.6-3.1 79.3 4.3 83.1 7.4 3.7 70.2-37 69.7-45.3-.4-8.3-67.1-42.3-74-37.8"></path>
                <path
                    d="M262.54 199.32c-7.9 5.3-1.9 90.9 6.7 95 8.6 4.2 79.6-43.7 79-53.2-.7-9.5-77.8-47.1-85.7-41.8"></path>
                <path
                    d="M257.74 194.12c-8.8 6.2-.3 102.5 9.4 107 9.7 4.5 88.9-50.9 88-61.6-1-10.7-88.6-51.5-97.4-45.4"></path>
                <path
                    d="M252.74 189.12c-9.6 7 1.6 114 12.5 118.8 11 4.9 98-58.3 96.7-70.2-1.2-11.9-99.5-55.6-109.2-48.6"></path>
                <path
                    d="M247.64 184.22c-10.6 7.9 3.9 125.5 16 130.7 12.1 5.1 106.7-66.2 105.1-79.3-1.6-13-110.6-59.3-121.1-51.4"></path>
                <path
                    d="M242.24 179.52c-11.3 8.8 6.7 137 20 142.4 13.3 5.3 115.3-74.3 113.3-88.6-2-14.2-122-62.7-133.3-53.8"></path>
                <path
                    d="M236.74 175.02c-12.2 9.8 9.8 148.3 24.3 153.9 14.6 5.6 123.6-82.7 121.2-98.1-2.4-15.4-133.4-65.7-145.5-55.8"></path>
                <path
                    d="M230.94 170.62c-12.8 10.8 13.4 159.7 29.2 165.5 15.8 5.7 131.6-91.5 128.7-108-2.9-16.6-145-68.3-157.9-57.5"></path>
                <path
                    d="M225.14 166.42c-13.6 11.8 17.3 171 34.3 176.8 17.1 5.9 139.4-100.5 136-118.1-3.4-17.7-156.7-70.5-170.3-58.7"></path>
                <path
                    d="M219.04 162.42c-14.3 12.9 21.7 182.1 40 188.1 18.3 5.9 146.9-109.9 142.9-128.7-4-18.8-168.6-72.3-182.9-59.4"></path>
                <path
                    d="M212.84 158.62c-14.9 13.9 26.5 193.2 46 199.1 19.5 6 154.1-119.4 149.5-139.3-4.6-20-180.5-73.7-195.5-59.8"></path>
                <path
                    d="M206.54 155.02c-15.6 15 31.5 204.1 52.3 210.1 20.9 5.9 161-129.4 155.8-150.4-5.3-21-192.6-74.8-208.1-59.7"></path>
                <path
                    d="M200.04 151.62c-16.2 16.1 37.1 214.9 59.1 220.8 22.1 5.9 167.6-139.6 161.7-161.7-5.9-22-204.7-75.3-220.8-59.1"></path>
                <path
                    d="M193.34 148.32c-16.7 17.4 43 225.6 66.4 231.5 23.3 5.8 173.9-150.1 167.2-173.2-6.6-23.2-216.9-75.6-233.6-58.3"></path>
                <path
                    d="M186.54 145.32c-17.2 18.5 49.3 236.2 74 241.9 24.6 5.6 179.8-160.8 172.4-185s-229.1-75.4-246.4-56.9"></path>
                <path
                    d="M179.64 142.52c-17.7 19.7 56 246.5 81.9 252.1 25.9 5.5 185.5-171.8 177.3-197-8.2-25.2-241.5-74.8-259.2-55.1"></path>
                <path
                    d="M172.54 139.92c-18.1 20.9 63.1 256.8 90.3 262.1 27.2 5.2 190.8-183 181.8-209.2s-253.9-73.8-272.1-52.9"></path>
                <path
                    d="M165.44 137.62c-18.6 22.1 70.4 266.7 98.9 271.8 28.5 5 195.8-194.4 185.9-221.6-9.9-27.2-266.2-72.4-284.8-50.2"></path>
                <path
                    d="M158.14 135.42c-19 23.4 78.2 276.6 108 281.4 29.7 4.7 200.4-206.1 189.6-234.3-10.8-28.1-278.7-70.5-297.6-47.1"></path>
                <path
                    d="M150.74 133.52c-19.3 24.7 86.4 286.3 117.4 290.6 31.1 4.4 204.8-217.9 193-247-11.7-29.1-291.1-68.3-310.4-43.6"></path>
                <path
                    d="M143.24 131.82c-19.6 26 94.9 295.7 127.2 299.7s208.7-230.1 196-260.1c-12.8-29.9-303.6-65.6-323.2-39.6"></path>
                <path
                    d="M135.54 130.32c-19.8 27.3 103.8 305 137.4 308.5 33.6 3.6 212.3-242.4 198.5-273.2-13.7-30.9-316-62.6-335.9-35.3"></path>
                <path
                    d="M127.84 129.02c-20 28.7 113 314.1 147.9 317.1 34.8 3.1 215.5-254.9 200.7-286.6-14.8-31.7-328.5-59.1-348.6-30.5"></path>
                <path
                    d="M120.04 128.02c-20.2 30 122.6 322.9 158.7 325.4s218.3-267.6 202.4-300.1c-15.8-32.6-340.8-55.3-361.1-25.3"></path>
                <path
                    d="M112.14 127.32c-20.3 31.3 132.5 331.3 169.9 333.3s220.7-280.4 203.8-313.8c-17-33.3-353.3-50.9-373.7-19.5"></path>
                <path
                    d="M104.24 126.72c-20.5 32.8 142.7 339.7 181.3 341.1 38.6 1.3 222.8-293.5 204.7-327.6s-365.5-46.2-386-13.5"></path>
                <path
                    d="M96.24 126.42c-20.6 34.2 153.3 347.8 193.1 348.5s224.5-306.7 205.2-341.5c-19.4-34.8-377.8-41.1-398.3-7"></path>
                <path
                    d="M88.14 126.42c-20.6 35.5 164.2 355.5 205.2 355.5s225.8-319.9 205.2-355.5c-20.5-35.5-389.9-35.6-410.4 0"></path>
                <path
                    d="M79.94 126.62c-20.5 37 175.4 363 217.7 362.3 42.2-.8 226.6-333.4 204.8-369.7-21.7-36.2-402-29.6-422.5 7.4"></path>
            </g>
        </svg>
    );
}

export default PlayButtonFractalLines;
