import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import {Fonts} from "../../../Constants/Fonts";
import {Ease} from "../../../Constants/EasingCurves";

const Button = styled.button`
    background: ${Colours.IckonicPink};
    color: white;
    font-family: ${ Fonts.Primary };
    font-size: 12pt;
    padding: 10px;
    letter-spacing: 0.7px;
    border-radius: 5px;
    border: 0;
    width: 100%;
    text-align: center;
    background: none;
    box-shadow: 0 0 0 1px ${ Colours.ErrorDark };
    transition: background .25s ${ Ease.Smooth };
    :disabled {
        box-shadow: 0 0 0 1px ${ Colours.Tertiary };
        
       color: ${ Colours.Tertiary };
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background-color: ${Colours.ErrorDark};
    }
`;

function GreyBorderThinButton(props: {
    children: React.ReactNode,
    onClick?: () => void,
    disabled?: boolean,
    type?: 'submit' | 'reset' | 'button' | undefined,
    className?: string,
    testId?: string
}){
    return(
        <Button className={props.className ?? ""} onClick={props.onClick} disabled={props.disabled} type={props.type} data-testid={props.testId}>
            {props.children}
        </Button>
    )
}

export default GreyBorderThinButton;