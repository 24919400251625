import axios, { AxiosError } from "axios";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.tvAuth;

export async function SendCode(
    code: string,
    userId: string,
    abortController: AbortController
) {


    const response = await axios
        .post(BACKEND_URL + "login/" + 
             code + "/" + 
             userId + "/", {
                signal: abortController.signal,
            }
        )
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("sendrequest error");
            return error;
        });
    
    return response;
}
