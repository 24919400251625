import { MdStarBorder, MdStarHalf, MdStar } from "react-icons/md";
import styled from "styled-components";
import { Colours } from "../../Constants/Colours";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`;

function Rating(props: { value: number, iconSize: number, maxStars: number }){

    function RenderStar(key: number | string) {
        return (
            <MdStar key={key} size={props.iconSize} color={Colours.IckonicPink} />
        )
    }

    function RenderHalfStar(key: number | string) {
        return (
            <MdStarHalf key={key} size={props.iconSize} color={Colours.IckonicPink} />
        )
    }

    function RenderOutlineStar(key: number | string) {
        return (
            <MdStarBorder key={key} size={props.iconSize} color={Colours.IckonicPink} />
        )
    }

    function RenderStars() {
        let stars: JSX.Element[] = [];
        const totalRemainer = Math.floor(props.maxStars - props.value);

        for (var i = 0; i < props.value; i++) {
            let remainer = props.value - i;
            let isHalf = remainer > 0 && remainer < 1;

            if (isHalf) {
                stars.push(RenderHalfStar(i));
                break;
            }

            stars.push(RenderStar(i));
        }

        //Add remaning stars as outlines
        for (var i2 = 0; i2 < totalRemainer; i2++) {
            stars.push(RenderOutlineStar(i2 + "outline"))
        }

        return (
            <>
                {stars}
            </>
        )
    }


    return(
        <Container>
            {RenderStars()}
        </Container>
    );
}

export default Rating;