import moment from "moment";
import { Suspense, useContext } from "react";
import {
    Await,
    defer,
    Params,
    redirect,
    useLoaderData,
} from "react-router-dom";
import { GetContentRedirect, SetTitle } from "../../Helpers/Utility";
import { GetVideo } from "../../Api/Video";
import { Colours } from "../../Constants/Colours";
import styled from "styled-components";
import Heading from "../../Components/UI/Text/Heading";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import Socials from "../../Components/UI/Socials";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import NavLinkPinkButtonFat from "../../Components/UI/Buttons/NavLinkPinkButtonFat";
import NavLinkPinkButton from "../../Components/UI/Buttons/NavLinkPinkButton";
import { RoutePaths } from "../../Constants/RoutePaths";
import { ContentType } from "../../Models/Enums/ContentType";
import { GetSeries } from "../../Api/Series";
import ISeriesDTO from "../../Models/DTOs/ISeriesDTO";
import AuthContext, { UserSnapshot } from "../../Store/auth-context";
import { AxiosError } from "axios";
import { IsActive } from "../../Helpers/UserUtility";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import PopUp from "../../Components/UI/Modals/PopUp";
import { PopUpType } from "../../Models/Enums/PopUpType";

const Container = styled.section`
    max-width: 1632px;
    margin: 0 auto;
    width: 100%;
`;

const VideoContainer = styled.div`
    max-width: 1600px;
    position: relative;
    flex-basis: 100%;
    width: 100%;
    height: 56.25vw;
    max-height: 900px;
    background: #000;
    margin: 0 auto;
`;

const ContentContainer = styled.div`
    height: auto;
    padding: 1rem;    
    > p {
        margin: 0 0 1rem 0;
        max-width: 1000px;
        &:first-of-type {
            color: ${ Colours.TertiaryHighlight };
            margin-bottom: 8px;
        }
    }
    
    > a { 
        margin-bottom: 1rem;
    }
    
    h1 {
        width: 100%;
        margin: 0 0 calc(12rem/16) 0;
        font-size: 16pt;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            font-size: 18pt;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        > h1 {
            margin: 0 0 calc(12rem/16) 0;
            width: calc(100% - (545rem/16));
        }
    }
`;

const SocialsAndRatingsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 51px;
    border-radius: 25px;
    background: #050505;
`;

const TitleSocialsRatings = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
    > h1 {
        width: 100%;
        margin: 0 0 calc(12rem/16) 0;
        font-size: 16pt;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            font-size: 18pt;
        }
    }
    
    > div {
        overflow-x: scroll;
        width: 310px;     
        margin: 0 0 calc(8rem/16) 0;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
        > div {
            width: 338px;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        > div {
            width: 398px;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        > h1 {
            margin: 0 0 calc(12rem/16) 0;
            width: calc(100% - (545rem/16));
        }
        
        > div {
            margin: calc(-16rem/16) 0 0 0;
        }
    }
`;

const ButtonContainer = styled.div`
    padding: 1.5rem 1rem;
    max-width: 1600px;
    margin: 0 auto;
    background: ${ Colours.SecondaryDarker };
    p {
        margin: 0;
        text-align: center;
        line-height: 1.4;
        color: ${ Colours.Text };
        font-size: 12pt;
        a {
            margin: 1rem auto 0 auto;
            padding: 9px 30px;
            display: table;
            @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            margin: 0 0 0 20px;
            display: inline-block;
            }
        }
    }
`;

function TrailerScreen() {

    const authCtx = useContext(AuthContext);
    const subscribed = IsActive(authCtx.UserDetails);

    const { contentPromise } = useLoaderData() as {
        contentPromise: Promise<IVideoDTO> | Promise<ISeriesDTO>;
    };

    function RenderContent(contentId: number, thumbnail: string, trailerLink: string, title: string, description: string, dateTime: string, contentType: ContentType) {
        const text = contentType === ContentType.Film ? "Watch Full Video" : "View Series";
        const route = GetContentRedirect(contentType, contentId);

        return (
            <>
                { (authCtx.UserDetails.AccessLevel === undefined || authCtx.UserDetails.AccessLevel < 4) && (
                    <PopUp
                        canBeDismissed={ true }
                        isFullScreen={ true }
                        openEvent="onPauseEvent"
                        apiEnum={ PopUpType.Watch }
                    />
                ) }

                <Container>
                    <VideoContainer>
                        <VideoPlayer poster={thumbnail} src={trailerLink} />
                    </VideoContainer>

                    { !subscribed ?
                        (
                            <ButtonContainer>
                                <p>
                                    Ready for More? Get your first month for just £1.99.
                                    <NavLinkPinkButtonFat to={RoutePaths.CreateYourAccount}>
                                        Sign Up Now
                                    </NavLinkPinkButtonFat>
                                </p>
                            </ButtonContainer>
                        )
                        : null
                    }

                    <ContentContainer>

                        <PrimaryText>
                            { moment(dateTime).format("Do MMMM YYYY").toString() }
                        </PrimaryText>

                        <TitleSocialsRatings>
                            <Heading>{ title }</Heading>

                            <div>
                                <SocialsAndRatingsContainer>
                                    <Socials displayText={true} title={title} contentType={"trailer"}/>
                                </SocialsAndRatingsContainer>
                            </div>
                        </TitleSocialsRatings>

                        { subscribed ? (
                            <NavLinkPinkButton to={route !== null && route !== undefined ? route : RoutePaths.Browse}>
                                {text}
                            </NavLinkPinkButton>
                        ) : null }

                        <PrimaryText>{ description }</PrimaryText>
                    </ContentContainer>
                </Container>
            </>
        )
    }

    return (
        <Suspense fallback={
            <Heading>...Loading trailer</Heading>
        }>
            <Await resolve={contentPromise}>
                { (contentData) => {
                    if ("SeriesId" in contentData) {
                        const video = contentData as IVideoDTO;

                        return RenderContent(video.Id, video.Thumbnail, video.TrailerLink, video.Title, video.Description, video.Datetime, ContentType.Film);
                    }
                    else {
                        const series = contentData as ISeriesDTO

                        return RenderContent(series.Id, series.HeaderImg, series.TrailerLink, series.Title, series.Description, series.Datetime, ContentType.Series);
                    }
                } }
            </Await>
        </Suspense>
    );
}

export default TrailerScreen;

export async function Loader({
                                 params,
                                 contentType,
                             }: {
    params: Readonly<Params<string>>;
    contentType: ContentType;
}) {
    const contentId = Number(params.id);
    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    async function InitVideo() {
        let result = await GetVideo(contentId, userDetails.CurrentCountryCode, controller);

        if (result === null || result instanceof AxiosError || result.Id == null) {
            return redirect(RoutePaths.Browse);
        }

        SetTitle(result.Title);
        return result;
    }

    async function InitSeries() {
        const result = await GetSeries(contentId, controller);

        if (result === null || result instanceof AxiosError || result.Id == null) {
            return redirect(RoutePaths.Browse);
        }

        SetTitle(result.Title);

        return result;
    }

    return defer({
        contentPromise: contentType === ContentType.Film ? InitVideo() : InitSeries()
    })
}
