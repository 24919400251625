import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import PlaceHolder from "../../../Assets/Images/Placeholders/IckonicPlaceholderLandscape.jpg";

const Container = styled.div`
    width: 100%;
    padding: 3em 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VideoContainer = styled.div`
    height: 39vw;
    flex: 0 0 70%;

    @media only screen and (max-width: 1115px) {
        height: 54vw;
        flex: 0 0 97%;
    }
`;

function PanelVideoCentred(props: {panel: IPanelDTO}){
    const properties = props.panel.Properties;
    const url = properties.find(x => x.PropertyType === PanelPropertyType.VideoUrl);
    const background = properties.find(x => x.PropertyType === PanelPropertyType.Image);

    if(url === undefined || url.Value.length <= 0){
        return null;
    }

    return(
        <Container>
            <VideoContainer>
                <VideoPlayer 
                    src={url.Value} 
                    poster={background ? background.Value : PlaceHolder}/>
            </VideoContainer>
        </Container>
    )
}

export default PanelVideoCentred;