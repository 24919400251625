import Card from "./Card";
import { PaymentMethod } from "@stripe/stripe-js";
import StripeCardForm from "./Forms/StripeCardForm";

function StripeCard(props: {
    setPaymentMethod: (paymentMethod: PaymentMethod) => Promise<boolean>;
}) {
    return (
        <Card>
            <StripeCardForm setPaymentMethod={props.setPaymentMethod}/>
        </Card>
    );
}

export default StripeCard;
