import styled from "styled-components";
import Heading from "../Text/Heading";
import { SlOptions } from "react-icons/sl";
import DropDownOverlay from "../DropDowns/DropDownOverlay";
import PrimaryText from "../Text/PrimaryText";
import { Colours } from "../../../Constants/Colours";
import React, { useContext, useEffect, useState } from "react";
import PinkTextButton from "../Buttons/PinkTextButton";
import { convertUTCDateToLocalDate } from "../../../Helpers/Utility";
import AuthContext from "../../../Store/auth-context";
import DropDownPushDown from "../DropDowns/DropDownPushDown";
import ICommentAndPersonDTO from "../../../Models/DTOs/ICommentAndPersonDTO";
import { DeleteComment } from "../../../Api/VideoComment";
import CommentInput from "./CommentInput";
import { Fonts } from "../../../Constants/Fonts";

const Container = styled.div`
    flex-basis: 100%;

    h1 {
        margin: 0;
        font-size: 12pt;
    }

    > p {
        font-size: 10pt;
    }

    svg {
        color: ${Colours.Text};
        cursor: pointer;
    }

    svg:hover {
        opacity: 0.7;
    }
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    > p {
        font-size: 10pt;
    }

    :first-of-type {
        justify-content: space-between;
    }

    :last-of-type {
        justify-content: flex-start;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
        font-size: 10pt;
    }

    .edited {
        color: ${Colours.IckonicPink};
    }
`;
const OptionsBtn = styled(SlOptions)`
    align-self: flex-end;
`;

const RepliesContainer = styled.div`
    padding-left: 10px;
    width: 100%;
`;

const EditButton = styled.button`
    background: transparent;
    color: ${Colours.Text};
    font-family: ${ Fonts.Primary };
    border: 0;
    font-size: 12pt;
    padding: 10px;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

const DeleteButton = styled.button`
    background: transparent;
    color: ${Colours.Error};
    font-family: ${ Fonts.Primary };
    border: 0;
    font-size: 12pt;
    padding: 10px;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

function CommentItem(props: {
    CommentPerson: ICommentAndPersonDTO;
    children?: React.ReactNode;
    count?: number;
    videoId: number;
    UpdateComments: (videoId: number) => Promise<void>;
    disableInputs?: boolean;
}) {
    const authCtx = useContext(AuthContext);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isReplying, setIsReplying] = useState<boolean>(false);
    const controller = new AbortController();

    //Closes inputs on update list call
    useEffect(() => {
        OnEditToggle(false);
        OnReplyToggle(false);
    }, [props.UpdateComments]);

    function OnActiveToggle(value: boolean) {
        setIsActive(value);
    }

    function OnEditToggle(value: boolean) {
        setIsEditing(value);
        setIsReplying(value);
    }

    function OnReplyToggle(value: boolean) {
        setIsReplying(value);
        setIsEditing(false);
    }

    async function OnDeletePress() {
        await DeleteComment(props.CommentPerson.Comment.Id, controller);
        await props.UpdateComments(props.videoId);
    }

    return (
        <Container>
            <InlineContainer>
                <TitleContainer>
                    <Heading>
                        {props.CommentPerson.Person.FirstName}
                    </Heading>
                    {props.CommentPerson.Comment.AspNetUserId ===
                    authCtx.UserDetails.AspNetUserId ? (
                        <PrimaryText>{"(You)"}</PrimaryText>
                    ) : null}
                    {props.CommentPerson.Comment.Edited ? (
                        <PrimaryText class="edited">{"(Edited)"}</PrimaryText>
                    ) : null}
                </TitleContainer>
                {props.CommentPerson.Comment.AspNetUserId ===
                authCtx.UserDetails.AspNetUserId ? (
                    <DropDownOverlay Header={<OptionsBtn />} testId={"options"} childTestId={"optionsChild"}>
                        <EditButton onClick={() => OnEditToggle(!isEditing)}>
                            Edit
                        </EditButton>
                        <DeleteButton onClick={OnDeletePress}>
                            Delete
                        </DeleteButton>
                    </DropDownOverlay>
                ) : null}
            </InlineContainer>
            <PrimaryText>{props.CommentPerson.Comment.Text}</PrimaryText>
            <InlineContainer>
                <PrimaryText>Posted: </PrimaryText>
                <PrimaryText>
                    {convertUTCDateToLocalDate(
                        new Date(props.CommentPerson.Comment.DatePosted)
                    )}
                </PrimaryText>
                {props.CommentPerson.Comment.Text.toLowerCase() ===
                "This comment has been deleted by the user" ? null : (
                    <DropDownPushDown
                        isActive={isReplying}
                        setIsActive={isEditing ? setIsEditing : OnReplyToggle}
                        Header={
                            <PinkTextButton
                                onClick={() => {}}
                                disabled={false}
                            >
                                Reply
                            </PinkTextButton>
                        }
                    >
                        <CommentInput
                            videoId={props.videoId}
                            replyTo={props.CommentPerson.Comment}
                            UpdateComments={props.UpdateComments}
                            disableInput={props.disableInputs}
                            edit={
                                isEditing
                                    ? props.CommentPerson.Comment
                                    : undefined
                            }
                            placeholder={
                                isEditing
                                    ? "Edit a comment here..."
                                    : "Write a comment here..."
                            }
                        />
                    </DropDownPushDown>
                )}
            </InlineContainer>
            {props.children !== undefined &&
            props.count !== undefined &&
            props.count > 0 ? (
                <DropDownPushDown
                    isActive={isActive}
                    setIsActive={OnActiveToggle}
                    Header={
                        <PinkTextButton onClick={() => {}} disabled={false} testId={"reply"}>
                            {isActive ? "Hide " : "View "}
                            {props.count.toString()}
                            {props.count > 1 ? " Replies" : " Reply"}
                        </PinkTextButton>
                    }
                >
                    <RepliesContainer>{props.children}</RepliesContainer>
                </DropDownPushDown>
            ) : null}
        </Container>
    );
}

export default CommentItem;
