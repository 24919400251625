import { ReactNode } from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";

const Text = styled.p`
    font-family: ${ Fonts.Primary };
    color: ${ Colours.Text };
    font-size: 11pt;
    margin: 0;
    line-height: 1.5;
`;

function PrimaryText(props: {children: string | string[] | number | ReactNode, class?: string, onClick?: () => void, testId?: string}){
    return(
        <Text data-testid={props.testId} className={props.class} onClick={props.onClick}>{props.children}</Text>
    )
}

export default PrimaryText;