import React, {
    useRef,
    useImperativeHandle,
    forwardRef,
    HTMLInputTypeAttribute,
} from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import ErrorText from "../Text/ErrorText";
import PrimaryText from "../Text/PrimaryText";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const Container = styled.div<{removeMargin?: boolean}>`
    margin: ${props => props.removeMargin ? 0 : "0 0 1rem 0"} ;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    
    input {
        font-family: ${ Fonts.Primary };
        flex: 3;
        padding: 20px;
        border-radius: 7px;
        border: 1px solid ${ Colours.SecondaryHighlight };
        background: black;
        color: ${Colours.Text};
        transition: border .25s ${ Ease.Smooth };
    }

    input::placeholder {
        color: ${Colours.LighterGrey};
    }
    
    input:hover {
        border-color: ${ Colours.TertiaryHighlight };
    }
   

    input:focus {
        outline: none;
        border-color: ${ Colours.TertiaryHighlight };
    }

    input:disabled {
        opacity: 0.5;
        outline: none;
    }

    .invalid {
        border-color: ${Colours.Error};
    }

    input {
        display: block;
    }

    @media (min-width: 768px) {

    }
`;

const Icon = styled.i`
    color: ${Colours.Text};
    position: absolute;
    padding: 10px;
    text-align: center;
    right: 0;
    bottom: 0;
    cursor: pointer;
    font-size: 14pt;

    :active {
        opacity: 0.5;
    }

    
    svg {
        vertical-align: middle;
    }
`;

const ErrorContainer = styled.div`
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;

    p {
        font-size: 12pt;
    }

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
        height: 0;
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    position: relative;
    flex-basis: 100%;
    width: 100%;
    & > p {
        margin: 0 0 8px 0;
    }
`;

const InputWithIcon = forwardRef(
    (
        props: {
            label?: string;
            isValid: boolean;
            id?: string;
            type: HTMLInputTypeAttribute;
            value?: string;
            placeholder?: string;
            required?: boolean;
            children: string | JSX.Element;
            disabled?: boolean;
            errorMessage?: string;
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
            onBlur: () => void;
            onIconClick: () => void;
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        function activate() {
            inputRef.current?.focus();
        }

        useImperativeHandle(ref, () => {
            return {
                focus: activate,
            };
        });

        return (
            <Container>
                <InputContainer>
                    {props.label !== null && props.label !== undefined}
                    {<PrimaryText>{props.label}</PrimaryText>}

                    <input
                        ref={inputRef}
                        className={`${props.isValid === false ? "invalid" : ""
                            }`}
                        type={props.type}
                        id={props.id}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        required={props.required}
                    />
                    <Icon onClick={props.onIconClick}>{props.children}</Icon>
                </InputContainer>

                <ErrorContainer
                    className={`${props.isValid === false ? "active" : ""}`}
                >
                    {props.errorMessage !== undefined ? (
                        <ErrorText>{props.errorMessage}</ErrorText>
                    ) : null}
                </ErrorContainer>
            </Container>
        );
    }
);

export default InputWithIcon;
