import { useEffect, useRef, useState } from "react";
import { IsAuthenticated } from "../../../Helpers/UserUtility";
import { IsDev, NoPopupPage, showMarketingPopup } from "../../../Helpers/Utility";
import { IUserDetails } from "../../../Models/IUserDetails";
import CommercialEmailModal from "./CommercialEmailModal";
import DailyNewsletterModal from "./DailyNewsletterModal";
import { useLocation } from "react-router-dom";
import MarketingPopup from "./MarketingPopup";

function GlobalPopupManager(props : {
    userData: IUserDetails
}) {
    const dev = IsDev();
    const loaded = useRef<boolean>(false);
    const [marketingPopupAvailable, setMarketingPopupAvailable] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        if (loaded.current === true) {
            return;
        }

        if (IsAuthenticated(props.userData)) {
            setMarketingPopupAvailable(showMarketingPopup());
            loaded.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userData]);

    if (dev || !IsAuthenticated(props.userData) || NoPopupPage(location.pathname)) {
        return null;
    }

    else if (props.userData.AllowCommericalEmails === null) {
        return <CommercialEmailModal token={props.userData.Access_Token}/>
    }

    else if (props.userData.PromptForDailyNewsletter === true) {
        return <DailyNewsletterModal token={props.userData.Access_Token}/>
    }

    else if (marketingPopupAvailable) {
        return <MarketingPopup/>;
    }

    return null;
}

export default GlobalPopupManager;