import axios, { AxiosError } from "axios";
import {PopUpType} from "../Models/Enums/PopUpType";
import IPopUpDTO from "../Models/DTOs/IPopUpDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.popups;

export default async function GetPopUpApiData(abortController: AbortController, apiEnum: PopUpType | undefined) {
    const fetchUrl = BACKEND_URL + apiEnum;
    //console.log(fetchUrl);

    const response = await axios
        .get<IPopUpDTO>(fetchUrl, {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IPopUpDTO;
        })
        .catch((error: AxiosError) => {
            console.log("Email error");
            return error;
        });

    return response;
}