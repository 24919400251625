import React, { useState, ReactNode } from 'react';
import styled from "styled-components";

interface TooltipProps {
    content: string;
    children: ReactNode;
}

const ToolTipDiv = styled.div`
    position: relative;
    display: inline-block;
    .tooltip {
        position: absolute;
        bottom: calc(100% + (20rem/16));
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        background-color: #323232;
        color: white;
        width: calc(250rem/16);
        padding: calc(12rem/16);
        border-radius: calc(4rem/16);
        font-size: calc(14rem/16);
        text-align: left;
        box-shadow: 0 0 calc(5rem/16) calc(2rem/16) rgba(0,0,0,0.3);
        span {
            line-height: 1.7;
            padding: 0 !important;
            background: none !important;
        }

        &:after {
            content: '';
            position: absolute;
            width: calc(16rem/16);
            height: calc(16rem/16);
            transform: rotate(45deg);
            left: calc(50% - (8rem/16));
            bottom: calc(-8rem/16);
            background-color: #323232;
        }

        @media screen and (min-width: 36.5em) {
            width: calc(300rem / 16);
        }
    }
`;

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <ToolTipDiv
            className="tooltip-container"
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && (
                <div className="tooltip">
                    <span>{content}</span>
                </div>
            )}
        </ToolTipDiv>
    );
};

export default Tooltip;
