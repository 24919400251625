import axios, { AxiosError } from "axios";
import { IRatingDTO } from "../Models/DTOs/IRatingDTO";
import config from "../Constants/Config";
    
const BACKEND_URL = process.env.REACT_APP_API_URL + config.rating;

export async function GetVideoRating(
    videoId: number,
    userId: string | undefined,
    abortController: AbortController
) {
    const responce = await axios
        .get(BACKEND_URL + "GetVideoRating", {
            signal: abortController.signal,
            params: {
                videoId: videoId,
                userId: userId,
            },
        })
        .then((responce) => {
            return responce.data as IRatingDTO;
        })
        .catch((error: AxiosError) => {
            console.log("Video rating error" );
            return error;
        });

    return responce;
}

export async function GetSeriesRating(
    seriesId: number,
    abortController: AbortController
) {
    const responce = await axios
        .get(BACKEND_URL + "GetSeriesRating", {
            signal: abortController.signal,
            params: {
                seriesId: seriesId,
            },
        })
        .then((responce) => {
            return responce.data as number;
        })
        .catch((error: AxiosError) => {
            console.log("GetSeriesRating error" );
            return error;
        });

    return responce;
}

export async function AddRating(
    contentId: number,
    AspNetUserId: string,
    isLike: boolean,
    abortController: AbortController
) {
    const responce = await axios
        .post(
            BACKEND_URL +
                "add/" +
                contentId +
                "/" +
                AspNetUserId +
                "/" +
                isLike,
            null,
            { signal: abortController.signal }
        )
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("AddRating error" );
            return error;
        });

    return responce;
}

export async function DeleteRating(
    contentId: number,
    AspNetUserId: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "delete/" + contentId + "/" + AspNetUserId, null, {
            signal: abortController.signal,
        })
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("DeleteRating error");
            return error;
        });

    return responce;
}
