import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import { Colours } from "../../../Constants/Colours";
import AndroidIcon from '../../../Assets/Images/Logos/AppProviders/android.png';
import AppleIcon from '../../../Assets/Images/Logos/AppProviders/apple.png';
import ChromecastIcon from '../../../Assets/Images/Logos/AppProviders/chromecast.png';
import FireTVIcon from '../../../Assets/Images/Logos/AppProviders/fire-tv.png';
import RokuIcon from '../../../Assets/Images/Logos/AppProviders/roku.png';
import SamsungIcon from '../../../Assets/Images/Logos/AppProviders/samsung.png';
import PrimaryText from "../Text/PrimaryText";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { Ease } from "../../../Constants/EasingCurves";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    padding: 2rem 1rem 1rem 1rem;
    h2 {
        text-align: center;
        margin: 0 0 3rem 0;
        padding: 0 1rem 0 0;
    }
    
     @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
        padding: 2rem 0 1rem 1rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        // h2 {
        //     margin: 0 0 2rem 0;
        // }
        
        padding: 3rem 2rem 2rem 3rem;
    }
`;

const AppsGrid = styled.div`
    max-width: 1610px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    & > div,
    & > a {
        display: flex;
        justify-content: center;
        padding: 1rem;
        margin: 0 auto 1rem auto;
        width: calc(100% - 1rem);
        border-radius: 31px;
        transition: background .25s ${ Ease.Smooth };
        max-width: 250px;
        
        img {
            height: 25px;
            width: auto;
            margin: 0;
        }
    }
    
     & > div {
        box-shadow: inset 0 0 0 3px #181818;
        background: #181818;
    }
    
    & > a {
        box-shadow: inset 0 0 0 3px #101010;
        &:hover,
        &:active {
            background: #101010;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
        flex-wrap: wrap;
        flex-direction: row;
        & > div,
        & > a {
            display: inline-flex;
            margin: 0 1rem 1rem 0;
            width: calc(50% - 1rem);
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        & > div,
        & > a {
            width: calc(33.333333% - 1rem);
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {

    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        justify-content: space-between;
        & > div,
        & > a {
            width: calc(16.666666% - 1rem);
        }
    }  
`;

const IconNoLink = styled.div`

`;

const Img = styled.img`

`;

const Disclaimer = styled.div`
    p {
        max-width: 1568px;
        margin: 2rem auto 1rem auto;
        font-size: 10pt;
        text-align: center;
        color: ${ Colours.TertiaryHighlight };
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
            text-align: right;
        }  
    }  
`;

function PanelApp(props: { panel: IPanelDTO; freeDaysTrial: number }) {
    return (
        <Container>
            {props.panel.Properties.filter(
                (x) => x.PropertyType === PanelPropertyType.Title
            ).map((property, index) => {
                return (
                    <Heading key={"T" + index} type={ HeadingType.H2 }>
                        {property.Value.replace(
                            "{freetrialdays}",
                            props.freeDaysTrial.toString()
                        )}
                    </Heading>
                );
            })}

            <AppsGrid>
                <a
                    key={"android"}
                    className="android"
                    href="https://play.google.com/store/apps/details?id=com.ickonic.ickonicapp"
                >
                    <img src={AndroidIcon} alt="Android" />
                </a>

                <a key={"apple"} href="https://apps.apple.com/us/app/ickonic/id1551307977">
                    <img src={AppleIcon} alt="iOS" />
                </a>

                <a
                    key={"roku"}
                    className="roku"
                    href="https://channelstore.roku.com/en-gb/details/863dc8e46592d77817f2247f27790472/ickonic"
                >
                    <img src={RokuIcon} alt="Roku" />
                </a>

                <IconNoLink>
                    <img src={ChromecastIcon} alt="Chromecast" />
                </IconNoLink>

                <a
                    key={"fireTv"}
                    className="fireTv"
                    href="https://www.amazon.co.uk/Ickonic-Media-Group-Limited/dp/B0B214MT73/ref=sr_1_2?crid=30ZXPDYNXJGY9&keywords=ickonic&qid=1671441993&s=amazon-devices&sprefix=ickonic%2Camazon-devices%2C180&sr=1-2"
                >
                    <img src={FireTVIcon} alt="Fire TV" />
                </a>

                <IconNoLink>
                    <Img src={SamsungIcon} alt="Sansung TV" /><PrimaryText>*</PrimaryText>
                </IconNoLink>

            </AppsGrid>

            <Disclaimer>
                <PrimaryText>* Samsung Tizen app is only available in the US</PrimaryText>
            </Disclaimer>
        </Container>
    );
}

export default PanelApp;
