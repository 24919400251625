import axios, { AxiosError } from "axios";
import IHelpTopicDTO from "../Models/DTOs/IHelpTopicDTO";
import { IHelpRequestDTO } from "../Models/DTOs/IHelpRequestDTO";
import { HelpTopicType } from "../Models/Enums/HelpTopicType";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.help;

export default async function GetHelpTopics(abortController: AbortController, topicType?: HelpTopicType) {

    const response = await axios
        .get(BACKEND_URL + "GetHelpTopics/" + topicType, {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as IHelpTopicDTO[];
        })
        .catch((error: AxiosError) => {
            console.log("GetHelpTopics error");
            return error;
        });

    return response;
}

export async function SendHelpRequest(request: IHelpRequestDTO) {
    const response = await axios
        .post(
            BACKEND_URL + "sendrequest",
            {
                userEMail: request.UserEmail,
                subject: request.Subject,
                message: request.Message,
                os: 'web',
                version: '1'
            }
        )
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("sendrequest error");
            return error;
        });

    return response;
}