import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";

const Container = styled.div`
    width: 100%;

    margin-left:auto;
    margin-right:auto;
`

const Video = styled.div`
    height: 600px;
    background-color: ${Colours.SecondaryHighlight};
`;

function WatchLoader() {

    return (
        <Container>
            <Video/>
        </Container>
    );
}

export default WatchLoader;