import axios, { AxiosError } from "axios";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.favourite;

export async function GetIsFavourite(contentId: number, userId: string, isSeries: boolean, abortController: AbortController) {
    const responce = await axios.get(BACKEND_URL, {
        signal: abortController.signal,
        params:
        {
            contentId: contentId,
            aspNetUserId: userId,
            isSeries: isSeries
        }
    }).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("GetIsFavourite error");
        return error;
    });

    return responce;
};

export async function AddOrDeleteFavourite(contentId: number, aspNetUserId: string, isSeries: boolean, abortController: AbortController) {

    const data = {
        contentId: contentId,
        aspNetUserId: aspNetUserId,
        isSeries: isSeries
    }
    
    const responce = await axios.post(BACKEND_URL + "Add", null,  {signal: abortController.signal, params: data}).then((responce) => {
        return responce.data as string;
    }).catch((error: AxiosError) => {
        console.log("Favourite error");
        return error;
    });

    return responce;
};