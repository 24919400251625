import LazyLoad from "react-lazy-load";
import styled from "styled-components";
import PlaceHolder from "../../Assets/Images/Placeholders/IckonicPlaceholderLandscape.jpg";
import { useState, useCallback, useRef, useEffect } from 'react';
import {Colours} from "../../Constants/Colours";

const Container = styled.div`
    background: transparent;
    width: 100%;
    
    .LazyLoad {
        width: 100%;
        height: 100%;
    }
    
    &.u-full_cover_absolute {
        .LazyLoad {
            img {
                object-fit: cover;
                height: 100%;
                width: auto;
            }
        }
    }

    .LazyLoad.is-visible {
        opacity: 1;
    }
`;

const Loader = styled(LazyLoad)`
    opacity: 0;
    transition: all 500ms ease-in-out;
    display: flex;
    align-items: flex-start;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

function ImageLazyLoad(props: {
    source: string,
    forceAspectRatio?: boolean,
    altText?: string,
    className?: string,
    children?: React.ReactNode
}) {
    const
        [ loaded, setLoaded ] = useState(false),
        [ containerWidth, setContainerWidth ] = useState(0),
        handleVisible = useCallback(() => setLoaded(true), []),
        containerRef = useRef<HTMLDivElement>(null),
        adjustAspectRatio = () => {
            if (containerRef.current !== null) {
                const width = containerRef.current.offsetWidth;
                setContainerWidth(width);
            }
        };

    useEffect(() => {
        adjustAspectRatio();
        window.addEventListener('resize', adjustAspectRatio);

        // Clean up on unmount
        return () => {
            window.removeEventListener('resize', adjustAspectRatio);
        }
    }, []);

    return (
        <Container
            ref={ containerRef }
            className={ props.className }
            style={ {
                minHeight: props.forceAspectRatio ? `${ containerWidth * 9 / 16 }px` : 0,
                background: props.forceAspectRatio ? Colours.SecondaryDarker : 'none'
            } }
        >
            <Loader onContentVisible={ handleVisible }>
                <Image
                    src={ props.source }
                    alt={ props.altText ? props.altText : '' }
                    className={ loaded ? 'has-loaded' : 'loader--side-to-side' }
                    onError={ ({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src= PlaceHolder;
                    } }
                />
            </Loader>

            { props.children !== undefined ? props.children : null }
        </Container>
    );
}
export default ImageLazyLoad;
