import { AxiosResponse } from "axios";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import styled from "styled-components";
import Footer from "../Components/UI/Navigation/Footer";
import EncrytedError from "../Components/UI/Errors/EncryptedError";
import GenericError from "../Components/UI/Errors/GenericError";

const Container = styled.section`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(20deg, rgba(0, 0, 0, 0) 41%, rgb(114 40 113) 100%);
    background-color: rgb(28, 28, 28);
    background-attachment: fixed;
`;

function ErrorScreen() {
    const error = useRouteError();

    function RenderError(
        statusCode: number,
        statusText: string,
        Message: string,
        encrypt?: boolean
    ) {
        return (
            <>
                <Container>
                    {encrypt ? (
                        <EncrytedError
                            message={Message}
                            statusCode={statusCode}
                            statusText={statusText}
                        />
                    ) : (
                        <GenericError
                            message={Message}
                            statusCode={statusCode}
                            statusText={statusText}
                        />
                    )}
                </Container>
                <Footer />
            </>
        );
    }

    if (isRouteErrorResponse(error)) {
        return RenderError(error.status, error.statusText, error.data);
    }

    const apiError = error as AxiosResponse;

    let message = "";

    if (apiError?.data?.ExceptionMessage) {
        message = apiError.data.ExceptionMessage;
    }
    else if (apiError?.data?.Message) {
        message = apiError.data.Message;
    }
    else {
        try {
            message = apiError.toString();
        }
        catch {
            message = "";
        }
    }

    return RenderError(
        apiError.status,
        apiError.statusText,
        message,
        true
    );
}

export default ErrorScreen;
