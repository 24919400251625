import Modal from "./Modal";
import { useState } from 'react';
import CommercialEmailForm from "../Forms/CommercialEmailsForm";
import styled from "styled-components";

const Container = styled.div`
    max-width: 560px;
`;

function CommercialEmailModal(props: {
    token: string
}) {

    const [formComplete, setFormComplete] = useState<boolean>(false);

    function formSubmitted() {
        setFormComplete(true);
    }

    return (
        <Container>
            <Modal
                display={!formComplete}
                setDisplay={setFormComplete}
                title="Sign me up!"
                subText="Sign up today to receive emails on our exclusive offers, brand new original content and so much more!"
                closeOnClickOutside={false}
                displayClosebutton={false}
            >
                <CommercialEmailForm token={props.token} formSubmitted={formSubmitted} />
            </Modal>
        </Container>
    );
}

export default CommercialEmailModal;