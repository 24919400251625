import styled from "styled-components";
import { Colours } from "../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";

const CardItem = styled.div`
    background: ${ Colours.SecondaryDarker };
    width: 100%;
    box-sizing: border-box;
    max-width: 700px;
    padding: 1rem;
    margin: 0 auto;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        padding: 4rem;
    }
`;

function Card(props: {children: React.ReactNode}){
    return(
        <CardItem>
            {props.children}
        </CardItem>
    )
}

export default Card;