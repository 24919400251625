import React, { useEffect, useRef, useState } from "react";
import { GetUserDetails, Login } from "../Api/Account";
import { IUserDetails } from "../Models/IUserDetails";
import { AddToPersistentStorage, HoursToMinutes, SetLoginAfterToNow } from "../Helpers/Utility";
import { GetIpDataInfo } from "../Api/IpData";
import IContextDTO from "../Models/DTOs/IContextDTO";
import { LocalStorageKeys } from "../Constants/LocalStorageKeys";
import lscache from "lscache";
import { AxiosError } from "axios";
import { IsAuthenticated } from "../Helpers/UserUtility";
import moment from "moment";

export let UserSnapshot: Promise<IUserDetails> = InitUserDetails();

async function InitUserDetails() {
    //Clears expired data
    lscache.flushExpired();
    const controller = new AbortController();

    let tokenJson = lscache.get(LocalStorageKeys.AccessToken);
    let countryCode = lscache.get(LocalStorageKeys.CountryCode);

    if (countryCode === null || countryCode.length === 0) {
        countryCode = await FetchCountryCode(controller);
        AddToPersistentStorage(LocalStorageKeys.CountryCode, countryCode, HoursToMinutes(24));
    }

    if (tokenJson === null || tokenJson.length === 0) {
        return { CurrentCountryCode: countryCode, AspNetUserId: '' } as IUserDetails;
    }

    let userDetails = await FetchUserDetails(tokenJson, controller, countryCode);

    return userDetails;
}

async function FetchCountryCode(controller: AbortController) {
    let ipData = await GetIpDataInfo(controller);

    if (ipData instanceof AxiosError) {
        return "GB";
    }

    return ipData.country_code;
}

async function FetchUserDetails(token: string, controller: AbortController, countryCode?: string) {
    let newUserDetails = await GetUserDetails(token, controller);

    //Will clear local storage if user details is null
    if (newUserDetails === null || newUserDetails instanceof AxiosError) {

        if (countryCode) {
            return { CurrentCountryCode: countryCode, AspNetUserId: '' } as IUserDetails
        }

        const newCountryCode = await FetchCountryCode(controller);
        AddToPersistentStorage(LocalStorageKeys.CountryCode, newCountryCode, HoursToMinutes(24));

        return { CurrentCountryCode: newCountryCode, AspNetUserId: '' } as IUserDetails;
    }

    if (countryCode === null) {
        const newCountryCode = await FetchCountryCode(controller);

        AddToPersistentStorage(LocalStorageKeys.CountryCode, newCountryCode, HoursToMinutes(24));
        return { ...newUserDetails, CurrentCountryCode: newCountryCode };
    }

    return { ...newUserDetails, CurrentCountryCode: countryCode } as IUserDetails;
}

const AuthContext = React.createContext<IContextDTO>({
    UserDetails: {} as IUserDetails,
    LogIn: async () => { return true },
    LogOut: async () => { },
    Update: async () => { }
} as IContextDTO);

export const AuthContextProvider = (props: { children: React.ReactNode }) => {
    const [userDetails, setUserDetails] = useState<IUserDetails>({} as IUserDetails);
    // const [countryCode, setCountryCode] = useState<string>("");
    const controller = new AbortController();
    const isLoaded = useRef<boolean>(false);

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        //Every time user refreshes we reinit the user details via the api. This ensures the detail will always be up to date
        //is ONLY called when REFRESHING, NOT when navigating between views
        async function LoadData() {
            const newUserDetails = await InitUserDetails();

            checkLoginAfter(newUserDetails);
            setUserDetails(newUserDetails);
        }

        LoadData();
        isLoaded.current = true;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loginHandler(
        email: string,
        password: string,
        remember: boolean
    ) {
        let access_token = await Login(email, password, controller);

        if (access_token instanceof AxiosError || access_token === null) {
            return false;
        }

        const promise = FetchUserDetails(access_token, controller, userDetails.CurrentCountryCode);
        UserSnapshot = promise;

        const newDetails = await promise;

        if (newDetails === null
            || newDetails === undefined
            || newDetails.AspNetUserId === null
            || newDetails.AspNetUserId === undefined
            || newDetails.AspNetUserId.length <= 0) {
            return false;
        }

        if (remember) {
            AddToPersistentStorage(LocalStorageKeys.AccessToken, newDetails.Access_Token);
        }
        else {
            AddToPersistentStorage(LocalStorageKeys.AccessToken, newDetails.Access_Token, HoursToMinutes(24));
        }

        SetLoginAfterToNow();

        if (userDetails.CurrentCountryCode === null || userDetails.CurrentCountryCode === undefined) {
            const newCountryCode = await FetchCountryCode(controller);
            AddToPersistentStorage(LocalStorageKeys.CountryCode, newCountryCode, HoursToMinutes(24));
            setUserDetails({ ...newDetails, CurrentCountryCode: newCountryCode });
            return true;
        }

        setUserDetails({ ...newDetails, CurrentCountryCode: userDetails.CurrentCountryCode });
        return true;
    }

    function checkLoginAfter(userDetails: IUserDetails) {

        // If unauthenticated we remove the last detail
        if (userDetails === null || userDetails === undefined || !IsAuthenticated(userDetails)) {
            lscache.remove(LocalStorageKeys.LoginAfter);
            return;
        }

        // If the user has not set login after we do not do any further check
        if (userDetails.LoginAfter === null || userDetails.LoginAfter === undefined) {
            return;
        }

        // If the users stored date is null or empty
        // If the users stored date is inferior to the login after we log them out
        let lastLocalLogin = lscache.get(LocalStorageKeys.LoginAfter);
        const localTime = moment.utc(lastLocalLogin);
        const loginAfter = moment.utc(userDetails.LoginAfter);

        const isBefore = moment(localTime).isBefore(loginAfter);

        if (lastLocalLogin === null || lastLocalLogin === undefined || isBefore) {
            logoutHandler();
            return;
        }

        // If the users stored date is superior or equal to login after we dont do anything
    }

    async function logoutHandler() {
        lscache.remove(LocalStorageKeys.AccessToken);
        lscache.remove(LocalStorageKeys.LoginAfter);

        UserSnapshot = await { CurrentCountryCode: userDetails.CurrentCountryCode } as unknown as Promise<IUserDetails>;

        setUserDetails({ CurrentCountryCode: userDetails.CurrentCountryCode } as IUserDetails);
    }

    async function updateHandler(token: string) {
        const promise = FetchUserDetails(token, controller, userDetails.CurrentCountryCode);
        UserSnapshot = promise;
        let newUserDetails = await promise;

        //Will clear local storage if user details is null
        if (newUserDetails === null || newUserDetails === undefined) {
            logoutHandler();
            return;
        }

        AddToPersistentStorage(LocalStorageKeys.AccessToken, newUserDetails.Access_Token);
        setUserDetails({ ...newUserDetails, CurrentCountryCode: userDetails.CurrentCountryCode });
    }

    return (
        <AuthContext.Provider
            value={
                {
                    UserDetails: userDetails,
                    LogIn: loginHandler,
                    LogOut: logoutHandler,
                    Update: updateHandler
                } as IContextDTO
            }
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
