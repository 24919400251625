import { ReactNode } from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import DropDownDialog from "../DropDowns/DropDownDialog";
import PrimaryText from "./PrimaryText";


const Icon = styled.div`    
    color: ${Colours.TextDark};
    cursor: pointer;
    position: relative;
    div {
        display: flex;
        align-items: center;
        color: ${ Colours.TertiaryHighlight };
    }

    #text {
        display: none;
        position: absolute;
        bottom: 1000%;
        right: 0;                        
    }

    div:hover {        
        #text {
            display: flex;
            position: absolute;
            bottom: -100%;        
            right: -20px;           
        }
    }
`;

function QuestionTooltip(props: {children: string | string[] | number | ReactNode}){
    return(    
        <Icon>
            <div>
                <BsFillQuestionCircleFill size={24}/>
                <div id="text">
                    <DropDownDialog isActive={true} setIsActive={() => {}} isTooltip={true} ><PrimaryText>{props.children}</PrimaryText></DropDownDialog>
                </div>
            </div>
        </Icon>              
    )
}

export default QuestionTooltip;

