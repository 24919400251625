import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";

const Button = styled.button`
    background: transparent;
    color: ${Colours.IckonicPinkHighlight};
    font-family: ${ Fonts.Primary };
    border: 0;
    font-size: 10pt;
    padding: 0;
    cursor: pointer;
    :disabled {
        color: ${ Colours.TertiaryHighlight };
        opacity: 1;
    }

    &:not(:disabled):hover {
        opacity: 0.8;
    }
`;

function PinkTextButton(props: {children: string | string[], onClick?: () => void, disabled : boolean | undefined, testId?: string}){
    return(
        <Button onClick={props.onClick} disabled={props.disabled} data-testid={props.testId}>
            {props.children}
        </Button>
    )
};

export default PinkTextButton;