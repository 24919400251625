import axios, { AxiosError } from "axios";
import IpDataDTO from "../Models/DTOs/IpDataDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.ipDataUrl;
const BACKEND_KEY = config.ipDataKey;

export async function GetIpDataInfo(abortController: AbortController) {

    const responce = await axios
        .get(BACKEND_URL + "?api-key=" + BACKEND_KEY + "&fields=country_code", {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as IpDataDTO;
        })
        .catch((error: AxiosError) => {
            console.log("Ip data error");
            return error;
        });

    return responce;
}