export const ResponsiveBreakpoints = {
    SmallMobileBreakpoint: 300,
    MediumMobileBreakpoint: 400,
    LargeMobileBreakpoint: 450,
    XLMobileBreakpoint: 550,
    SmallTabletBreakpoint: 600,
    FooterDesktopWidth: 600,
    ArticleMobileBreakpoint: 900,
    TabletBreakpoint: 940,
    MobileNavbar: 1050,
    DesktopBreakpoint: 1100,
    ContainerDesktopWidth: 1266,
    WideScreenBreakpoint: 1350,
    SuperWideBreakpoint: 1920
};
