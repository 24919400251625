import { Suspense, useContext, useEffect, useState } from "react";
import { Await, defer, Outlet, useLoaderData, useLocation, } from "react-router-dom";
import styled from "styled-components";
import AcceptCookies from "../Components/AcceptCookies";
import Footer from "../Components/UI/Navigation/Footer";
import NavBar from "../Components/UI/Navigation/NavBar";
import { IsAuthenticated } from "../Helpers/UserUtility";
import { GetDefaultSearchFilters, ShouldHideNavbar, ShowWelcomeToast } from "../Helpers/Utility";
import { ISearchFilterDTO } from "../Models/DTOs/ISearchFilterDTO";
import AuthContext, { UserSnapshot } from "../Store/auth-context";
import Toast from "../Components/UI/Toasts/Toast";
import ScrollToTop from "../Components/UI/Navigation/ScrollToTop";
import { IUserDetails } from "../Models/IUserDetails";
import GlobalPopupManager from "../Components/UI/Modals/GlobalPopupManager";
import MarketingScripts from "../Components/Headers/MarketingScripts";
import { HeaderProperties } from "../Constants/HeaderProperties";

const Main = styled.main<{ hideNav: boolean }>`
    margin-top: ${(props) => (props.hideNav ? 0 : HeaderProperties.height)};
`;

const ContentContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    //min-height: 79.6vh;
    background: #151515;
    &.hideContent {
        display: none;
    }
`;

function RootLayout() {
    const { userPromise } = useLoaderData() as {
        userPromise: Promise<IUserDetails>;
    };

    const authCtx = useContext(AuthContext);
    const location = useLocation();

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [searchFilters, setSearchFilters] = useState<ISearchFilterDTO>(GetDefaultSearchFilters());
    const [userLoader, setUserLoader] = useState<Promise<IUserDetails>>(userPromise);

    function onSearchUpdate(value: string) {
        setSearchText(value);
    }

    function onSearchFilterUpdate(updatedFilters: ISearchFilterDTO) {
        const newFilters: ISearchFilterDTO = { ...updatedFilters };
        setSearchFilters(newFilters);
    }

    function clearSearch() {
        setSearchText("");
    }

    useEffect(() => {
        if (isLoaded) {
            ShowWelcomeToast(authCtx.UserDetails.Person?.FirstName, location);
            return;
        }

        setTimeout(() => {
            setIsLoaded(true);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.Update]);

    useEffect(() => {
        clearSearch();
    }, [location]);

    useEffect(() => {
        setUserLoader(UserSnapshot);
    }, [authCtx.LogOut]);

    if (!isLoaded) {
        return (
            <Main hideNav={ true }> </Main>
        );
    }

    return (
        <Suspense>
            <Await resolve={userLoader}>
                { (userData) => {
                    const user = userData as IUserDetails;
                    return (
                        <>
                            <MarketingScripts/>

                            <NavBar
                                searchText={ searchText }
                                onSearchUpdate={ onSearchUpdate }
                                searchFilter={ searchFilters }
                                onSearchFilterUpdate={ onSearchFilterUpdate }
                                isAuthenticated={ IsAuthenticated(user) }
                                currentCountryCode={ authCtx.UserDetails.CurrentCountryCode }
                            />

                            <Main hideNav={ ShouldHideNavbar(location.pathname) }>
                                <ContentContainer>

                                    {/* Outlet inserts screens into DOM */}
                                    <Outlet />
                                </ContentContainer>
                            </Main>

                            <Footer />
                            <GlobalPopupManager userData={ userData } />
                            <AcceptCookies />
                            <Toast />
                            <ScrollToTop />
                        </>
                    );
                } }
            </Await>
        </Suspense>
    );
}

export default RootLayout;

export async function Loader() {
    return defer({
        userPromise: UserSnapshot,
    });
}
