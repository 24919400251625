import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {IconClose} from "../../Assets/Icons/Icons";
import styled from "styled-components";

interface PopUpProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    padding?: string | number;
    borderRadius?: string | number;
}

const PopUpBasicDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background .45s cubic-bezier(.84,0,.13,.98);
    &.is-transitioning-in {
        background-color: rgba(0, 0, 0, 0.7);
        transition: background .45s cubic-bezier(.84,0,.13,.98) .01s;
        .popup__content {
            opacity: 1;
            transform: scale(1);
            transition: opacity .45s cubic-bezier(.84,0,.13,.98) .01s, transform .45s cubic-bezier(.84,0,.13,.98) .01s;
        }

        .popup__close {
            opacity: 1;
            transition: opacity .45s cubic-bezier(.84,0,.13,.98) .01s, background .25s cubic-bezier(.84,0,.13,.98);
        }
    }

    .popup__close {
        width: calc(40rem/16);
        height: calc(40rem/16);
        position: absolute;
        top: calc(10rem/16);
        right: calc(10rem/16);
        cursor: pointer;
        padding: calc(5rem/16);
        border-radius: calc(20rem/16);
        opacity: 0;
        transition: opacity .45s cubic-bezier(.84,0,.13,.98), background .25s cubic-bezier(.84,0,.13,.98);
        &:hover,
        &:focus,
        &:active {
            background: white;
            box-shadow: 0 0 calc(2rem/16) calc(1rem/16) rgba(0,0,0,0.2);
            svg {
                * {
                    fill: black;
                }
            }
        }

        svg {
            * {
                fill: white;
                transition: fill .25s cubic-bezier(.84,0,.13,.98);
            }
        }
    }

    .popup__content {
        max-width: 80%;
        max-height: 80%;
        position: relative;
        opacity: 0;
        transform: scale(.95);
        background: #0e0e0e;
        overflow-y: auto;
        transition: opacity .25s cubic-bezier(.84,0,.13,.98), transform .25s cubic-bezier(.84,0,.13,.98);
        @media screen and (min-width: 36.5em) and (min-height: calc(1000em/16)) {
            margin-bottom: 20vh;
        }
    }
`;

const PopUpBasic: React.FC<PopUpProps> = ({ isOpen, onClose, children, padding, borderRadius}) => {
    let closeTimeout: NodeJS.Timeout;
    const
        popupRef = useRef<HTMLDivElement>(null),
        [transitionDirection, setTransitionDirection] = useState<string>("out"),
        handleClose = () => {
            closeTimeout = setTimeout(() => {
                onClose();
            }, 450);
        };

    useEffect(() => {

        const handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setTransitionDirection("out");
                handleClose();
            }
        };

        if (isOpen) {
            setTransitionDirection("in");
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            if (closeTimeout) {
                clearTimeout(closeTimeout);
            }
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <PopUpBasicDiv
            className={`popup popup--basic ${transitionDirection === "in" ? 'is-transitioning-in' : 'is-transitioning-out'}`}>
            <div className="popup__close" onClick={() => handleClose()}>{IconClose()}</div>
            <div className="popup__content" style={{padding, borderRadius}} ref={popupRef}>
                {children}
            </div>
        </PopUpBasicDiv>
    );
};

export default PopUpBasic;
