import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import PlaceHolder from "../../../Assets/Images/Placeholders/IckonicPlaceholderLandscape.jpg";
import { useRef, useEffect } from "react";
import { RoutePaths } from "../../../Constants/RoutePaths";
import PrimaryText from "../Text/PrimaryText";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    padding: 6rem 2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    * {
        overflow-wrap: anywhere;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        padding: 12rem 2rem;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
        padding: 17rem 2rem;
    }
`;

const MediaContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
`;

const ContentContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    h1 {
        margin: 0 0 2rem 0;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
    }
    
    p {
        max-width: 550px;
        line-height: 1.75;
        margin: 0 auto 2rem auto;
        color: #f0f0f0;
        text-align: center !important;
        font-size: 14pt;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
    
    a {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
        background: rgba(223,75,220,0.2);
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {        
        p {
            font-size: 16pt;
        }
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {        
        p {
            max-width: 750px;
            font-size: 20pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {        
        a {
            font-size: 16pt;
        }
    }
`;

function PanelMain(props: {panel: IPanelDTO, freeDaysTrial: number}){

    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const properties = props.panel.Properties;
    const background = properties.find(
        (x) => x.PropertyType === PanelPropertyType.BackgroundImage
    );
    const videoBg = properties.find(
        (x) => x.PropertyType === PanelPropertyType.VideoUrl
    );

    const displayBackgroundImage = videoBg === null || videoBg === undefined;

    useEffect(() => {
        const videoElement = videoRef.current;
        const playVideo = async () => {
            if (videoElement) {
                try {
                    await videoElement.play();
                } catch (error) {
                    console.error("Video play failed.", error);
                }
            }
        };

        playVideo();

        return () => {
            if (videoElement) {
                videoElement.pause();
                videoElement.currentTime = 0;
            }
        };
    }, []);

    return (
        <Container ref={containerRef} style={displayBackgroundImage ?
            { background: `url(${background ? background.Value : PlaceHolder}) center/cover no-repeat`}
            : {background: ``}}>
            <MediaContainer>
                { videoBg && videoBg.Value && (
                    <video src={ videoBg ? videoBg.Value : undefined }
                           ref={ videoRef }
                           autoPlay={ true }
                           muted= {true}
                           loop={ true }
                           style={ { width:'100%', height: '100%', objectFit: 'cover' } }
                    />
                ) }
            </MediaContainer>

            <ContentContainer>
                {properties.map((property) => {
                    switch (property.PropertyType) {
                        case PanelPropertyType.Title:
                            return (
                                <Heading key={"T" + property.Id} type={ HeadingType.H1 }>{property.Value.replace("{freetrialdays}", props.freeDaysTrial.toString())}</Heading>
                            );
                        case PanelPropertyType.Text:
                            return (
                                <PrimaryText key={"T" + property.Id}>
                                    {parse(
                                        DOMPurify.sanitize(property.Value.replace("{freetrialdays}", props.freeDaysTrial.toString()))
                                    )}
                                </PrimaryText>
                            );
                        case PanelPropertyType.ButtonText:
                            return (
                                <NavLinkPinkButtonFat key={"B" + property.Id} to={RoutePaths.CreateYourAccount}>
                                    {property.Value.replace("{freetrialdays}", props.freeDaysTrial.toString())}
                                </NavLinkPinkButtonFat>
                            );
                        case PanelPropertyType.BackgroundImage:
                        case PanelPropertyType.Image:
                        default:
                            return null;
                    }
                })}
            </ContentContainer>
        </Container>
    );
}

export default PanelMain;