import {Colours} from "../Constants/Colours";

interface Category {
    primaryName: string;
    colour: string;
}

interface Categories {
    [key: string]: Category; // This allows for additional categories to be added dynamically
}

const Categories: Categories = {
    OriginalFilms: {
        primaryName: "Original Films",
        colour: Colours.IckonicPink
    },
    OriginalSeries: {
        primaryName: "Original Series",
        colour: Colours.IckonicPink
    },
    HealthAndWellbeing: {
        primaryName: "Health & Wellbeing",
        colour: Colours.HealthAndWellbeing,
    },
    Consciousness: {
        primaryName: "Consciousness",
        colour: Colours.Consciousness
    },
    GlobalCurrentEvents: {
        primaryName: "David Icke",
        colour: Colours.GlobalCurrentEvents,
    },
    DavidIcke: {
        primaryName: "Global Current Events",
        colour: Colours.GlobalCurrentEvents,
    },
    Documentaries: {
        primaryName: "Documentaries",
        colour: Colours.Documentaries,
    },
    HiddenKnowledge: {
        primaryName: "Occult & Hidden Knowledge",
        colour: Colours.HiddenKnowledge
    }
    // Add more categories as needed
};

export default Categories;
