import { NeedsRenew } from "../Helpers/UserUtility";
import { Suspense, useContext, useEffect, useState } from "react";
import AuthContext, { UserSnapshot } from "../Store/auth-context";
import { PanelSelector } from "../Models/Enums/PanelSelector";
import { Await, redirect, useRouteLoaderData } from "react-router-dom";
import { IUserDetails } from "../Models/IUserDetails";
import BrowseLoader from "./UI/PageLoaders/BrowseLoader";
import { RoutePaths } from "../Constants/RoutePaths";

// Free Loaders
import { Loader as BrowseScreenFreeLoader } from '../Screens/Watch/Free/BrowseScreenFree';
import { Loader as SeriesScreenFreeLoader } from '../Screens/Watch/ContentListScreens/Free/SeriesScreenFree';
import { Loader as FilmsScreenFreeLoader } from '../Screens/Watch/ContentListScreens/Free/FilmsScreenFree';
import { Loader as CategoryDetailsFreeLoader } from '../Screens/Watch/ContentListScreens/Free/CategoryDetailsScreenFree';

// Subscribed Loaders
import { Loader as BrowseScreenLoader } from '../Screens/Watch/Subscribed/BrowseScreen';
import { Loader as SeriesScreenLoader } from '../Screens/Watch/ContentListScreens/Subscribed/SeriesScreen';
import { Loader as FilmsScreenLoader } from '../Screens/Watch/ContentListScreens/Subscribed/FilmsScreen';
import { Loader as CategoryDetailsLoader } from '../Screens/Watch/ContentListScreens/Subscribed/CategoryDetailsScreen';

function AuthScreenRedirect(props: { SubscribedScreen: JSX.Element; FreeScreen: JSX.Element }) {
    const
        authCtx = useContext(AuthContext),
        [isLoaded, setIsLoaded] = useState<boolean>(false),
        { userPromise } = useRouteLoaderData("root") as {
            userPromise: Promise<IUserDetails>;
        },
        [userLoader, setUserLoader] = useState<Promise<IUserDetails>>(userPromise);

    useEffect(() => {
        setIsLoaded(true);
        setUserLoader(UserSnapshot);
    }, [authCtx.LogOut, authCtx.LogIn]);

    if (!isLoaded) {
        return (
            <BrowseLoader />
        )
    }

    return (
        <Suspense>
            <Await resolve={userLoader}>
                {(userData) => {
                    const user = userData as IUserDetails;
                    return user.AspNetUserId !== undefined && user.AspNetUserId !== '' ? props.SubscribedScreen : props.FreeScreen;
                }}
            </Await>
        </Suspense>
    )
}

export async function Loader(selector: PanelSelector) {
    const userDetails = await UserSnapshot;

    const needsRenew = NeedsRenew(userDetails);
    if (needsRenew) {
        return redirect(RoutePaths.Renew);
    }

    const
        newsAndCurrentAffairsParams = { id: 13, featuredImage: "https://ickesaimage.blob.core.windows.net/compressed/news-banner-3.jpg" },
        davidIckeParams = { id: 17, featuredImage: "https://ickesaimage.blob.core.windows.net/compressed/di-banner-min-3.png" };

    switch (selector) {
        case PanelSelector.Browse:
            return userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId !== '' ? await BrowseScreenLoader() : await BrowseScreenFreeLoader();
        case PanelSelector.Series:
            return userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId ? await SeriesScreenLoader() : await SeriesScreenFreeLoader();
        case PanelSelector.Films:
            return userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId ? await FilmsScreenLoader() : await FilmsScreenFreeLoader();
        case PanelSelector.DavidIcke:
            return userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId ? await CategoryDetailsLoader(davidIckeParams) : await CategoryDetailsFreeLoader(davidIckeParams);
        case PanelSelector.NewsAndCurrentAffairs:
            return userDetails.AspNetUserId !== undefined && userDetails.AspNetUserId ? await CategoryDetailsLoader(newsAndCurrentAffairsParams) : await CategoryDetailsFreeLoader(newsAndCurrentAffairsParams);
        case PanelSelector.Generic:
            break;
    }

    return selector;
}

export default AuthScreenRedirect;