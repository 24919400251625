import { useState, useRef, useReducer, useEffect } from "react";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from '../../../Models/Enums/InputState';
import { ContainsAt } from "../../../Helpers/Utility";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { HelpSubject } from "../../../Models/Enums/HelpSubject";
import { IHelpRequestDTO } from "../../../Models/DTOs/IHelpRequestDTO";
import { SendHelpRequest } from "../../../Api/HelpTopics";
import { HelpTopicToString } from '../../../Helpers/Utility';
import { toast } from "react-toastify";

import styled from "styled-components";
import InputWithLabel from "../Inputs/InputWithLabel";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import PrimaryText from "../Text/PrimaryText";
import TextArea from "../Inputs/TextArea";
import PinkButton from "../Buttons/PinkButton";
import Heading from "../Text/Heading";
import Selecter from "../Selecters/Selecter";
import ISelecter from "../../../Models/DTOs/ISelecter";
import { AxiosError } from "axios";
import { IsAuthenticated } from "../../../Helpers/UserUtility";
import { IUserDetails } from "../../../Models/IUserDetails";


const Container = styled.form`
    padding-left:30px;
    padding-right:30px;
    padding-top:20px;
    text-align: left;

    p {
        text-align: left;
    }

    input, textarea {
        background-color: #646464;
    }

    button {

        margin-top: 30px;

        h3 {
            margin: 0;
        }
    }
`;

function ContactUs(props : {
    userDetails: IUserDetails
}) {

    const  messageInput = useRef<HTMLTextAreaElement | null>(null);
    const emailInput = useRef<HTMLInputElement | null>(null);
    const subjectRef = useRef<HTMLInputElement | null>(null);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>();
    const [subjectChanged, setSubjectChanged] = useState<boolean>(false);
    const [subjectError, setSubjectError] = useState<string>();
    const [messageError, setMessageError] = useState<string>();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);

    let userMail = "";

    if (IsAuthenticated(props.userDetails)) {
        userMail = props.userDetails.Email;
    }

    const options: ISelecter[] = [
        { value: HelpSubject.CustomerService, label: "Customer Service" },
        {
            value: HelpSubject.TechnicalSupport,
            label: "Technical Support",
        },
        {
            value: HelpSubject.ContentSuggestions,
            label: "Content Suggestions",
        },
        { value: HelpSubject.Careers, label: "Careers" },
        { value: HelpSubject.Other, label: "Other" },
    ];

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (isSubmitting || formSuccess) {
            return;
        }
        setIsSubmitting(true);
        
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        dispatchMessage({
            Value: messageState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        dispatchSubject({
            Value: messageState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        if (!formIsValid || (emailState.Type === InputState.Not_Set || 
                             messageState.Type === InputState.Not_Set ||
                             subjectState.Type === InputState.Not_Set)) 
        {
            return;
        }

        const data: IHelpRequestDTO = {
            Message : messageState.Value,
            Subject: HelpTopicToString(subjectState.Value),
            UserEmail: emailState.Value,
        }

        const result = await SendHelpRequest(data);

        if(result instanceof AxiosError){
            toast.error("An error has occured. Please try again or send an email directly to support@ickonic.com.");
            setIsSubmitting(false);
            return;
        }

        if (result) {
            setFormSuccess(true);
            toast.success("Your message has been sent to our team, we will do our best to get back to you as soon as possible.");
        }
        else {
            toast.error("An error has occured. Please try again or send an email directly to support@ickonic.com.");
        }

        setIsSubmitting(false);
    }

    const [subjectState, dispatchSubject] = useReducer(sujectReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: userMail,
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [messageState, dispatchMessage] = useReducer(messageReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    function emailReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetEmailKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetEmailValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function messageReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetMessageValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetMessageValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: "",
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function sujectReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetSubjectValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetSubjectValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: "",
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function GetMessageValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setMessageError("Your message cannot be empty.");
            return InputIsValid.Invalid;
        }

        setMessageError(undefined);
        return InputIsValid.Valid;
    }

    function GetSubjectValidState(text: string) {
        setSubjectChanged(true);
        if (text.length === 0) {
            return InputIsValid.Invalid;
        }

        setSubjectError(undefined);
        return InputIsValid.Valid;
    }

    function GetEmailValidState(text: string) : InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("Your e-mail cannot be empty.");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("Not a valid e-mail.");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    }

    function GetEmailKeyUpState(text: string) : InputIsValid {
        if (emailError === '' || emailError === undefined || emailError === null) {
            return InputIsValid.Valid;
        }
        return GetEmailValidState(text);
    }

    function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function messageChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        dispatchMessage({
            Type: InputState.User_Input,
            Value: event.target.value,
        } as IInputDTO);
    }

    function subjectChangeHandler(option: ISelecter) {
        dispatchSubject({
            Type: InputState.User_Input,
            Value: option.value,
        } as IInputDTO);
    }

    function validateEmailHandler() {
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function validateMessageHandler() {
        dispatchMessage({
            Value: messageState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function validateSubjectHandler() {
        dispatchSubject({
            Value: subjectState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    const emailIsValid = emailState.IsValid === InputIsValid.Valid;
    const messageIsValid = messageState.IsValid === InputIsValid.Valid;
    const subjectIsValid = (subjectState.IsValid !== InputIsValid.Invalid) && 
                           (subjectState.IsValid !== InputIsValid.NotSet) && 
                           (subjectError === undefined);


    useEffect(() => {
        const identifier = setTimeout(() => {
            if (IsAuthenticated(props.userDetails)) {
                setFormIsValid(
                    messageIsValid && subjectIsValid
                );
            }
            else {
                setFormIsValid(
                    emailIsValid && messageIsValid && subjectIsValid
                );
            }
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailIsValid, messageIsValid, subjectIsValid]);

    return(
        <Container onSubmit={OnSubmit}>

            <Selecter
                ref={subjectRef}
                options={options}
                placeholder={"Select a subject"}
                onChange={subjectChangeHandler}
                onBlur={validateSubjectHandler}
                isValid={subjectIsValid || !subjectChanged}
                backgroundColour={'#646464'}
                highlightColour={'#333333'}
                errorMessage={messageError}
            />

            <InputWithLabel
                ref={emailInput}
                placeholder={"Enter your e-mail"}
                type={"text"}
                isValid={emailIsValid || emailState.IsValid === InputIsValid.NotSet}
                value={emailState.Value}
                onChange={emailChangeHandler}
                onBlur={validateEmailHandler}
                label={"E-mail"}
                errorMessage={emailError}
                required={true}
                disabled={userMail === "" ? false : true}
            />

            <PrimaryText>Message</PrimaryText>
            <TextArea
                ref={messageInput}
                isValid={messageIsValid || messageState.IsValid === InputIsValid.NotSet}
                required={true}
                onChange={messageChangeHandler}
                onBlur={validateMessageHandler}
                value={messageState.Value}
                errorMessage={messageError}
            />

            <PinkButton type="submit" disabled={isSubmitting || !formIsValid || formSuccess}>
                <Heading type={HeadingType.H3}>Submit</Heading>
            </PinkButton>

        </Container>
    );
}

export default ContactUs;