import styled from "styled-components";
import IVideoDTO from "../Models/DTOs/IVideoDTO";
import Heading from "./UI/Text/Heading";
import ReadMoreText from "./UI/ReadMoreText";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import { Colours } from "../Constants/Colours";
import { Fonts } from "../Constants/Fonts";

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-image: linear-gradient(
            90deg,
            rgba(29, 29, 29, 1) 5%,
            rgba(29, 29, 29, 0.95) 47%,
            rgba(255, 255, 255, 0) 100%
        ),
        url("https://ickesaimagecdnendpoint.azureedge.net/compressed/Featured Header(1).jpg");
    background-size: cover;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        background: transparent;
    }
`;

const Split = styled.div`
    flex-basis: 50%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;

    @media only screen and (max-width: 800px) {
        flex-basis: 100%;
        width: 100%;
        padding: 0 0 10px 0;
    }
`;

const VideoContainer = styled.div`
    width: 94%;
    max-width: 177.78vh;
    height: 26.4vw;
    max-height: 100vh;

    @media only screen and (max-width: 800px) {
        width: 100vw;
        height: 56.25vw;
    }
`;

const DetailsContainer = styled.div`
    width: 100%;
    padding: 0 5%;

    //BebasTitle extra styles
    h1 {
        font-size: 2vw;
        margin: 0.2em 0;
    }

    @media only screen and (max-width: 800px) {
        padding: 0 3%;

        h1 {
            font-size: 6vw;
        }
    }
`;

const ContentType = styled.div`
    background: ${Colours.IckonicPink};
    width: max-content;
    padding: 5px;
`;

const ContentTypeText = styled.h3`
    color: white;
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    margin: 0;
    letter-spacing: 0.7px;

    @media only screen and (max-width: 800px) {
        font-size: 4vw;
    }
`;

function NewsHeader(props: { latestVideo: IVideoDTO | undefined}) {
    
    if(props.latestVideo === undefined){
        return null;
    }

    return (
        <Header>
            <Split>
                <DetailsContainer>
                    <ContentType>
                        <ContentTypeText>
                            WEEKLY SHOWS
                        </ContentTypeText>
                    </ContentType>
                    <Heading>
                        {props.latestVideo.Title}
                    </Heading>
                    <ReadMoreText limit={200}>
                        {props.latestVideo.Description}
                    </ReadMoreText>
                </DetailsContainer>
            </Split>
            <Split>
                <VideoContainer>
                    <VideoPlayer src={props.latestVideo.Link} poster={props.latestVideo.Thumbnail}/>
                </VideoContainer>
            </Split>
        </Header>
    );
}

export default NewsHeader;
