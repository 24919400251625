import styled from "styled-components";
import SearchFilters from "./SearchFilters";
import SearchScreen from "../../../Screens/SearchScreen";

import { ISearchFilterDTO } from "../../../Models/DTOs/ISearchFilterDTO";
import { useState } from "react";
import { HeaderProperties } from "../../../Constants/HeaderProperties";
import { IconSearchMagnifier } from "../../../Assets/Icons/Icons";
import { Ease } from "../../../Constants/EasingCurves";

const styledIcon = `
  cursor: pointer;
  width: 40px;
  padding: 11px;
  height: ${ HeaderProperties.height };
`;

const SearchIcon = styled.div`
  ${ styledIcon }
  svg {
    * {
      fill: #bcbcbc;
      transition: fill .15s ${ Ease.Smooth };
    }
  }
  
  &:hover,
  &:active {
      svg {
        * {
          fill: white;
        }
      }
  }
`;

function SearchBar(props: {
    searchText: string;
    onSearchUpdate: (text: string) => void;
    searchFilter: ISearchFilterDTO;
    onSearchFilterUpdate: (filters: ISearchFilterDTO) => void;
    toggleMobileMenuClosed: () => void;
    currentCountryCode: string;
}) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    function OnActiveToggle(value: boolean) {
        setIsLoaded(true);
        setIsActive(value);

        // Close the mobile menu if open
        props.toggleMobileMenuClosed();
    }

    function SetIsActive(value: boolean) {
        setIsActive(value);
    }

    return (
        <div style={{ margin: '0 0 0 auto' }}>
            <SearchFilters
                Header={
                    <>
                        <SearchIcon
                            onClick={() => OnActiveToggle(!isActive)}
                        >
                            <IconSearchMagnifier />
                        </SearchIcon>
                    </>
                }
                isLoaded={isLoaded}
                isActive={isActive}
                setIsActive={SetIsActive}
                mobileLayout={false}
                searchFilter={props.searchFilter}
                searchText={props.searchText}
                onSearchUpdate={ props.onSearchUpdate }
                onSearchFilterUpdate={props.onSearchFilterUpdate}
            >
                <SearchScreen
                    searchText={props.searchText}
                    searchFilters={props.searchFilter}
                    currentCountryCode={props.currentCountryCode}
                    disablePopUp={ () => SetIsActive(false) }
                />
            </SearchFilters>
        </div>
    );
}

export default SearchBar;
