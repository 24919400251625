import { Await, defer, Params, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { GetCategory } from "../../../Api/Category";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import ICategoryDTO from "../../../Models/DTOs/ICategoryDTO";
import {Suspense, useContext, useState} from "react";
import AuthContext, { UserSnapshot } from "../../../Store/auth-context";
import InfiniteScroll from "react-infinite-scroll-component";
import ListContainer from "../../../Components/UI/List/ListContainer";
import CarouselItem from "../../../Components/Carousels/CarouselItem";
import { SetTitle } from "../../../Helpers/Utility";
import Heading from "../../../Components/UI/Text/Heading";
import NavLinkPinkButton from "../../../Components/UI/Buttons/NavLinkPinkButton";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { GetCategoryContent } from "../../../Api/Carousel";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import VideoSingleLoader from "../../../Components/UI/PageLoaders/VideoSingleLoader";
import HeadingLoader from "../../../Components/UI/PageLoaders/HeadingLoader";

const Container = styled.section`
        padding: 1rem;
        width: 100%;
        max-width: calc(1616rem/16);
        margin: 0 auto;
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            padding: 1.5rem;
        }
    `,
    HeaderContainer = styled.div`
        display: flex;
        margin: 0 0 2rem 0;
        justify-content: space-between;
        align-items: center;
        h1 {
            margin: 0;
            font-size: 30px;
            line-height: 30px;
        }
        
        a {
            margin: 0;
        }
    `,
    take = 30;

function CategoryScreen() {

    const { categoryPromise, categoryContentPromise, categoryId } = useLoaderData() as {
        categoryPromise: Promise<ICategoryDTO>;
        categoryContentPromise: Promise<ICarouselContentDTO[]>;
        categoryId: number;
    };

    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [categoryContent, setCategoryContent] = useState<ICarouselContentDTO[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);

    async function Categories(skip: number) {
        const
            result = await GetCategoryContent(
                Number(categoryId),
                authCtx.UserDetails.CurrentCountryCode,
                authCtx.UserDetails.AspNetUserId,
                take,
                skip,
                controller
            );

        if (result === null || result === undefined || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setCategoryContent((prev) => [...prev, ...result]);
    }

    return (
        <Container>
            <Suspense fallback={
                <>
                    <HeaderContainer>
                        <HeadingLoader paddingTop="32" />
                    </HeaderContainer>

                    <ListContainer>
                        { [...Array(5)].map((e, i) => (
                            <VideoSingleLoader paddingTop="calc(16rem/16)" key={ i } />
                        )) }
                    </ListContainer>
                </>
            }>
                <Await resolve={categoryPromise}>
                    {(categoryPromiseData) => {
                        const categoryData = categoryPromiseData as ICategoryDTO;
                        SetTitle(categoryData.Title);

                        return (
                            <HeaderContainer>
                                <Heading>{categoryData.Title}</Heading>
                                <NavLinkPinkButton to={RoutePaths.Browse}>Back to Browse</NavLinkPinkButton>
                            </HeaderContainer>
                        );
                    }}
                </Await>

                <Await resolve={categoryContentPromise}>
                    {(categoriesContentData) => {

                        const initCategories = categoriesContentData as ICarouselContentDTO[];
                        const mergedLists = initCategories ? [...initCategories, ...categoryContent] : [];

                        return (
                            <InfiniteScroll
                                dataLength={mergedLists.length}
                                hasMore={hasMore}
                                next={() => Categories(mergedLists.length)}
                                loader={
                                    <ListContainer>
                                        { [...Array(5)].map((e, i) => (
                                            <VideoSingleLoader paddingTop="calc(16rem/16)" key={ i } />
                                        )) }
                                    </ListContainer>
                                }
                            >
                                <ListContainer>
                                    {mergedLists.map((content, index) => {
                                        return (
                                            <CarouselItem
                                                key={index.toString() + content.Id}
                                                Content={content}
                                                disableOverlay={true}
                                            />
                                        );
                                    })}
                                </ListContainer>
                            </InfiniteScroll>
                        );
                    }}
                </Await>
            </Suspense>
        </Container>
    );
}

export default CategoryScreen;

export async function Loader({
    params,
    category,
} : {
    params: Readonly<Params<string>> | null;
    category: number | undefined | null;
}) {
    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    let categoryId: number;
    if (params !== null && (category === null || category === undefined)) {
        categoryId = Number(params.id);
    } else if (!(category === null || category === undefined)) {
        categoryId = category;
    }
    else {
        categoryId = 1;
    }

    async function InitCategory() {
        var result = await GetCategory(categoryId, controller);
        return result;
    }

    async function InitCategoryContent() {

        const result = await GetCategoryContent(
            categoryId,
            userDetails.CurrentCountryCode,
            userDetails.AspNetUserId,
            take,
            0,
            controller
        );

        return result;
    }

    return defer({
        categoryPromise: InitCategory(),
        categoryContentPromise: InitCategoryContent(),
        categoryId : categoryId,
    });
}