import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const buttonStyles = `
    box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.Text };
    background: ${ Colours.Text };
    color: ${ Colours.SecondaryDarker };
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    font-size: 14pt;
    padding: 12px 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    text-align: center;
    border: 0;
    line-height: 1;
    margin: 0 auto;
    font-weight: 600;
    transition: background .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth };
    :disabled {
        opacity: 0.7;
    }
    
    svg {
        margin: 0 calc(8rem/16) 0 0;
        * {
            fill: black;
            transition: fill .25s ${ Ease.Smooth };
        }
    }

    &:not(:disabled):hover {
        cursor: pointer;
        color: ${ Colours.Text };
        background-color: none;
        background: none;
        svg {
            * {
                fill: white;
            }
        }
    }
`;

const Link = styled(NavLink)`
    ${ buttonStyles }
`;

const Button = styled.button`
    ${ buttonStyles }
`;

function NavLinkWhiteButton(props: { children: React.ReactNode; to: string }) {
    return props.to ? (
            <Link to={props.to}>
                {props.children}
            </Link>
        )
        :
        (
            <Button>
                {props.children}
            </Button>
        )
}

export default NavLinkWhiteButton;
