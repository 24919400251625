import { FaExclamationCircle } from "react-icons/fa";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import PrimaryText from "../Text/PrimaryText";

const SubMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 7px;
    margin: 0 0 1rem 0;
    border: 1px solid ${ Colours.IckonicPinkDark };
    p {
        width: calc(100% - (41rem/16));
        color: ${ Colours.Text };
        font-size: 10pt;
    }
`;

const Alert = styled(FaExclamationCircle)`
    color: ${ Colours.Text };
    font-size: 19pt;
`;

function SubAlert(props: { children: string | string[] }) {
    return (
        <SubMessageContainer>
            <Alert />
            <PrimaryText>{ props.children }</PrimaryText>
        </SubMessageContainer>
    );
}

export default SubAlert;
