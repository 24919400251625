import React, {
    useRef,
    useImperativeHandle,
    forwardRef,
    HTMLInputTypeAttribute,
} from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import ErrorText from "../Text/ErrorText";
import { Fonts } from "../../../Constants/Fonts";
import {Ease} from "../../../Constants/EasingCurves";

const Container = styled.div<{removeMargin?: boolean}>`
    margin: ${props => props.removeMargin ? 0 : "0 0 1rem 0"} ;
    input { 
        width: 100%;
        font-family: ${ Fonts.Primary };
        padding: 20px;
        border-radius: 7px;
        border: 1px solid ${ Colours.SecondaryHighlight };
        background: black;
        color: ${Colours.Text};
        transition: border .25s ${ Ease.Smooth };
    }

    input::placeholder {
        color: ${Colours.Text};
    }
    
    input:hover {
        border-color: ${ Colours.TertiaryHighlight };
    }

    input:focus {
        outline: none;
        border-color: ${ Colours.TertiaryHighlight };
    }

    input:disabled {
        opacity: 0.5;
        outline: none;
    }

    .invalid {
        border-color: ${Colours.Error};
    }

    input {
        display: block;
    }

    @media (min-width: 768px) {

    }
`;

const ErrorContainer = styled.div`
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;

    p {
        font-size: 12pt;
    }

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
        height: 0;
    }
`;

const Input = forwardRef(
    (
        props: {
            isValid: boolean;
            id?: string;
            type: HTMLInputTypeAttribute;
            value?: string;
            placeholder?: string;
            required?: boolean;
            errorMessage?: string;
            defaultValue?: string,
            disabled?: boolean,
            removeMargin?: boolean
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
            onBlur: () => void;
            children?: string | string[] | number | React.ReactNode;
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        function activate() {
            inputRef.current?.focus();
        }

        useImperativeHandle(ref, () => {
            return {
                focus: activate,
            };
        });

        return (
            <Container removeMargin={props.removeMargin}>
                <input
                    ref={inputRef}
                    className={`${props.isValid === false ? "invalid" : ""}`}
                    type={props.type}
                    id={props.id}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    required={props.required}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                />
                <ErrorContainer
                    className={`${props.isValid === false ? "active" : ""}`}
                >
                    {props.errorMessage !== undefined ? (
                        <ErrorText>{props.errorMessage}</ErrorText>
                    ) : null}
                </ErrorContainer>

                { props.children && props.children }
            </Container>
        );
    }
);

export default Input;
