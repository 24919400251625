import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import SocialAndRatingsLoader from "./SocialAndRatingsLoader";
import PlayerLoader from "./PlayerLoader";

const Container = styled.div`
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;

const Title = styled.div`
    height: 30px;
    background-color: ${Colours.SecondaryHighlight};
    margin:20px 10px 0 10px;
    width: 800px;
    border-radius: 10px;
`;

function WatchPageLoader() {
    return (
        <Container>
            <PlayerLoader />

            <Title />

            <SocialAndRatingsLoader />
        </Container>
    );
}

export default WatchPageLoader;