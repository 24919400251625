import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const Button = styled(NavLink)`
    box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.IckonicPink};
    background: none;
    color: white;
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    font-size: 14pt;
    padding: 20px 30px;
    display: inline-block;
    border-radius: 31px;
    text-align: center;
    margin: 0 auto;
    line-height: 1;
    font-weight: 600;
    transition: background .25s ${ Ease.Smooth };
    :disabled {
        opacity: 0.7;
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background-color: ${Colours.IckonicPinkSubtleHightlight};
    }
`;

function NavLinkPinkButtonFat(props: {children: React.ReactNode, to: string, testId?: string }){
    return(
        <Button to={props.to} data-testid={props.testId}>
            {props.children}
        </Button>
    )
}

export default NavLinkPinkButtonFat;