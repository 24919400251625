import React from "react";
import styled from "styled-components";
import {Colours} from "../../../Constants/Colours";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import Heading from "../Text/Heading";
import NavLinkBlackButton from "../Buttons/NavLinkBlackButton";
import PrimaryText from "../Text/PrimaryText";
import ImageLazyLoad from "../ImageLazyLoad";
import {Ease} from "../../../Constants/EasingCurves";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import { NavLink } from "react-router-dom";
import {RoutePaths} from "../../../Constants/RoutePaths";

const
    Container = styled.div`
        width: 100%;
        margin: 0 0 2rem 0;
        p {
            margin: calc(16rem/16) 0 0 0;
            color: ${ Colours.TertiaryHighlight };
        }
        
        h3 {
            font-weight: 400;
            margin: calc(5rem/16) 0 0 0;
            font-size: 14pt;
            a {
                color: white;
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.MediumMobileBreakpoint}rem/16)) {
            width: calc(50% - (8rem/16));
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            width: calc(50% - (16rem/16));
            margin: 0 0 3rem 0;
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
            width: calc(25% - (24rem/16));
        }
    `,
    ImageContainer = styled(NavLink)`
        position: relative;
        overflow: hidden;
        border-radius: calc(10rem/16);
        background: ${ Colours.IckonicPinkSubtleHightlight };
        display: block;
        a,
        button {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 82px);
            opacity: 0;
            transform: translateY(20px);
            transition: transform .15s ${ Ease.LateSnap }, opacity .15s ${ Ease.LateSnap }, background .25s ${ Ease.Smooth }, box-shadow .15s ${ Ease.Smooth };
        }
        
        img {
            transition: transform .5s ${ Ease.Smooth }, opacity .5s ${ Ease.Smooth };
            transform: scale(1.02);
        }
        
        &:hover,
        &:active {
            img {
                opacity: 0.5;
                transform: scale(1.05);
            }
            
            a,
            button {
                opacity: 1;
                transform: translateY(0);
            }
        }
    `;

function ArticleGridItemSingle(props: { article: ICarouselContentDTO }) {
    const redirect = props.article ? RoutePaths.ReadArticle(props.article.Link) : null;
    return (
        <Container className="article-single">
            <ImageContainer to={ redirect != null ? redirect : "/" }>
                <ImageLazyLoad source={props.article.Thumbnail} />

                <NavLinkBlackButton>
                    Read Article
                </NavLinkBlackButton>
            </ImageContainer>

            <PrimaryText>{ props.article.Title }</PrimaryText>

            <Heading type={ HeadingType.H3 }>
                <NavLink to={ redirect != null ? redirect : "/" }>
                    { props.article.Text }
                </NavLink>
            </Heading>
        </Container>
    );
}

export default ArticleGridItemSingle;
