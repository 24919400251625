import axios, { AxiosError } from "axios";
import IPlanDTO from "../Models/DTOs/IPlanDTO";
import { PlanType } from "../Models/Enums/PlanType";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.subscription;

export async function GetPlans(countryCode: string, abortController: AbortController, userId?: string, currency?: string,){
    const responce = await axios.get(BACKEND_URL + "GetPlans/" + countryCode  + "/" + userId + "/" + currency, {signal: abortController.signal}).then((responce) => {
        return responce.data as IPlanDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetPlans error");
        return error;
    });

    return responce;
}

export async function GetPlanByType(countryCode: string, type: PlanType, abortController: AbortController, userId?: string){
    const responce = await axios.get(BACKEND_URL + "GetPlanByType/" + countryCode+ "/"+ type + "/" + userId, {signal: abortController.signal}).then((responce) => {
        return responce.data as IPlanDTO;
    }).catch((error: AxiosError) => {
        console.log("GetPlanByType error");
        return error;
    });

    return responce;
}