import { IMenuItem } from "../Models/IMenuItem"
import { RoutePaths } from "./RoutePaths"

export const WatchSecondary = {
    items: [
        { Title: "Browse", Link: RoutePaths.Browse },
        { Title: "Series", Link: RoutePaths.Series },
        { Title: "Films", Link: RoutePaths.Films },
        { Title: "Weekly Shows", Link: RoutePaths.WeeklyShows },
        { Title: "David Icke", Link: RoutePaths.DavidIcke },
    ] as IMenuItem[],
    breakpoint: 506,
    links: [
        RoutePaths.Browse, RoutePaths.News, RoutePaths.Films, RoutePaths.Series, RoutePaths.DavidIcke
    ]
};