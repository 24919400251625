import { NavLink } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { GetContentRedirect } from "../../Helpers/Utility";
import styled from "styled-components";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";
import ImageLazyLoad from "../UI/ImageLazyLoad";
import PrimaryText from "../UI/Text/PrimaryText";
import Rating from "../UI/Rating";
import Original from "../UI/Icons/Original";
import { Colours } from "../../Constants/Colours";
import AuthContext from "../../Store/auth-context";
import { Ease } from "../../Constants/EasingCurves";
import { format } from 'date-fns'

const Container = styled(NavLink)`
    width: 100%;
    height: auto;
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
    position: relative;
    img {
        //border-radius: 10px;
    }

    //Title
    h1 {
        margin: 0;
    }

    > p:nth-child(3)  {
        font-weight: bold;
    }

    > div:last-child{
        justify-content: flex-start;
    }


    &:hover,
    &:focus {
        &.is-not-free {
            .carousel-item__overlay {
                box-shadow: inset 0 0 calc(20rem/16) 0 rgba(50,50,50,0.8);
            }
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &.has-lifetime-early-access {
        animation: pulseSoftAccentInner 2s infinite ${Ease.Smooth};
    }
`;

const LabelLatest = styled.div`
    position: absolute;
    padding: calc(4rem/16) calc(13rem/16) calc(4rem/16) calc(13rem/16);
    background: rgba(0,0,0,0.85);
    color: white;
    font-weight: bold;
    top: calc(15rem/16);
    left: 0;
    font-size: 10pt;
    text-align: center;
    z-index: 4;
    text-transform: uppercase;
    box-shadow: 0 0 calc(12rem/16) 0 ${Colours.IckonicPinkHighlight};
`;

const LabelFree = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    padding: calc(25rem/16) calc(16rem/15);
    color: ${Colours.TextBright};
    text-align: center;
    z-index: 3;
    width: 100%;
    border-radius: 10px;
    font-sizE: 16pt;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: inset 0 0 calc(100rem/16) calc(0rem/16) rgba(187,54,184,0.5);
    transition: box-shadow .45s ${Ease.Smooth}, padding .45s ${Ease.Smooth};
    span {
        padding: calc(2rem/16) calc(24rem/16);
        border-radius: calc(20rem/16);
        background: rgba(0,0,0,0.7);
        font-size: 12pt;
        box-shadow: 0 0 0 calc(2rem/16) ${Colours.IckonicPinkHighlight};
    }

    &:hover,
    &:focus {
        padding: calc(15rem/16) calc(16rem/15) calc(35rem/16) calc(16rem/15);
        box-shadow: inset 0 0 calc(100rem/16) calc(0rem/16) rgba(187,54,184,0.9);
    }
`;

const LabelEarlyAccess = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(45deg, rgba(229,174,44, 0.7) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(223,75,220,0.7) 100%);
    padding: calc(25rem/16) calc(16rem/15);
    color: ${Colours.TextBright};
    text-align: center;
    z-index: 3;
    width: 100%;
    border-radius: 10px;
    font-sizE: 16pt;
    font-weight: bold;
    text-transform: uppercase;
    transition: box-shadow .45s ${Ease.Smooth}, padding .45s ${Ease.Smooth};
    span {
        padding: calc(2rem/16) calc(24rem/16);
        border-radius: calc(20rem/16);
        background: rgba(0,0,0,0.8);
        font-size: 12pt;
        box-shadow: 0 0 0 calc(2rem/16) ${Colours.Accent};
    }

    &:hover,
    &:focus {
        padding: calc(15rem/16) calc(16rem/15) calc(35rem/16) calc(16rem/15);
        box-shadow: inset 0 0 calc(100rem/16) calc(0rem/16) rgba(229,174,44, 1);
    }
`;

const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    border-radius: 10px;
    z-index: 2;
    transition: box-shadow .25s ${Ease.Smooth};
    svg {
        position: absolute;
        align-self: flex-end;
        margin: 0 0 3px 3px;
    }

    &.has-lifetime-early-access {
        animation: pulseSoftAccentInner 4s infinite ${Ease.Smooth};
    }

    .carousel-item__pre-release {
        width: 100%;
        height: calc(50rem/16);
        position: absolute;
        top: calc(50% - (25rem/16));
        left: 0;
        border-radius: 0;
        text-align: center;
        font-size: 12pt;
        line-height: 1.2;
        background: rgba(187, 54, 184, 0.7);
        margin: 0;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
            display: block;
        }
    }

    &[data-disabled="true"] {
        background-color: #000000b3;
    }
`;

function CarouselItem(props: {
    Content: ICarouselContentDTO,
    disableOverlay?: boolean,
    onClickCallbackFunc?: () => void
}) {

    const
        authCtx = useContext(AuthContext),
        isNotReleased = new Date(`${props.Content.ReleaseDate}Z`) > new Date(),
        releaseDate = isNotReleased || props.Content.ReleaseDateEarlyAccess === undefined ? format(new Date(`${props.Content.ReleaseDate}Z`), "dd/MM/yyyy hh:mm a") : format(new Date(`${props.Content.ReleaseDateEarlyAccess}Z`), "dd/MM/yyyy hh:mm a"),
        earlyAccessAvailable = props.Content.ReleaseDateEarlyAccess !== undefined && new Date(`${props.Content.ReleaseDateEarlyAccess}Z`) < new Date() && props.Content.Text === "Film",
        redirect = props.Content.IsClickeable || earlyAccessAvailable ? GetContentRedirect(props.Content.ContentType, props.Content.Id) : null,
        userHasLifetimeAccessLevel = authCtx.UserDetails?.AccessLevel === 6,

        [containerWidth, setContainerWidth] = useState(0),
        containerRef = useRef<HTMLDivElement>(null),
        adjustAspectRatio = () => {
            if (containerRef.current !== null) {
                const width = containerRef.current.offsetWidth;
                setContainerWidth(width);
            }
        },
        OnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if ((earlyAccessAvailable && !userHasLifetimeAccessLevel) && isNotReleased) {
                event.preventDefault();
            }

            if (props.onClickCallbackFunc) {
                props.onClickCallbackFunc();
            }
        },

        // Only show 'Watch Free label for unsubscribed users'
        watchFreeLogic =
            props.Content &&
            props.Content.IsFreeView &&
            (authCtx.UserDetails.AccessLevel === undefined || authCtx.UserDetails.AccessLevel < 4);

    useEffect(() => {
        adjustAspectRatio();
        window.addEventListener('resize', adjustAspectRatio);

        // Clean up on unmount
        return () => {
            window.removeEventListener('resize', adjustAspectRatio);
        }
    }, []);

    return (
        <Container
            to={redirect != null ? redirect : "/"}
            onClick={OnClick}
            className={`${watchFreeLogic ? 'is-free' : 'is-not-free'}`}
            style={{
                minHeight: `${containerWidth * 9 / 16}px`,
            }}
        >
            <ImageContainer
                ref={containerRef}
                className={`${watchFreeLogic ? 'is-free' : 'is-not-free'} ${isNotReleased && earlyAccessAvailable && userHasLifetimeAccessLevel ? 'has-lifetime-early-access' : 'has-normal-access'}`}
                style={{
                    minHeight: `${containerWidth * 9 / 16}px`,
                    background: Colours.SecondaryDarker,
                    marginBottom: '8px'
                }}
            >
                {watchFreeLogic && (
                    <LabelFree>
                        <div className="u-full_cover_absolute"> </div>
                        <span>Watch Free</span>
                    </LabelFree>
                )}

                {props.Content.LatestEpisode === 1 && (
                    <LabelLatest>
                        <span>Latest Episode</span>
                    </LabelLatest>
                )}

                {isNotReleased && earlyAccessAvailable && userHasLifetimeAccessLevel && (
                    <LabelEarlyAccess>
                        <div className="u-full_cover_absolute"> </div>
                        <span>Early Access</span>
                    </LabelEarlyAccess>
                )}

                <ImageLazyLoad source={props.Content.Thumbnail} />
                <OverlayContainer data-disabled={ (isNotReleased && earlyAccessAvailable && !userHasLifetimeAccessLevel) || (isNotReleased && !earlyAccessAvailable) } className={`carousel-item__overlay ${ isNotReleased && earlyAccessAvailable && userHasLifetimeAccessLevel ? 'has-lifetime-early-access' : 'has-normal-access' }`}>
                    { (isNotReleased && earlyAccessAvailable && !userHasLifetimeAccessLevel) || (isNotReleased && !earlyAccessAvailable) ?
                        <p className="carousel-item__pre-release">
                            <span>Releases at:</span> {releaseDate}
                        </p>
                        :
                        null
                    }

                    {props.Content.IsOriginal ? <Original /> : null}
                </OverlayContainer>
            </ImageContainer>

            <PrimaryText>{props.Content.Title}</PrimaryText>

            <PrimaryText>{props.Content.Text}</PrimaryText>

            <Rating
                iconSize={14}
                maxStars={5}
                value={props.Content.Rating}
            />
        </Container>
    );
}

export default CarouselItem;
