export enum PopUpType {
    Browse,
    WeeklyShows,
    Films,
    Series,
    DavidIcke,
    Watch,
    Read,
    ReadArticle,
    SeriesDetails
}