import React, { Suspense, useRef } from "react";
import { useContext, useState } from "react";
import { Await, defer, Params, redirect, useLoaderData, useParams } from "react-router-dom";
import { GetLatestVideo } from "../../Api/Video";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import CarouselItem from "../../Components/Carousels/CarouselItem";
import NewsHeader from "../../Components/NewsHeader";
import Heading from "../../Components/UI/Text/Heading";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";
import AuthContext, { UserSnapshot } from "../../Store/auth-context";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import { RoutePaths } from "../../Constants/RoutePaths";
import InfiniteScrollLoader from "../../Components/UI/PageLoaders/InfiniteScrollLoader";
import { AuthValidSubOnly } from "../../Helpers/RouteAuth";
import { GetEpisodesByYear } from "../../Api/Carousel";
import { AxiosError } from "axios";

const Container = styled.section`
    flex: 1;
`;

const VideoContainer = styled.div`
    .infinite-scroll-component {
        display: flex;
        position: relative;
        align-items: flex-start;
        gap: 5px;
        padding: 15px 10px;
        flex-flow: row wrap;
    }

    //Each video item
    .infinite-scroll-component a {
        flex: 0 1 calc(20% - 4px);
    }

    h1 {
        flex-basis: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 1600px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(25.25% - 8px);
        }
    }

    @media only screen and (max-width: 1000px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(33.8% - 8px);
        }
    }

    @media only screen and (max-width: 800px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(50.6% - 8px);
        }
    }
    @media only screen and (max-width: 600px) {
        .infinite-scroll-component a {
            flex: 0 1 100%;
        }
    }
`;

function NewsDetailsScreen() {
    const { latestPromise } = useLoaderData() as {
        latestPromise: Promise<IVideoDTO>;
    };

    const params = useParams();
    let seriesId = Number(params.id);
    const controller = new AbortController();
    const authCtx = useContext(AuthContext);
    const [episodes, setEpisodes] = useState<{ Carousel: ICarouselDTO; DisplayTitle: boolean }[]>(
        []
    );
    const [hasMore, setHasMore] = useState(true);
    const [currentYears, setCurrentYears] = useState<string[]>([]);
    const iteration = useRef<number>(0);
    const skip = useRef<number>(0);

    async function EpisodesByYear() {
        let take = 15;
        const result = await GetEpisodesByYear(
            seriesId,
            authCtx.UserDetails.CurrentCountryCode,
            authCtx.UserDetails.AspNetUserId,
            take,
            skip.current,
            iteration.current,
            controller,
            'd'
        );

        if (
            result === null ||
            result === undefined ||
            result instanceof AxiosError ||
            result.Content === null ||
            result.Content === undefined ||
            result.Content.length <= 0
        ) {
            setHasMore(false);
            return;
        }

        const year = result.Title.toLowerCase().split("from ")[1].trim();
        let displayTitle = true;

        if (!currentYears.includes(year)) {
            skip.current = result.Content.length;
            setCurrentYears((prev) => [...prev, year]);
        } else {
            skip.current += result.Content.length;
            displayTitle = false;
        }

        if (result.Content.length < take) {
            skip.current = 0;
            iteration.current = iteration.current += 1;
        }

        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: displayTitle }]);
    }

    function RenderCarouselItems(items: ICarouselContentDTO[]) {
        let elements: JSX.Element[] = [];
        items.map((content) => elements.push(<CarouselItem key={content.Id} Content={content} />));

        return elements;
    }

    return (
        <Container>
            <Suspense fallback={<Heading>...Loading latest</Heading>}>
                <Await resolve={latestPromise}>
                    {(latestData) => {
                        const latestVideo = latestData as IVideoDTO;

                        return <NewsHeader latestVideo={latestVideo} />;
                    }}
                </Await>
            </Suspense>
            <VideoContainer>
                <InfiniteScroll
                    dataLength={episodes.length}
                    hasMore={hasMore}
                    next={EpisodesByYear}
                    loader={<InfiniteScrollLoader />}
                >
                    {episodes.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {item.DisplayTitle ? (
                                    <Heading>{item.Carousel.Title}</Heading>
                                ) : null}
                                {RenderCarouselItems(item.Carousel.Content)}
                            </React.Fragment>
                        );
                    })}
                </InfiniteScroll>
            </VideoContainer>
        </Container>
    );
}

export default NewsDetailsScreen;

export async function Loader({
                                 params,
                             }: {
    params: Readonly<Params<string>>;
}) {
    let seriesId = Number(params.id);
    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    async function InitLatestVideo() {
        let video = await GetLatestVideo(seriesId, userDetails.CurrentCountryCode, controller);
        if (video === null || video instanceof AxiosError) {
            //Redirects user if has no videos
            return redirect(RoutePaths.News);
        }

        return video;
    }

    const responce = AuthValidSubOnly(userDetails);

    if (responce !== undefined) {
        return responce;
    }

    return defer({
        latestPromise: InitLatestVideo(),
    });
}
