import { NavLink } from "react-router-dom";
import { RoutePaths } from "../../../Constants/RoutePaths";
import styled from "styled-components";
import IPartnerDTO from "../../../Models/DTOs/IPartnerDTO";
import ImageLazyLoad from "../ImageLazyLoad";

const Container = styled.a`
    width: 100%;
    max-height: 300px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    grid-column: 1/-1;
`;

const StyledLink = styled(NavLink)`
    width: 100%;
    max-height: 300px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    grid-column: 1/-1;
`;

function BannerAd(props: { partner: IPartnerDTO;  }) {
    const base_url = window.location.host;

    if (props.partner.IsIckonic) {
        return (
            <StyledLink to={RoutePaths.CreateYourAccount}>
                <ImageLazyLoad source={props.partner.ImageHorizontal} />
            </StyledLink>
        );
    }

    return (
        <Container
            href={props.partner.DestinationUrl}
            target={
                props.partner.DestinationUrl.includes(base_url)
                    ? "_self"
                    : "_blank"
            }
        >
            <ImageLazyLoad source={props.partner.ImageHorizontal} />
        </Container>
    );
}

export default BannerAd;
