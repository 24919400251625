export enum SubscriptionStatus{
    None,
    Active,
    Trialing,
    Past_Due,
    Incomplete,
    Incomplete_Expired,
    Cancelled,
    Unpaid,
    Paused
}