import axios, { AxiosError } from "axios";
import IAffiliateDTO from "../Models/DTOs/IAffiliateDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.affiliate;

export async function GetAffiliate(link: string, abortController: AbortController) {
    const responce = await axios
        .get(BACKEND_URL + link, {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as IAffiliateDTO;
        })
        .catch((error: AxiosError) => {
            console.log("GetAffiliate Error");
            return error;
        });

    return responce;
}