import styled from "styled-components";
import Card from "../../../Components/UI/Card";
import Heading from "../../../Components/UI/Text/Heading";
import { Colours } from "../../../Constants/Colours";
import { useState } from "react";
import SelectPlan from "../../../Components/UI/Subscription/SelectPlan";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { PlanPeriod } from "../../../Models/Enums/PlanPeriod";
import { useNavigate } from "react-router-dom";
import { SetTitle } from "../../../Helpers/Utility";
import SignUpHeader from "../../../Components/UI/Subscription/SignUpHeader";
import { SignUpHeaderActive } from "../../../Models/Enums/SignUpHeaderActive";
import { ChoosePlanAuth } from "../../../Helpers/RouteAuth";
import { UserSnapshot } from "../../../Store/auth-context";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { Ease } from "../../../Constants/EasingCurves";
import { NavLink } from "react-router-dom";
import { IckonicLogo } from "../../../Assets/Images/Logos/Logos";
import { HeadingType } from "../../../Models/Enums/HeadingType";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };;
    
    > div {
        margin: 75px 0 35px 0;
        max-width: 800px;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        @media screen and (min-height: calc(1140em/16)) {
            padding: 0 0 5vh 0;
        }
    }
`;

const TitleContainer = styled.div`
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding-bottom: 20px;

    h2 {
        color: ${ Colours.TextBright }; 
        font-size: 2rem;
        margin: 2rem 0 0 0;
    }
    
    p {
        color: ${ Colours.LighterGrey };
        margin: 5px 0 1rem 0;
        font-size: 10pt;
        a {
            color: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

const InfoTextContainer = styled.div`
    text-align: center;
    margin: 1rem 0 0 0;
`;

const LogoContainer = styled(NavLink)`
    position: absolute;
    top: 24px;
    left: calc(50% - (55rem/16));
    width: 110px;
    height: 30px;
    path {
        fill: ${ Colours.Text };
        transition: fill .3s ${ Ease.Smooth };
    }
    
    &:hover,
    &:focus {
        path {
            fill: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

function ChoosePlanScreen() {
    const navigate = useNavigate();
    const [selectedPeriod, setSelectedPeriod] = useState<PlanPeriod>(
        PlanPeriod.Yearly
    );

    function SetSelectedPlan(plan: IPlanDTO) {
        navigate(RoutePaths.ConfirmSubscription, {state: {plan: plan}});
    }

    function SetSelectedPeriod(period: PlanPeriod) {
        setSelectedPeriod(period);
    }

    return (
        <Container>
            <LogoContainer to="/">
                { IckonicLogo }
            </LogoContainer>

            <Card>
                <SignUpHeader active={SignUpHeaderActive.ChoosePlan} />

                <TitleContainer>
                    <Heading type={ HeadingType.H2 }>Uncensored, Unbiased and Unique Information – All in one Place</Heading>
                </TitleContainer>


                <SelectPlan
                    setSelectedPlan={SetSelectedPlan}
                    setSelectedPeriod={SetSelectedPeriod}
                    selectedPeriod={selectedPeriod}
                />

                <InfoTextContainer>
                    <PrimaryText>Cancel easily, anytime.</PrimaryText>
                </InfoTextContainer>
            </Card>
        </Container>
    );
}

export default ChoosePlanScreen;


export async function Loader() {
    const userDetails = await UserSnapshot;
    const responce = ChoosePlanAuth(userDetails);

    if(responce !== null && responce !== undefined){
        return responce;
    }

    SetTitle("Choose Plan");

    return null;
}