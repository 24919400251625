import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { BannerType } from "../../Models/Enums/BannerType";
import GetBannerApiData from "../../Api/BannerApi";
import IBannerDTO from "../../Models/DTOs/IBannerDTO";
import { Ease } from "../../Constants/EasingCurves";

const Container = styled.div`
    position: relative;
    height: 23.75vw;

    max-height: calc(387rem/16);
    max-width: calc(1616rem/16);
    margin: 0 auto;
    width: 100%;
    background-color: black;
    a {
        z-index: 1; 
        opacity: 0;
        transition: opacity .45s ${Ease.Smooth};
    }
    
    &.has-loaded {
        a {
            opacity: 1;
        }
    }
`;

const Anchor = styled(NavLink)``;

const DummyLoader = styled.div``;

const Banner = (props: {
    children?: React.ReactNode,
    apiEnum?: BannerType
}) => {
    const
        [bannerApiData, setBannerApiData] = useState<IBannerDTO | null>(null),
        [apiSuccess, setApiSuccess] = useState(false),
        controller = new AbortController();

    useEffect(() => {
        if (props.apiEnum !== undefined) {
            if (bannerApiData === null || bannerApiData.BannerType !== props.apiEnum) {
                GetBannerApiData(controller, props.apiEnum)
                    .then(response => {
                        if (response && Object.keys(response).length > 0) {
                            setBannerApiData(response as IBannerDTO);
                            setTimeout(() => { setApiSuccess(true); }, 500);
                        }
                    });
            }
        }

        return () => {
            setBannerApiData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apiEnum]);

    return (
        <Container className={apiSuccess ? 'has-loaded' : 'is-loading'}>
            {bannerApiData !== null && (
                <Anchor
                    to={bannerApiData.DestinationUrl}
                    className="u-full_cover_absolute"
                    style={{ background: `url('${bannerApiData.Image}') center/cover no-repeat` }}
                />
            )}

            <DummyLoader className={apiSuccess ? 'loader--disabled' : 'loader--side-to-side'} style={{ background: 'black' }} />
        </Container>
    );
};

export default Banner;