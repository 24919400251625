import React, {useEffect, useState} from "react";

// Models
import {IUserDetails} from "../../Models/IUserDetails";
import {IYourAccountDTO} from "../../Models/DTOs/IYourAccountDTO";

// Api
import {GetYourAccountDTO} from "../../Api/Account";
import {ISubscriptionDTO} from "../../Models/DTOs/ISubscriptionDTO";
import styled from "styled-components";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";

const SinglePlanInfoLayout = styled.section`
    padding: calc(3rem/16);
    border-radius: 0 calc(10rem/16) calc(10rem/16) calc(10rem/16);
    background: #191919FF;
    margin: 4rem 0 2rem 0;
    position: relative;
    .single-plan-info__inner {
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .single-plan-info__label {
            border-radius: calc(10rem/16) calc(10rem/16) 0 0;
            background: #191919FF;
            color: white;
            position: absolute;
            top: calc(-32rem/16);
            padding: calc(10rem/16) calc(16rem/16);
            font-family: "Rubik", Helvetica sans-serif;
            text-align: center;
            font-weight: normal;
            left: 0;
            margin: 0;
            font-size: calc(14rem/16);
            height: auto;
            span {
                display: inline-block;
                min-width: calc(100rem/16);
                height: calc(12rem/16);
                margin: 0 0 0 calc(5rem/16);
            }
        }

        p {
            margin: 0 0 calc(8rem/16) 0;
            width: 100%;
            font-size: calc(14rem/16);
            display: flex;
            min-height: calc(28rem/16);
            justify-content: space-between;

            span {
                display: inline-block;
                padding: calc(7rem/16) calc(10rem/16);
                line-height: 1;
                height: calc(28rem/16);
                border-radius: calc(5em/16);
                font-size: calc(14rem/16);
                font-weight: bold;
                background: none;
                color: white;
                background: #303030FF;
                &.single-plan-info__span {
                    margin: 0 0 0 calc(7rem/16);
                }
            }


            &:nth-child(even) {
                span {

                }
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: ${ResponsiveBreakpoints.SmallTabletBreakpoint}) {
        .single-plan-info__inner {
            p {

            }
        }
    }
`;

const SinglePlanInfo = (props: {
    userData: IUserDetails | undefined
}) => {
    const
        abortController = new AbortController(),
        [account, setAccount] = useState<IYourAccountDTO | Partial<IYourAccountDTO>>({}),

        handleGetUserAccount = async () => {
            if (props.userData) {
                const
                    result = await GetYourAccountDTO(
                        props.userData.AspNetUserId,
                        props.userData.Access_Token,
                        abortController
                    ),
                    getUserAccountResult = result as IYourAccountDTO;

                if (getUserAccountResult.Last4) {
                    console.log(getUserAccountResult);
                    setAccount(getUserAccountResult);
                }
            }
        },

        getPlanNameByAccessLevel = (accessLevel: number) => {
            switch (accessLevel) {
                case 5:
                    return 'Game Changer';
                case 6:
                    return 'Lifetime';
                default:
                    return 'Truth Seeker';
            }
        };

    useEffect(() => {
        if (props.userData?.AspNetUserId && !account.Last4) {
            handleGetUserAccount();
        }
    }, [props.userData]);

    return (
        <SinglePlanInfoLayout
            className={`layout layout--single-plan-info`}>

            <div className={`single-plan-info__inner ${account.Subscription ? 'is-not-loading' : 'is-loading'}`}>
                <h4 className="single-plan-info__label">
                    Your Plan: {account.Subscription ?
                    getPlanNameByAccessLevel(account.Subscription.AccessLevel)
                    :
                    (
                        <span className="u-relative_hidden single-plan-info__loader">
                            <span className="u-full_cover_absolute loader--side-to-side"/>
                        </span>
                    )
                }
                </h4>

                {account.Subscription ?
                    (
                        <>
                            <p>
                                Cost: <span className="single-plan-info__span">£{account.Subscription.BillingAmount}</span>
                            </p>

                            <p>
                                First Billing
                                Date: <span className="single-plan-info__span">{new Date(account.Subscription.SubscriptionStartDate).toLocaleDateString('en-GB')}</span>
                            </p>
                        </>
                    )
                    :
                    (
                        <>
                            <p className="u-relative_hidden single-plan-info__loader">
                                <span className="u-full_cover_absolute loader--side-to-side"/>
                            </p>

                            <p className="u-relative_hidden single-plan-info__loader">
                                <span className="u-full_cover_absolute loader--side-to-side"/>
                            </p>
                        </>
                    )
                }
            </div>
        </SinglePlanInfoLayout>
    );
};

export default SinglePlanInfo;

const dummyPlan: IYourAccountDTO = {
    Last4: "1003",
    LatestInvoiceStatus: 3,
    Subscription: {
        AccessLevel: 4,
        AspNetUserId: "387c51a3-5aa1-4f2e-9286-487d72895a1e",
        BillingAmount: 1.99,
        BillingFrequency: 0,
        Currency: null,
        Id: "49287",
        PaymentMeth: "pm_1NBKkuKO1xu37edMoYhGRYUO",
        StripeId: "cus_NxFEP9WyG0RdXJ",
        SubNo: "sub_1NBKkxKO1xu37edM634G78NO",
        SubscriptionEndDate: "2024-07-31T16:41:47",
        SubscriptionStartDate: "2023-05-24T16:41:47",
        TransactionId: "0"
    } as ISubscriptionDTO
};
