import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import {Fonts} from "../../../Constants/Fonts";

const Text = styled.p`
    font-family: ${ Fonts.Primary };
    color: ${Colours.Error};
    font-size: 10pt;
    margin: 10px 0 0 0;
    line-height: 1.25;
`;

function ErrorText(props: {children: string | string[] | number, testId?: string}){
    return(
        <Text data-testid={props.testId}>{props.children}</Text>
    )
}

export default ErrorText;