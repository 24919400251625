import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import Heading from "../Text/Heading";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import PrimaryText from "../Text/PrimaryText";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { NavLink } from "react-router-dom";
import { FaRegPlayCircle } from "react-icons/fa";
import { GetPlans } from "../../../Api/Subscription";
import AuthContext from "../../../Store/auth-context";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { SymbolFromCurrency } from "../../../Helpers/Utility";
import { PlanType } from "../../../Models/Enums/PlanType";
import ImageLazyLoad from "../ImageLazyLoad";
import { AxiosError } from "axios";
import { Fonts } from "../../../Constants/Fonts";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import {Ease} from "../../../Constants/EasingCurves";

const Container = styled.div`
    padding: 2rem 1rem;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        padding: 3rem;
    }
    
    * {
        overflow-wrap: anywhere;
    }
`;

const ContainerInner = styled.div`
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const ContentContainer = styled.div`
    max-width: 776px;
    margin: 0 auto;
    order: 1;

    li::marker {
        color: ${Colours.IckonicPink};
    }

    li,
    p {
        margin: 0 0 15px;
        font-family: ${ Fonts.Primary };
        font-size: 12pt;
        line-height: 1.5;
        color: ${Colours.Text};
    }

    h3 {
        color: ${Colours.IckonicPink};
    }

    h2:first-child {
        font-size: 30pt;
        color: ${Colours.TextBright};
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        margin: 0 auto 2rem auto;
        order: 0;
        margin: 0;
        width: calc(50% - 1.5rem);
    }
`;

const PlansContainer = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-self: center;
    align-items: center;

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const PlanObject = styled(NavLink)`
    border: 2px solid ${Colours.IckonicPink};
    display: flex;
    flex-direction: column;
    text-decoration: none;
    border-radius: 10px;
    transition: background .25s ${ Ease.Smooth };
    svg {
        * {
            transition: fill .25s ${ Ease.Smooth };
        }
    }
    
    :hover {
        background: ${Colours.IckonicPink};
        
        svg {
            * {
                fill: ${Colours.TextBright};
            }
        }
    }
`;

const PlanContent = styled.div`
    width: 100%;
    color: ${Colours.IckonicPink};
    padding: 15px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-size: 16pt;

    p {
        text-align: center;
        margin: 0;
        color: ${Colours.TextBright};
    }
`;

const PlanHeader = styled.div`
    grid-column: 1/-1;
    text-align: center;
    padding: 2% 2%;

    h4 {
        margin: 15px 0 2px 0;
        font-size: 16pt;
    }
`;

const ImageContainer = styled.div`
    height: calc(56vw - 22px);
    max-width: 700px;
    max-height: 394px;
    margin: 0 auto;

    > div {
        background-color: transparent;
    }
    
    div {
        height: 100%;
        width: 100%;
    }
    
    img {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        height: calc(28vw - 20px);
        max-height: 385px;
        margin: 0;
        width: calc(50% - 1.5rem);
        img {
            width: 100%;
            height: auto;
            max-height: none;
        }
    }
`;

function PanelPricing(props: { panel: IPanelDTO, freeDaysTrial: number }) {
    const isLoaded = useRef<boolean>(false);
    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [plans, setPlans] = useState<IPlanDTO[]>([]);

    const titles = props.panel.Properties.filter(
        (x) => x.PropertyType === PanelPropertyType.Title
    );

    const text = props.panel.Properties.filter(
        (x) => x.PropertyType === PanelPropertyType.Text
    );

    const bulletPoints = props.panel.Properties.filter(
        (x) => x.PropertyType === PanelPropertyType.BulletPoints
    );

    const background = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Image
    );

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        async function LoadData() {
            await Promise.all([InitPlans()]);
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function InitPlans() {
        const result = await GetPlans(
            authCtx.UserDetails.CurrentCountryCode,
            controller
        );

        if (result === null || result instanceof AxiosError) {
            return;
        }

        setPlans(result);
    }

    return (
        <Container>
            <ContainerInner>
                <ContentContainer>
                    {titles !== null &&
                    titles !== undefined &&
                    titles.length > 0 ? (
                        <Heading key={titles[0].Id} type={ HeadingType.H2 }>
                            {titles[0].Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </Heading>
                    ) : null}

                    {bulletPoints !== null &&
                    bulletPoints !== undefined &&
                    bulletPoints.length > 0 ? (
                        <ul key={bulletPoints[0].Id}>
                            {bulletPoints[0].Value.split(";").map((point) => {
                                return (
                                    <li key={point}>
                                        {point.replace(
                                            "{freetrialdays}",
                                            props.freeDaysTrial.toString()
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}

                    {titles !== null &&
                    titles !== undefined &&
                    titles.length >= 1 ? (
                        <Heading key={titles[1].Id} type={ HeadingType.H3 }>
                            {titles[1].Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </Heading>
                    ) : null}

                    {text.map((property) => {
                        return (
                            <React.Fragment key={"T" + property.Id}>
                                {parse(
                                    DOMPurify.sanitize(
                                        property.Value.replace(
                                            "{freetrialdays}",
                                            props.freeDaysTrial.toString()
                                        )
                                    )
                                )}
                            </React.Fragment>
                        );
                    })}

                    {titles !== null &&
                    titles !== undefined &&
                    titles.length >= 2 ? (
                        <Heading key={titles[2].Id} type={ HeadingType.H3 }>
                            {titles[2].Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </Heading>
                    ) : null}

                    <PlansContainer>
                        {plans.map((plan) => {
                            return (
                                <PlanObject key={"p" + plan.PlanId} to={"/" + PlanType[plan.Type]}>
                                    <PlanHeader>
                                        <Heading type={HeadingType.H4}>
                                            {plan.Title}
                                        </Heading>
                                    </PlanHeader>
                                    <PlanContent>
                                        <FaRegPlayCircle />
                                        <PrimaryText>
                                            {SymbolFromCurrency(plan.Currency) +
                                            plan.Price}
                                        </PrimaryText>
                                    </PlanContent>
                                </PlanObject>
                            );
                        })}
                    </PlansContainer>

                    {titles !== null &&
                    titles !== undefined &&
                    titles.length >= 3 ? (
                        <Heading key={titles[3].Id} type={ HeadingType.H3 }>
                            {titles[3].Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </Heading>
                    ) : null}

                    {bulletPoints !== null &&
                    bulletPoints !== undefined &&
                    bulletPoints.length > 1 ? (
                        <ul key={bulletPoints[1].Id}>
                            {bulletPoints[1].Value.split(";").map((point) => {
                                return (
                                    <li key={point}>
                                        {point.replace(
                                            "{freetrialdays}",
                                            props.freeDaysTrial.toString()
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </ContentContainer>
                {background ? (
                    <ImageContainer>
                        <ImageLazyLoad source={background.Value} />
                    </ImageContainer>
                ) : null}

            </ContainerInner>
        </Container>
    );
}

export default PanelPricing;
