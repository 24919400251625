import React, {useContext, useEffect, useRef, useState} from "react";
import {GetArticleHeader, GetCategories} from "../../Api/Article";
import styled from "styled-components";
import IArticleBrowseDTO from "../../Models/DTOs/IArticleBrowseDTO";
import IArticleCategoryListDTO from "../../Models/DTOs/IArticleCategoryListDTO";
import Heading from "../../Components/UI/Text/Heading";
import ArticleGridItemSingle from "../../Components/UI/Article/ArticleGridItemSingle";
import {HeadingType} from "../../Models/Enums/HeadingType";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";
import FeaturedArticles from "../../Components/UI/Article/FeaturedArticles";
import {IsAuthenticated} from "../../Helpers/UserUtility";
import BannerAd from "../../Components/UI/Ads/BannerAd";
import AuthContext from "../../Store/auth-context";
import NavLinkSmallInlineGreyButton from "../../Components/UI/Buttons/NavLinkSmallInlineGreyButton";
import {RoutePaths} from "../../Constants/RoutePaths";
import {debounce, SetTitle} from "../../Helpers/Utility";
import VideoSingleLoader from "../../Components/UI/PageLoaders/VideoSingleLoader";
import PopUp from "../../Components/UI/Modals/PopUp";
import {PopUpType} from "../../Models/Enums/PopUpType";
import IPartnerDTO from "../../Models/DTOs/IPartnerDTO";
import {GetRandomPartner} from "../../Api/Partner";

const
    Container = styled.section`
        width: 100%;
        max-width: calc(1600rem/16);
        margin: 0 auto;
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    `,
    ArticlesGrid = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem;
        > .heading {
            display: flex;
            align-items: center;
            justify-
            margin: 0 0 2rem 0;
            width: 100%;
            a {
                width: calc(86rem/16);
                //margin: 0 0 calc(3rem/16) calc(12rem/16);
            }
        }
        
        // For the dummy loader items
        > div {
            @media screen and (min-width: calc(${ResponsiveBreakpoints.MediumMobileBreakpoint}rem/16)) {
                width: calc(50% - (8rem/16));
            }
            
            @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
                width: calc(50% - (16rem/16));
                margin: 0 0 3rem 0;
            }
            
            @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
                width: calc(25% - (24rem/16));
            }
        }
        
        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            padding: 2rem 2rem 0 2rem;
        }
    `,
    AdContainer = styled.div`
        margin: 0 0 1.5rem 0;
        &:last-child {  
            margin: 0;
        }
    `;

function ArticlesScreen() {
    const
        authCtx = useContext(AuthContext),
        endOfListRef = useRef<boolean>(false),
        [ canFetch, setCanFetch ] = useState<boolean>(false),
        [ isLoading, setIsLoading ] = useState<boolean>(true),
        controller = new AbortController(),

        // Data for the featured or "hero" section
        // at the top of the screen
        [ featured, setFeatured ] = useState<IArticleBrowseDTO|null>(null),
        fetchFeaturedArticles = () => {
            GetArticleHeader(controller)
                .then(response => {
                    setFeatured(response as IArticleBrowseDTO);
                    setCanFetch(true);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        [ categories, setCategories ] = useState<IArticleCategoryListDTO[]>([]),
        fetchCategories = (skip: number) => {
            GetCategories(skip, 2, controller)
                .then(response => {
                    const newCategories = response as IArticleCategoryListDTO[];
                    if (newCategories.length > 0) {
                        setCategories([...categories, ...newCategories]);
                    }

                    else {
                        endOfListRef.current = true;
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        [ partner, setPartner ] = useState<IPartnerDTO|null>(null),
        fetchPartner = () => {
            GetRandomPartner(controller)
                .then(response => {
                    setPartner(response as IPartnerDTO);
                })
                .catch(error => {
                    console.log(error);
                });
        };

    // On mount
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight
                && !endOfListRef.current
            ) {
                setCanFetch(true);
            }
        };

        SetTitle("News");

        // scroll listener for infinite load
        const debouncedHandleScroll = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedHandleScroll);

        // Unmount
        return () => {
            setFeatured(null);
            setCategories([]);
            setCanFetch(false);
            setIsLoading(true);
            endOfListRef.current = false;
            window.removeEventListener('scroll', debouncedHandleScroll);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (featured === null) {
            fetchFeaturedArticles();
        }

        if (partner === null) {
            fetchPartner();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ featured, partner ]);

    useEffect(() => {
        if (canFetch) {
            setCanFetch(false);
            fetchCategories(categories.length);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ canFetch, categories ]);

    return (
        <Container>
            {!IsAuthenticated(authCtx.UserDetails) && (
                <PopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Read}
                    isFullScreen={true}
                />
            )}

            { featured && featured.Latest && featured.Latest.length > 0 && (
                <FeaturedArticles
                    Articles={ featured }
                />
            ) }

            { categories.map((category, i) => {
                return (
                    <div key={ i }>
                        <ArticlesGrid>
                            { category && category.Title && (
                                <Heading
                                    type={ HeadingType.H3 }
                                    className="heading"
                                >
                                    { category.Title }

                                    <NavLinkSmallInlineGreyButton to={ RoutePaths.ArticleCategory(category.CategoryId) }>
                                        View All
                                    </NavLinkSmallInlineGreyButton>
                                </Heading>
                            ) }

                            { category && category.Articles && category.Articles.length > 0 ?
                                category.Articles.map((article, i) => (
                                    <ArticleGridItemSingle
                                        key={ i }
                                        article={ article }
                                    />
                                ))
                                :
                                null
                            }
                        </ArticlesGrid>

                        {
                            category.Partner !== undefined &&
                            category.Partner !== null &&
                            !IsAuthenticated(authCtx.UserDetails) ? (
                                <AdContainer>
                                    <BannerAd partner={category.Partner} />
                                </AdContainer>
                            ) : null
                        }
                    </div>
                );
            }) }

            { isLoading ?
                <ArticlesGrid>
                    <VideoSingleLoader paddingTop="0"/>
                    <VideoSingleLoader paddingTop="0"/>
                    <VideoSingleLoader paddingTop="0"/>
                    <VideoSingleLoader paddingTop="0"/>
                </ArticlesGrid> : null
            }

            { partner !== null && !IsAuthenticated(authCtx.UserDetails) && (
                <AdContainer>
                    <BannerAd partner={partner} />
                </AdContainer>
            ) }
        </Container>
    );
}

export default ArticlesScreen;