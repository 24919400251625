import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { HeaderProperties } from "../../../Constants/HeaderProperties";

const HeaderContainer = styled.div`
    cursor: pointer;
    float:left;
    display: flex;
    user-select: none;
    height: ${ HeaderProperties.height };
`;

const sharedDropDownStyles = `
    position: absolute;
    right: 0px;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid rgba(0,0,0, 0.12);
    overflow: visible;
    background: #1c1c1c;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 5%);
    z-index: 50;
    
    opacity: 0;
    top: -2000px;
    transform: translateY(${ HeaderProperties.height });
    transition: opacity .2s cubic-bezier(.56,.15,.16,1), top 0s linear .2s, transform .2s cubic-bezier(.56,.15,.16,1);
    
    &.active{
        opacity: 1;
        top: ${ HeaderProperties.height };
        //visibility: visible;
        transform: translateY(0);
        transition: opacity .2s cubic-bezier(.56,.15,.16,1), top 0s linear, transform .2s cubic-bezier(.56,.15,.16,1);

    }

    &:before {
        top: -10px;
        border-color: transparent transparent #1c1c1c transparent;
        border-width: 10px;
        box-sizing: border-box;
        content: '';
        display: block;
        position: absolute;
        right: 15px;
        margin-top: -10px;
        width: 0;
        height: 0;
        z-index: 30;
        border-style: solid;
    }
`;

const ChildContainer = styled.div`
    min-width: 250px;
    ${ sharedDropDownStyles }
`;

const ChildContainerTooltip = styled.div`
    min-width: 300px;
    text-align: left;
    padding: 20px;
    ${ sharedDropDownStyles }
`;

function DropDownDialog(props: {
    Header?: JSX.Element | JSX.Element[];
    children?: JSX.Element | JSX.Element[];
    isActive: boolean,
    isTooltip?: boolean,
    setIsActive: (value: boolean) => void,
}) {

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const { setIsActive } = props;

    function getHeader () {
        if(props.Header === null || props.Header === undefined) {
            return (<></>);
        }
         return props.Header;
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
          if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsActive(false);
          }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setIsActive]);

    function OnClick() {
        props.setIsActive(!props.isActive);
        setIsLoaded(true);
    }

    function getLoadedClass(): string {
        if (props.isActive) {
            return "active";
        }
        else if (isLoaded)
        {
            return "loaded";
        }
        return "";
    }

    function RenderChildrenContainer() {
        if(props.isTooltip) {
            return <ChildContainerTooltip className={getLoadedClass()}>{props.children}</ChildContainerTooltip>
        }
        return <ChildContainer className={getLoadedClass()}>{props.children}</ChildContainer>
    }

    return (
        <div ref={ref}>
            <HeaderContainer onClick={OnClick}>{getHeader()}</HeaderContainer>
            {RenderChildrenContainer()}
        </div>
    );
}


export default DropDownDialog;
