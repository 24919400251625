import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./Store/auth-context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { LocalContextProvider } from "./Store/local-context";
import config from "./Constants/Config"

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const stripeKey = config.stripeKey;

const stripePromise = loadStripe(stripeKey!);

root.render(
    <React.StrictMode>
        <Elements stripe={stripePromise}>
            <AuthContextProvider>
                <LocalContextProvider>
                    <App />
                </LocalContextProvider>
            </AuthContextProvider>
        </Elements>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
