import { SwiperSlide } from "swiper/react";
import { ContentType } from "../../Models/Enums/ContentType";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";
import Carousel from "./Carousel";
import FeaturedCarouselItem from "./FeaturedCarouselItem";
import styled from "styled-components";
import { useEffect, useState, useRef } from 'react';
import { Ease } from "../../Constants/EasingCurves";
import { Colours } from "../../Constants/Colours";

const Container = styled.div`
    width: 100%;
    .swiper-button-prev,
    .swiper-button-next {
        &:after {
            font-size: 18pt;
            color: white;
            text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) rgba(0, 0, 0, 0.3);
            transition: color .25s ${ Ease.Smooth };
        }
        
        &:hover,
        &:focus {
            &:after {
                color: ${ Colours.IckonicPinkHighlight };
            }
        }
    }
    
    .swiper-button-prev {
        left: calc(1rem/16);
    }
    
    .swiper-button-next {
        right: calc(1rem/16);
    }
    
    .swiper-wrapper {
        //transition: transform .75s ${ Ease.Smooth } !important;
    }
`;

function FeaturedCarousel(props: {
    content: ICarouselContentDTO[];
    allowedContent: ContentType[];
}) {
    const
        filteredContent = props.content.filter((x) =>
            props.allowedContent.includes(x.ContentType)
        ),
        wrapperRef = useRef<HTMLDivElement|null>(null),
        [ wrapperHeight, setWrapperHeight ] = useState(0),
        updateHeight = () => {
            if (wrapperRef !== null) {
                const
                    slides = document.querySelectorAll('.featured-slide__content');
                let maxSlideHeight = 0;

                slides.forEach((slide) => {
                    const slideHeight = slide.clientHeight;
                    if (slideHeight > maxSlideHeight) {
                        maxSlideHeight = slideHeight;
                    }
                });

                setWrapperHeight(maxSlideHeight);
            }
        };

    useEffect(() => {

        // Initial height calculation
        updateHeight();

        // Listen for window resize events and update height
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <Container>
            <Carousel
                sliderPerView={ 1 }
                arrows={ true }
                bullets={ true }
                autoPlay={ true }
                loop={ true }
            >
                { filteredContent
                    .filter(
                        (x) =>
                            !x.BackgroundImage !== null
                            //!x.BackgroundImage.endsWith("/")
                    )
                    .map((content) => {
                        return (
                            <SwiperSlide key={ "f" + content.Id } className="swiper-slide">
                                <FeaturedCarouselItem
                                    Content={ content }
                                    height={ wrapperHeight } // Pass the height as a prop
                                />
                            </SwiperSlide>
                        );
                    }) }
            </Carousel>
        </Container>
    );
}

export default FeaturedCarousel;
