import styled from "styled-components";
import {NavLink} from "react-router-dom";
import React from "react";

type MyComponentProps = {
    link: string,
    text: string,
    background?: string,
    color?: string,
    openInNewWindow?: boolean
}

const Button = styled(NavLink)`
    transition: background .25s $sexyEase, transform .35s $sexyEase;
    &:hover,
    &:active,
    &:focus {
        transform: translateY(calc(-4rem/16));
    }
`;

const ButtonGradientNavLink: React.FC<MyComponentProps> = ({
    link = '',
    text = 'Click Here',
    background = 'linear-gradient(90deg, rgb(232,187,39) 0%, rgb(229,174,44) 100%)',
    color = 'black',
    openInNewWindow = false
}) => {
    return (
        <Button
            to={ link }
            className="button-ickonic button-ickonic--gradient"
            target={ openInNewWindow ? "_blank" : "_self" }
            style={{
                color: color,
                fontWeight: 'bold',
                borderRadius: 'calc(24rem/16)',
                padding: 'calc(10rem/16) calc(40rem/16)',
                textAlign: 'center',
                margin: '0 auto',
                background: background,
                textDecoration: 'none',
                display: 'table'
            }}
        >
            { text }
        </Button>
    );
};

export default ButtonGradientNavLink;
