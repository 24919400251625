import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import ImageLazyLoad from "../ImageLazyLoad";

const Container = styled.div`
    width: 100%;
    height: auto;
`;


function PanelBanner(props: { panel: IPanelDTO }){
    const background = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Image
    );

    if(background === null || background === undefined){
        return null;
    } 
    
    return(
        <Container>
            <ImageLazyLoad source={background.Value}/> 
        </Container>
    )    
}

export default PanelBanner;