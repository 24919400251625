import axios, { AxiosError } from "axios";
import IArticleBrowseDTO from "../Models/DTOs/IArticleBrowseDTO";
import IArticleCategoryLatestDTO from "../Models/DTOs/IArticleCategoryLatestDTO";
import IArticleCategoryListDTO from "../Models/DTOs/IArticleCategoryListDTO";
import IArticleReadDTO from "../Models/DTOs/IArticleReadDTO";
import ICategoryArticlesDTO from "../Models/DTOs/ICategoryArticlesDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.article;

export async function GetArticleHeader(abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "web", {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IArticleBrowseDTO;
        })
        .catch((error: AxiosError) => {
            console.log("GetArticleHeader error" );
            return error;
        });

    return response;
}

export async function Read(id: number, abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "Read/" + id + "/web", {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IArticleReadDTO;
        })
        .catch((error: AxiosError) => {
            console.log("Read error" );
            return error;
        });

    return response;
}

export async function ReadByTitle(title: string, abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "ReadByTitle/" + title + "/web", {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IArticleReadDTO;
        })
        .catch((error: AxiosError) => {
            console.log("ReadByTitle error");
            return error;
        });

    return response;
}

export async function GetCategory(id: number, take: number, abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "Category/" + id + "/" + take + "/web" , {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IArticleCategoryLatestDTO;
        })
        .catch((error: AxiosError) => {
            console.log("Category error" );
            return error;
        });

    return response;
}

export async function GetCategories(skip: number, take: number, abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "GetCategories/" + skip + "/" + take + "/web", {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as IArticleCategoryListDTO[];
        })
        .catch((error: AxiosError) => {
            console.log("GetCategories error");
            return error;
        });

    return response;
}

export async function GetCategoryArticles(id: number, skip: number, take: number, displayedSinceLastAd: number, abortController: AbortController) {
    const response = await axios
        .get(BACKEND_URL + "GetCategoryArticles/" + id + "/" + skip + "/" + take + "/web/" + displayedSinceLastAd, {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as ICategoryArticlesDTO;
        })
        .catch((error: AxiosError) => {
            console.log("GetCategories error" );
            return error;
        });

    return response;
}
