import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { RoutePaths } from "../../../Constants/RoutePaths";
import IArticleCategoryDTO from "../../../Models/DTOs/IArticleCategoryDTO";
import {Ease} from "../../../Constants/EasingCurves";

const
    Container = styled(NavLink)`
        padding: calc(2rem/16) calc(12rem/16);
        text-decoration: none;
        box-shadow: 0 0 0 calc(2rem/16) ${Colours.Secondary};
        background: ${Colours.SecondaryHighlight};
        transition: background .25s ${Ease.Smooth};
        border-radius: calc(16rem/16);
        display: inline-block;
        font-size: 10pt;
        margin: 0 1rem 1rem 0;
        &:hover,
        &:focus {
            background: ${Colours.IckonicPink};
        }
    `;

function CategoryTerm(props: {category: IArticleCategoryDTO}) {
    return (
        <Container to={RoutePaths.ArticleCategory(props.category.Id)}>
            {props.category.Title}
        </Container>
    );
}

export default CategoryTerm;
