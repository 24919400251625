import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";
import ImageLazyLoad from "../UI/ImageLazyLoad";
import PrimaryText from "../UI/Text/PrimaryText";
import { Colours } from "../../Constants/Colours";
import { RoutePaths } from "../../Constants/RoutePaths";
import NavLinkBlackButton from "../UI/Buttons/NavLinkBlackButton";
import { Ease } from "../../Constants/EasingCurves";

const globalStyles = `
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    transition: 250ms;
    backdrop-filter: blur(5px);
    display: block;
    & > div {
        margin-bottom: 10px;
    }
    
    p {
        &:first-of-type {
            font-weight: 600;
        }
    }

    &.noBg{
        border-top: 4px transparent solid;
        border-radius: 0;
        padding: 6px 0 0 0;
        background: transparent;
    }
`;

const Container = styled(NavLink)`
    padding: 10px;
    background: rgba(0, 0, 0, 0.35);
    ${ globalStyles }
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    background: ${ Colours.IckonicPinkSubtleHightlight };
    a,
    button {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 82px);
        opacity: 0;
        transform: translateY(20px);
        transition: transform .15s ${ Ease.LateSnap }, opacity .15s ${ Ease.LateSnap }, background .25s ${ Ease.Smooth }, box-shadow .15s ${ Ease.Smooth };
    }
    
    img {
        transition: transform .5s ${ Ease.Smooth }, opacity .5s ${ Ease.Smooth };
        transform: scale(1.02);
    }
    
    &:hover,
    &:active {
        img {
            opacity: 0.5;
            transform: scale(1.05);
        }
        
        a,
        button {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const DummyDiv = styled.div`
    ${ globalStyles }
    & > div {
        position: relative;
        width: 100%;
        padding-top: 56.25%; 
        margin: 0;
    }
    
    p {
        position: relative;
        margin: 10px 0 0 0 !important;
        height: 20px;
    }
`;


function ArticleCarouselItem(props: {Content?: ICarouselContentDTO, transparent?: boolean, borderHoverEffect?: boolean, isLoaded?:boolean }) {

    let redirect = props.Content ? RoutePaths.ReadArticle(props.Content.Link) : null;
    const transparent = props.transparent ? "noBg " : "";
    const borderHoverEffect = props.borderHoverEffect ? "border-hover " : "";
    const classes = transparent + borderHoverEffect;

    return props.Content ? (
            <Container className={classes} to={redirect != null ? redirect : "/"}>
                { props.Content && (
                    <>
                        <ImageContainer>
                            <ImageLazyLoad source={props.Content.Thumbnail} />

                            <NavLinkBlackButton>
                                Read Article
                            </NavLinkBlackButton>
                        </ImageContainer>

                        <PrimaryText>{props.Content.Title}</PrimaryText>
                        <PrimaryText>{props.Content.Text}</PrimaryText>
                    </>
                ) }
            </Container>
        )
        :
        <DummyDiv><div><div className="is-loading loader--side-to-side"> </div></div><p><div className="is-loading loader--side-to-side"> </div></p> <p><div className="is-loading loader--side-to-side"> </div></p></DummyDiv>
}

export default ArticleCarouselItem;