import { NavLink } from "react-router-dom";
import { ISearchFilterDTO } from "../../../../Models/DTOs/ISearchFilterDTO";
import { Colours } from "../../../../Constants/Colours";
import { useLocation } from 'react-router-dom';
import { WatchSecondary } from '../../../../Constants/SecondaryNavBars';
import AuthContext from "../../../../Store/auth-context";
import styled from "styled-components";
import { IckonicLogo } from '../../../../Assets/Images/Logos/Logos';
import { ExternalLink } from '../../../../Assets/Icons/Icons';
import ProfileButton from "./ProfileButton";
import Notifications from "./Notifications";
import SearchBar from "../SearchBar";
import { RoutePaths } from "../../../../Constants/RoutePaths";
import { HeaderProperties } from '../../../../Constants/HeaderProperties';
import { Fonts } from "../../../../Constants/Fonts";
import { Ease } from "../../../../Constants/EasingCurves";
import {ResponsiveBreakpoints} from "../../../../Constants/ResponsiveBreakpoints";
import NavLinkPinkButton from "../../Buttons/NavLinkPinkButton";
import {useContext} from "react";

const Header = styled.header`
    background: ${ Colours.SecondaryDarker };
    height: ${ HeaderProperties.height };
    flex: 0 1 auto;
    z-index: 25;
    position:fixed;
    top: 0;
    width:100%;
    .link--subscribe {
        position: absolute;
        top: ${ HeaderProperties.height };
        width: 100%;
        height: calc(12rem/16);
        color: white;
        background: ${ Colours.IckonicPink };
        border-radius: 0;
        text-transform: uppercase;
        font-size: 12pt;
        z-index: 3;
        &:hover,
        &:focus {
            color: black;
            background: ${ Colours.Accent };
            box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.Accent};
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.MediumMobileBreakpoint }em/16)) {
            top: calc(12.5rem/16);
            height: calc(30rem/16);
            left: calc(120rem/16);
            width: calc(120rem/16);
            border-radius: calc(3rem/16);
            color: black;
            background: ${ Colours.Accent };
            box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.Accent};
            animation-name: pulseSoftAccent;
            animation-iteration-count: infinite;
            animation-duration: 3s;
            animation-timing-function: ${ Ease.Smooth };
            &:hover,
            &:focus {
                color: white;
            }
        }
    }
`;

const Navigation = styled.nav`
    display: flex;
    justify-content: space-between;
`;

const List = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0 auto;
    height: ${ HeaderProperties.height };
    position: absolute;
    top: 0;
    left: calc(50% - 294px);
    align-items: flex-start;
    justify-content: flex-start;
`;

const ListRight = styled.ul`
    list-style: none;
    display: inline-flex;
    padding: 0;
    margin: 0 6px 0 0;
    height: ${ HeaderProperties.height };
    align-items: center;
    justify-content: flex-end;
    float: right;
`;

const ListItem = styled.li`
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;


    a {
        > svg {
            width: calc(10rem/16);
            height: calc(10rem/16);
            margin: 0 0 0 calc(5rem/16);
            * {
                fill: ${Colours.Text};
            }
        }

        &:hover.
        &:focus {
            svg {
                * {
                    fill: white;
                }
            }
        }
    }
`;

const StyledLogo = styled(NavLink)`
    display: flex;
    margin: 4px 20px 0px 5px;
    width: 108px;
    height: 47px;
    padding: 10px;
    svg {
        * {
            fill: #ffffff;
        }
    }
`;

const StyledLink = styled(NavLink)`
    color: #cccccc;
    text-decoration: none;
    font-family: ${ Fonts.Primary };
    font-size: 11pt;
    font-weight: 500;
    padding-bottom: 0.25rem;
    padding: 20px 12px;
    position:relative;
    display: block;
    transition-duration: 100ms;
    transition-timing-function: ${ Ease.LateSnap };
    line-height: 1;
    &:active,
    &:hover {
        color: #ffffff;
    }

    &.active {
        box-shadow: inset 0 calc(2rem/16) 0 0 ${Colours.IckonicPinkHighlight};
    }
`;

const ctaButtonBaseStyle = `
    color: ${Colours.Text};
    font-size: 11pt;
    font-family: ${ Fonts.Primary };
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    padding: 7px 20px;
    border: 0;
    line-height: 1;
    color: white;
    text-align: center;
    border-radius: 19px;
    display: block;
    transition: background .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth };
`;

const StyledRegister = styled(NavLink)`
    ${ ctaButtonBaseStyle }
    margin: 0 7px;
    box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPinkHighlight };
    &:hover,
    &:focus,
    &:active {
        background: ${ Colours.IckonicPinkHighlight };
        color: black;
    }
`;

function DesktopNavBar(props: {
    searchText: string,
    onSearchUpdate: (text: string) => void,
    searchFilter: ISearchFilterDTO,
    onSearchFilterUpdate: (filters: ISearchFilterDTO) => void,
    isAuthenticated: boolean,
    currentCountryCode: string,
}) {
    const
        { pathname } = useLocation(),
        authCtx = useContext(AuthContext);

    return (
        <Header>
            <Navigation>
                <StyledLogo to={props.isAuthenticated ? RoutePaths.Browse : "/"}>
                    <IckonicLogo />
                </StyledLogo>

                { props.isAuthenticated && authCtx.UserDetails.SubscriptionStatus === 0 ?
                    (
                        <NavLinkPinkButton to={ RoutePaths.ChoosePlan } className="link--subscribe">
                            Subscribe
                        </NavLinkPinkButton>
                    )
                    :
                    null
                }

                <List>

                    { WatchSecondary.items.map((menuItem, index) => (
                        <ListItem key={ index }>
                            <StyledLink
                                to={ menuItem.Link }
                                className={ pathname.includes(menuItem.Link) ? "active" : "" }
                            >
                                { menuItem.Title }
                            </StyledLink>
                        </ListItem>
                    )) }

                    <ListItem>
                        <StyledLink to={RoutePaths.BrowseArticles}>Read</StyledLink>
                    </ListItem>

                    <ListItem>
                        <StyledLink
                            to="https://shop.ickonic.com"
                            target="_blank"
                        >
                            Shop
                            { ExternalLink() }
                        </StyledLink>
                    </ListItem>

                </List>
                <ListRight>
                    { !props.isAuthenticated ? (
                        <>
                            <ListItem>
                                <StyledLink to={RoutePaths.Login()}>Login</StyledLink>
                            </ListItem>

                            <ListItem>
                                <StyledRegister
                                    to={RoutePaths.CreateYourAccount}
                                >
                                    Sign Up
                                </StyledRegister>
                            </ListItem>
                        </>
                    ) : (
                        <>
                            <SearchBar
                                searchText={props.searchText}
                                onSearchUpdate={props.onSearchUpdate}
                                searchFilter={props.searchFilter}
                                onSearchFilterUpdate={props.onSearchFilterUpdate}
                                toggleMobileMenuClosed={ () => null }
                                currentCountryCode={ props.currentCountryCode }
                            />

                            <Notifications />

                            <ListItem>
                                <ProfileButton />
                            </ListItem>
                        </>
                    )}
                </ListRight>
            </Navigation>
        </Header>
    );
}

export default DesktopNavBar;
