import styled from "styled-components"
import { Colours } from "../../../Constants/Colours";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 20px 10px;
    background-color: ${Colours.SecondaryHighlight};
`;

const SubContainer = styled.div`
    display: flex;
`;

function SocialAndRatingsLoader() {
    
    return (
        <Container>
            <SubContainer>
                {[...Array(7)].map((e, i) => <Circle key={i}/>)}
            </SubContainer>
            <SubContainer>
                {[...Array(2)].map((e, i) => <Circle key={i}/>)}
            </SubContainer>
        </Container>
    );
}

export default SocialAndRatingsLoader