import styled from "styled-components";
import { RoutePaths } from "../../../Constants/RoutePaths";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import NavLinkPinkButtonFat from "../Buttons/NavLinkPinkButtonFat";
import PrimaryText from "../Text/PrimaryText";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Fragment } from 'react';
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    width: 100%;
    padding: 3em 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
`;

const TextContainer = styled.div`    
    max-width: ${ResponsiveBreakpoints.TabletBreakpoint + 'px'};
    display: inline-flex;   
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; 
    p {
        text-align: center;
        font-size: 13pt;
    }
`;

function PanelButton(props: { panel: IPanelDTO, freeDaysTrial: number }) {

    const text = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Text
    );

    const url = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.ButtonLink
    );

    let buttonLink : string;
    if (!(url === null || url === undefined)) {
        buttonLink = url.Value;
    } else {
        buttonLink = RoutePaths.CreateYourAccount;
    }

    return (
        <Container>
            {props.panel.Properties.filter(
                (x) => x.PropertyType === PanelPropertyType.ButtonText
            ).map((property, index) => {
                return (
                    <Fragment key={ index }>
                        {!(text === null || text === undefined) ?
                            <TextContainer key={"TC" + text.Id}>
                                <PrimaryText key={"HT" + text.Id}>
                                    {parse(DOMPurify.sanitize(text.Value))}
                                </PrimaryText>
                            </TextContainer>
                            : null
                        }

                        <NavLinkPinkButtonFat key={"B" + property.Id} to={buttonLink}>
                            {property.Value.replace(
                                "{freetrialdays}",
                                props.freeDaysTrial.toString()
                            )}
                        </NavLinkPinkButtonFat>
                    </Fragment>
                );
            })}
        </Container>
    );
}

export default PanelButton;
