import styled from "styled-components";
import AppsImage from "../../../Assets/Images/apps.png";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    width: 100%;
    background: linear-gradient(#1d1d1d 45%, #141414 90%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth};
    border-radius: 5px;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

function DownloadAppAd() {
    return (
        <Container>
            <Image src={AppsImage} />
        </Container>
    );
}

export default DownloadAppAd;
