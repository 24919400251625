import React, { useEffect, useRef, useState } from "react";
import IAffiliateDTO from "../Models/DTOs/IAffiliateDTO";
import ILocalContext from "../Models/DTOs/ILocalContext";
import lscache from "lscache";
import { LocalStorageKeys } from "../Constants/LocalStorageKeys";

export let FreeTrialDays : IAffiliateDTO | undefined = InitFreeTrialDays();

function InitFreeTrialDays() {
    //Clears expired data
    lscache.flushExpired();

    let freeDaysJson = lscache.get(LocalStorageKeys.Affiliate);

    if (freeDaysJson === null || freeDaysJson.length === 0) {
        return undefined;
    }

    let affiliate = freeDaysJson as IAffiliateDTO;
    
    return affiliate;
}

const LocalContext = React.createContext<ILocalContext>({
    Affiliate: undefined
} as ILocalContext);

export default LocalContext;

export const LocalContextProvider = (props: { children: React.ReactNode }) => {
    const [affiliate, setAffiliate] = useState<IAffiliateDTO>();
    const isLoaded = useRef<boolean>(false);

    useEffect(() => {
        if (isLoaded.current) {
            return;
        }

        function LoadData() {
            const result = InitFreeTrialDays();

            setAffiliate(result);
        }

        LoadData();
        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function SetAffiliate(newAffiliate?: IAffiliateDTO){
        setAffiliate(newAffiliate);
    }

    return (
        <LocalContext.Provider
            value={{
                Affiliate: affiliate,
                SetAffiliate: SetAffiliate,
            }}
        >
            {props.children}
        </LocalContext.Provider>
    );
};
