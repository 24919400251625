import { useEffect } from "react";
import { SetTitle } from "../../Helpers/Utility";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { Colours } from "../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";
import Heading from "../../Components/UI/Text/Heading";
import { Fonts } from "../../Constants/Fonts";

const Container = styled.section`
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    width: 95%;

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + 'px'} ;
    }
`;

const Header = styled.div`

    text-align: center;

    h1 {
        font-size: 38px ;
        margin-bottom: 0.5rem;
    }

    h6 {
        margin-top: 0;
        font-size: 25px;
        a {
            color: ${Colours.IckonicPink};
            text-decoration: none;
        }
    }
`;

const Link = styled.a`
    color: ${Colours.IckonicPink};
    text-decoration: none;

    :hover {
        color: ${Colours.IckonicPinkHighlight}
    }
`

const CareersBox = styled.div`

    width: 100%;
    border-radius: 8px;
    text-align: center;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    background: ${Colours.Tertiary};
    position: relative;
    color: ${Colours.Text};
    
    svg {
        font-size: 40px;
    }

    h1 {
        margin: 5px 0 0 0;
    }

    p {
        margin: 5px 0 5px 0;
        font-size: 12pt;
    }

`;

const CareerContent = styled.p`
    text-align: left;
`;

const Title = styled.p`
    font-weight: 600;
    font-family: ${ Fonts.Primary };
`;

const List = styled.ul`
    font-family: ${ Fonts.Primary };
`;

function CareerScreen() {

    useEffect(() => {
        SetTitle("Careers");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header>
                <Heading>Work with Ickonic</Heading>
                <Heading type={HeadingType.H6}>
                    We aim towards creating and improving an independent media chanel that empowers your decisions.
                </Heading>
            </Header>
            <CareersBox>
                <FaUser />
                <Heading>News and Current Affairs Producer</Heading>
                <CareerContent>
                    <Title>Key Responsibilities and Accountabilities</Title>
                    <List>
                        <li>Contribute to the production and delivery of x3 weekly News & Current affairs programmes</li>
                        <li>Create engaging content for subscribers</li>
                        <li>Responsible for preparation and coordination of in-studio productions</li>
                        <li>You may also be required to carry out any other duties which are within the scope and purpose of the job e.g., filming on location, shift(s)/ unsocial hours/ travel etc.</li>
                        <li>Generating original, exciting and editorially strong ideas</li>
                        <li>Quality of output – demonstrating creative flair and editorial judgment</li>
                        <li>Responding to and learning from feedback from management</li>
                    </List>
                    <Title>Desired qualities</Title>
                    <List>
                        <li>Previous experience as a Producer in television production</li>
                        <li>Experience in sourcing and interviewing contributors</li>
                        <li>Experience in logging rushes and selecting material for edits</li>
                        <li>Experience in writing high-quality scripts for short features and promos</li>
                        <li>Experience in producing edits for promos or short features</li>
                        <li>Self-editing proficiency including knowledge of Adobe Premiere and After Effects</li>
                        <li>Experience in dealing with Talent</li>
                    </List>
                    <Title>Skills and Abilities Mandatory</Title>
                    <List>
                        <li>Ability to work under pressure and tight deadlines</li>
                        <li>Good communication skills (both written and verbal) and excellent interpersonal skills</li>
                        <li>Ability to media manage rushes and projects upon completion</li>
                        <li>Computer literacy and good administrative skills</li>
                        <li>Suggesting ways to improve the output in future programmes- bring new content / fresh ideas</li>
                    </List>
                    <Title>How to Apply?</Title>
                    <List>
                        <li>Please attach your CV and cover letter to <Link href="mailto:jobs@ickonic.com">jobs@ickonic.com</Link></li>
                        <li>Feel free to be creative with your application to include any video content, showreels or examples of your work</li>
                    </List>
                    <Title>Must be able to work from our headquarters in Derby.</Title>
                </CareerContent>

            </CareersBox>
        </Container>
    );

}

export default CareerScreen;