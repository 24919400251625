import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

interface InputDropDownProps {
    options: string[];
    selectedValue: string;
    onChange: (value: string) => void;
    onClick?: () => void,
    placeholder?: string,
    absolutePosition: boolean,
}


const InputDropDownWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    .ickonic-dropdown__selected {
        border-radius: calc(10rem/16);
        background-color: #191919;
        padding: calc(10rem/16) calc(20rem/16) calc(10rem/16) calc(10rem/16);
        cursor: pointer;
        display: flex;
        font-size: calc(14rem/16);
        justify-content: space-between;
        align-items: center;
        &.is-open {
            border-top-left-radius: calc(10rem/16);
            border-top-right-radius: calc(10rem/16);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ickonic-dropdown__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(13rem/16);
        height: calc(13rem/16);
        font-size: calc(12rem/16);
        transition: transform 0.3s ease;
    }

    .ickonic-dropdown__arrow.is-open {
        transform: rotate(180deg);
    }

    .ickonic-dropdown__options {
        width: 100%;
        background-color: #191919;
        max-height: calc(200rem/16);
        overflow-y: auto;
        box-shadow: 0 calc(4rem/16) calc(8rem/16) rgba(0, 0, 0, 0.1);
        z-index: 1000;
        border-bottom-left-radius: calc(10rem/16);
        border-bottom-right-radius: calc(10rem/16);
        &.is-absolute-positioned {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    .ickonic-dropdown__option {
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
        cursor: pointer;
        transition: background-color 0.2s;
    }

    .ickonic-dropdown__option:hover {
        background-color: #202020;
    }

    .ickonic-dropdown__option.is-selected {
        background-color: #202020;
        font-weight: bold;
    }
`;

const InputDropDown: React.FC<InputDropDownProps> = ({ options, selectedValue, onChange, onClick = () => {}, placeholder = "Select an option", absolutePosition }) => {
    const
        [isOpen, setIsOpen] = useState(false),
        dropdownRef = useRef<HTMLDivElement>(null),

        handleToggleDropdown = () => {
            setIsOpen((prev) => !prev);
            onClick();
        },

        handleOptionClick = (value: string) => {
            onChange(value);
            onClick();
            setIsOpen(false);
        },

        handleClose = () => {
            if (isOpen) {
                onClick();
            }

            setIsOpen(false);
        },

        handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

    useEffect(() => {
        const handleClickOutsideWithState = (e: MouseEvent) => {
            handleClickOutside(e);
            if (isOpen) {
                onClick();
            }
        };

        document.addEventListener("mousedown", handleClickOutsideWithState);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideWithState);
        };
    }, [isOpen]);

    return (
        <InputDropDownWrapper className="ickonic-dropdown" ref={dropdownRef}>
            <div className={`ickonic-dropdown__selected ${isOpen ? "is-open" : ""}`} onClick={handleToggleDropdown}>
                {selectedValue || placeholder}
                <span className={`ickonic-dropdown__arrow ${isOpen ? "is-open" : ""}`}>▼</span>
            </div>

            {isOpen && (
                <div className={`ickonic-dropdown__options ${absolutePosition ? 'is-absolute-positioned' : 'is-static-positioned'}`}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`ickonic-dropdown__option ${option === selectedValue ? "is-selected" : ""}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </InputDropDownWrapper>
    );
};

export default InputDropDown;
