import React, {
    forwardRef,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";

import {
    CreatePaymentMethodData,
    PaymentMethod,
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardExpiryElementOptions,
    StripeCardNumberElementChangeEvent,
    StripeCardNumberElementOptions,
    StripeError,
} from "@stripe/stripe-js";
import styled from "styled-components";
import PinkButton from "../Buttons/PinkButton";
import ErrorText from "../Text/ErrorText";
import { Colours } from "../../../Constants/Colours";
import Input from "../Inputs/Input";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputState } from "../../../Models/Enums/InputState";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { StripeInputType } from "../../../Models/Enums/StripeInputType";
import SuccessText from "../Text/SuccessText";
import { Ease } from "../../../Constants/EasingCurves";

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    gap: 10px;

    > div,
    > p {
        width: 100%;
        margin: 0;
    }

    button {
        flex-basis: 100%;
        width: 100%;
    }
    
    input[type=text] {
        margin: 0;
        
    }

    .StripeElement {
        padding: 20px;
        border-radius: 7px;
        border: 1px solid ${ Colours.SecondaryHighlight };
        background: black;
        color: ${Colours.Text};
        transition: border .25s ${ Ease.Smooth };
        box-sizing: border-box;
        width: calc(50% - 5px);
        transition: border .25s ${ Ease.Smooth };
        &:first-child {
            width: 100%;
        }
        
        &:hover,
        &:focus {
            border-color: ${ Colours.TertiaryHighlight };
        }
    }

    &[data-disabled="true"] {
        .StripeElement {
            opacity: 0.5;
            outline: none;
            transition-duration: 100ms;
            transition-property: opacity;
            transition-timing-function: ease-in-out;
        }
    }

    .StripeElement--focus {
        transition-duration: 100ms;
        transition-property: color, background-color, border;
        transition-timing-function: ease-in-out;
    }
`;

const StripeCardForm = forwardRef(
    (
        props: {
            setPaymentMethod: (
                paymentMethod: PaymentMethod
            ) => Promise<boolean> | boolean;
            setIsValid?: (value: boolean) => void;
            hideSubmitBtn?: boolean;
            OnSubmit?: (newPaymentmethod?: PaymentMethod) => Promise<void>;
            setLoading?: (value: boolean) => void;
            hideSuccess?: boolean;
        },
        ref: React.ForwardedRef<HTMLFormElement>
    ) => {
        const stripe = useStripe();
        const elements = useElements();
        const [errorMessages, setErrorMessages] = useState<
            { type: StripeInputType; text: string; justSubmitted: boolean }[]
        >([]);
        const zipInput = useRef<HTMLInputElement | null>(null);
        const [formIsValid, setFormIsValid] = useState(false);
        const [success, setSuccess] = useState<boolean | undefined>();
        const [isLoading, setIsloading] = useState<boolean>(false);
        const [justSubmitted, setJustSubmitted] = useState<boolean>(false);

        const options = {
            style: {
                base: {
                    backgroundColor: "transparent",
                    iconColor: Colours.Text,
                    fontSize: "14px",
                    color: Colours.Text,
                    "::placeholder": {
                        color: Colours.Text,
                    },
                },
                invalid: {
                    color: Colours.Error,
                },
            },
            iconStyle: "solid",
            showIcon: true,
            disabled: isLoading,
        } as StripeCardNumberElementOptions;

        const optionsNoIcon = {
            style: {
                base: {
                    backgroundColor: "transparent",
                    fontSize: "14px",
                    color: Colours.Text,
                    "::placeholder": {
                        color: Colours.Text,
                    },
                },

                invalid: {
                    color: Colours.Error,
                },
            },
            disabled: isLoading,
        } as StripeCardExpiryElementOptions;

        function AddError(type: StripeInputType, text: string) {
            //Stops the same error being added
            if (!errorMessages.some((x) => x.type === type)) {
                setErrorMessages((previousValues) => {
                    return [
                        ...previousValues,
                        {
                            type: type,
                            text: text,
                            justSubmitted: justSubmitted,
                        },
                    ];
                });
            }
        }

        function RemoveError(type: StripeInputType) {
            setErrorMessages((previousValues) => {
                return [...previousValues.filter((x) => x.type !== type)];
            });
        }

        function GetValidState(text: string) {
            if (text.length <= 0 || text.trim().length <= 0) {
                AddError(
                    StripeInputType.Zip,
                    "Zip/Postcode cannot be empty"
                );

                return InputIsValid.Invalid;
            }

            RemoveError(StripeInputType.Zip);
            return InputIsValid.Valid;
        }

        function ZipReducer(state: IInputDTO, action: IInputDTO) {
            switch (action.Type) {
                case InputState.User_Input:
                    return {
                        Value: action.Value,
                        IsValid: GetValidState(action.Value),
                    } as IInputDTO;
                case InputState.Input_Blur:
                    return {
                        Value: state.Value,
                        IsValid: GetValidState(state.Value),
                    } as IInputDTO;
                default:
                    return {
                        Value: "",
                        IsValid: InputIsValid.NotSet,
                    } as IInputDTO;
            }
        }

        const [zipState, dispatchZip] = useReducer(ZipReducer, {
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO);

        const zipIsValid = zipState.IsValid === InputIsValid.Valid;

        useEffect(() => {
            const identifier = setTimeout(() => {
                setFormIsValid(zipIsValid && errorMessages.length === 0);
            }, 500);

            return function CleanUp() {
                clearTimeout(identifier);
            };
        }, [zipIsValid, errorMessages]);

        useEffect(() => {

            if (props.setIsValid === null || props.setIsValid === undefined) {
                return;
            }

            if (stripe === null || stripe === undefined) {
                props.setIsValid(false);
                return;
            }

            if (elements === null || elements === undefined) {
                props.setIsValid(false);
                return;
            }

            if (!formIsValid || isLoading) {
                props.setIsValid(false);
                return;
            }

            props.setIsValid(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [stripe, elements, formIsValid, isLoading]);

        function zipChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
            dispatchZip({
                Type: InputState.User_Input,
                Value: event.target.value,
            } as IInputDTO);
        }

        function stripeInputsChangeHandler(
            event:
                | StripeCardNumberElementChangeEvent
                | StripeCardExpiryElementChangeEvent
                | StripeCardCvcElementChangeEvent
        ) {
            let typeText = "";
            let InputType;
            setJustSubmitted(false);

            switch (event.elementType) {
                case "cardNumber":
                    typeText = "number";
                    InputType = StripeInputType.Number;
                    break;
                case "cardCvc":
                    typeText = "security code";
                    InputType = StripeInputType.Cvc;
                    break;
                case "cardExpiry":
                    typeText = "expiration date";
                    InputType = StripeInputType.Expire;
                    break;
            }

            if (event.empty) {
                AddError(
                    InputType,
                    "Your card's " + typeText + " is incomplete."
                );
                return;
            }

            if (event.error !== undefined) {
                AddError(InputType, event.error.message);
                return;
            }

            RemoveError(StripeInputType.Form);
            RemoveError(InputType);
        }

        function validateZipHandler() {
            dispatchZip({
                Value: zipState.Value,
                Type: InputState.Input_Blur,
            } as IInputDTO);
        }

        function ErrorHandler(error: StripeError) {

            if (error.message === undefined) {
                AddError(
                    StripeInputType.Form,
                    "There was an error with stripe! Please contact support"
                );
                return;
            }

            switch (error.code) {
                case "incomplete_number":
                    AddError(StripeInputType.Number, error.message);
                    return;
                case "incomplete_expiry":
                    AddError(StripeInputType.Expire, error.message);
                    return;
                case "incomplete_cvc":
                    AddError(StripeInputType.Cvc, error.message);
                    return;
            }

            AddError(StripeInputType.Form, error.message);
        }

        async function OnSubmit(event: React.FormEvent<HTMLFormElement>) {
            event.preventDefault();
            setIsloading(true);

            if (props.setLoading !== null && props.setLoading !== undefined) {
                props.setLoading(true);
            }

            dispatchZip({
                Type: InputState.Input_Blur,
                Value: zipState.Value,
            } as IInputDTO);

            //Error message will already be displayed
            if (!formIsValid) {
                setIsloading(false);

                if (
                    props.setLoading !== null &&
                    props.setLoading !== undefined
                ) {
                    props.setLoading(false);
                }

                return;
            }

            if (elements === null || stripe === null) {
                AddError(
                    StripeInputType.Form,
                    "Form is not valid. Please contact support"
                );

                setIsloading(false);

                if (
                    props.setLoading !== null &&
                    props.setLoading !== undefined
                ) {
                    props.setLoading(false);
                }

                return;
            }

            const card = elements.getElement(CardNumberElement);
            const expire = elements.getElement(CardExpiryElement);
            const cvc = elements.getElement(CardCvcElement);

            if (card === null || expire === null || cvc === null) {
                AddError(
                    StripeInputType.Form,
                    "Elements not found. Please contact support"
                );

                setIsloading(false);

                if (
                    props.setLoading !== null &&
                    props.setLoading !== undefined
                ) {
                    props.setLoading(false);
                }

                return;
            }

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                    address: {
                        postal_code: zipState.Value,
                    },
                },
            } as CreatePaymentMethodData);

            if (error !== undefined) {
                ErrorHandler(error);

                setIsloading(false);

                if (
                    props.setLoading !== null &&
                    props.setLoading !== undefined
                ) {
                    props.setLoading(false);
                }
                return;
            }

            if (paymentMethod === undefined) {
                AddError(
                    StripeInputType.Form,
                    "Unexpected error! Please contact support."
                );

                setIsloading(false);

                if (
                    props.setLoading !== null &&
                    props.setLoading !== undefined
                ) {
                    props.setLoading(false);
                }

                return;
            }

            card.clear();
            expire.clear();
            cvc.clear();

            dispatchZip({
                Value: "",
                IsValid: InputIsValid.NotSet,
            } as IInputDTO);

            const result = await props.setPaymentMethod(paymentMethod);

            if(result && props.OnSubmit !== undefined){
                await props.OnSubmit(paymentMethod);
            }

            setSuccess(result);
            setJustSubmitted(true);
            setFormIsValid(false);
            setIsloading(false);

            if (props.setLoading !== null && props.setLoading !== undefined) {
                props.setLoading(false);
            }
        }

        return (
            <Form onSubmit={OnSubmit} ref={ref} data-disabled={isLoading}>
                <CardNumberElement
                    id="cardNumber"
                    options={options}
                    onChange={stripeInputsChangeHandler}
                />
                <CardExpiryElement
                    id="expiry"
                    options={optionsNoIcon}
                    onChange={stripeInputsChangeHandler}
                />
                <CardCvcElement
                    id="cvc"
                    options={optionsNoIcon}
                    onChange={stripeInputsChangeHandler}
                />
                <Input
                    ref={zipInput}
                    placeholder={"Zip/Postcode"}
                    type={"text"}
                    isValid={true}
                    value={zipState.Value}
                    onChange={zipChangeHandler}
                    onBlur={validateZipHandler}
                    disabled={isLoading}
                />

                {success ? (
                    props.hideSuccess ? null : (
                        <SuccessText>Success! Card updated!</SuccessText>
                    )
                ) : success === false ? (
                    <ErrorText key={"failed"}>Card failed to change.</ErrorText>
                ) : null}

                {!formIsValid &&
                !justSubmitted &&
                !isLoading &&
                errorMessages.length > 0
                    ? errorMessages
                          .filter((x) => x.justSubmitted === false)
                          .map((message, index) => {
                              return (
                                  <ErrorText key={index} testId={"cardError"}>
                                      {message.text}
                                  </ErrorText>
                              );
                          })
                    : null}

                {props.hideSubmitBtn === undefined ||
                props.hideSubmitBtn === false ? (
                    <PinkButton
                        type="submit"
                        disabled={
                            !stripe || !elements || !formIsValid || isLoading
                        }
                    >
                        Add Card
                    </PinkButton>
                ) : null}
            </Form>
        );
    }
);

export default StripeCardForm;
