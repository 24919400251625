import styled from "styled-components";
import Card from "../../../Components/UI/Card";
import Heading from "../../../Components/UI/Text/Heading";
import QuestionTooltip from "../../../Components/UI/Text/QuestionTooltip";
import {Colours} from "../../../Constants/Colours";
import {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../../Components/UI/Spinner";
import AuthContext, {UserSnapshot} from "../../../Store/auth-context";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import PinkButton from "../../../Components/UI/Buttons/PinkButton";
import {PaymentMethod} from "@stripe/stripe-js";
import StripeCardForm from "../../../Components/UI/Forms/StripeCardForm";
import {PaymentType} from "../../../Models/Enums/PaymentType";
import {addCookie, GetPlanTitle, SetTitle, SymbolFromCurrency} from "../../../Helpers/Utility";
import WhiteButton from "../../../Components/UI/Buttons/WhiteButton";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import {AddPaymentMethod, CreateSubscription} from "../../../Api/Stripe";
import ErrorText from "../../../Components/UI/Text/ErrorText";
import SignUpHeader from "../../../Components/UI/Subscription/SignUpHeader";
import {SignUpHeaderActive} from "../../../Models/Enums/SignUpHeaderActive";
import {ConfirmSubscriptionAuth} from "../../../Helpers/RouteAuth";
import {RoutePaths} from "../../../Constants/RoutePaths";
import lscache from "lscache";
import {LocalStorageKeys} from "../../../Constants/LocalStorageKeys";
import LocalContext from "../../../Store/local-context";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import CardDetails from "../../../Components/UI/Subscription/CardDetails";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";
import {Ease} from "../../../Constants/EasingCurves";
import {IckonicLogo} from "../../../Assets/Images/Logos/Logos";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };;
    
    > div {
        margin: 75px 0 35px 0;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        @media screen and (min-height: calc(1140em/16)) {
            padding: 0 0 5vh 0;
        }
    }
`;

const CardContent = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 30px;
  
    > div {
      flex-basis: 100%;
      width: 100%;
    }
  
    > button {
      width: 100%;
      font-weight: 500;
    }
`;

const TitleContainer = styled.div`
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    display: flex;
    gap: 15px;
  
    h1 {
      font-size: 2.5rem;
      margin: 0;
    }
`;

const PeriodContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  
    h1 {
      margin: 0;
      cursor: pointer;
      transition-duration: 200ms;
      transition-property: color, opacity;
      transition-timing-function: ease-in-out;
    }
  
    h1.active {
      color: ${Colours.IckonicPink};
    }
  
    h1:hover {
      opacity: 0.5;
      color: ${Colours.IckonicPink};
    }
`;

const PlanDetailsContainer = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
     padding: 25px 16px;
     background: ${ Colours.Secondary };
     border-radius: 7px;
     box-sizing: border-box;
`;

const PlanDetailsSplit = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

  
    h2,
    h3 {
      margin: 8px 0;
    }
    
    button {
      margin: 0 auto;
      display: table;
      padding: 8px 15px;
    }
    
    h3 {
      font-weight: 500;
      width: 100%;
      font-size: 14pt;
      text-align: center;
      margin: 0 0 1rem 0;
    }
  
    h2 > span:last-child {
      font-size: 0.7em;
      color: ${Colours.Text};
    }
`;

const LogoContainer = styled(NavLink)`
    position: absolute;
    top: 24px;
    left: calc(50% - (55rem/16));
    width: 110px;
    height: 30px;
    path {
        fill: ${ Colours.Text };
        transition: fill .3s ${ Ease.Smooth };
    }
    
    &:hover,
    &:focus {
        path {
            fill: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

function ConfirmSubscriptionScreen() {
  const { state } = useLocation();
  const plan = state !== null ? (state.plan as IPlanDTO) : null;
  const newCardForm = useRef<HTMLFormElement | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentType>(
      PaymentType.New
  );
  const authCtx = useContext(AuthContext);
  const localCtx = useContext(LocalContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();
  const [selectedPlan, setSelectedPlan] = useState<IPlanDTO | null>(plan);
  const [newCardValid, setNewCardValid] = useState<boolean>(false);
  const controller = new AbortController();
  const newCard = useRef<PaymentMethod>();

  function OnPaymentTypeClick(type: PaymentType) {
    setPaymentType(type);
  }

  function RemoveSelectedPlan() {
    setSelectedPlan(null);
  }

  function SetSelectedPaymentMethodByObject(paymentMethod: PaymentMethod) {
    newCard.current = paymentMethod;

    return true;
  }

  //Can not figure out how to access state in Loader. This works and does not cause any issues
  useEffect(() => {
    if (state === null || selectedPlan === null || selectedPlan === undefined) {
      navigate(RoutePaths.ChoosePlan);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  function OnSubmit() {
    setLoading(true);

    if (selectedPlan === null) {
      setLoading(false);
      return;
    }

    switch (paymentType) {
      case PaymentType.New:
        submitForm(newCardForm);
        return;
      case PaymentType.Existing:
        Submit();
        return;
      case PaymentType.None:
      default:
        return;
    }
  }

  function submitForm(formRef: React.RefObject<HTMLFormElement | null>) {
    if (!formRef || !formRef.current) {
      return;
    }

    const form = formRef.current;

    if (form.requestSubmit) {
      // Use requestSubmit if supported
      form.requestSubmit();
    } else {
      // Manually trigger form submission
      if (document.createEvent) {
        const submitEvent = document.createEvent("HTMLEvents");
        submitEvent.initEvent("submit", true, false);
        form.dispatchEvent(submitEvent);
      } else {
        form.submit(); // Fallback for older browsers
      }
    }
  }

  function SetNewCardValid(value: boolean) {
    setNewCardValid(value);
  }

  async function PurchaseSubscription(paymentMethodId: string) {
    if (selectedPlan === undefined || selectedPlan === null) {
      setSuccess(false);
      setLoading(false);
      return;
    }

    if (
        paymentMethodId === null ||
        paymentMethodId === undefined ||
        paymentMethodId.length === 0
    ) {
      setSuccess(false);
      setLoading(false);
      return;
    }

    const affiliateId = localCtx.Affiliate !== undefined ? localCtx.Affiliate.Id : undefined;

    const result = await CreateSubscription(
        authCtx.UserDetails.Email,
        selectedPlan.PlanId,
        authCtx.UserDetails.AspNetUserId,
        selectedPlan.Type,
        paymentMethodId,
        affiliateId
    );

    let success;

    if (result instanceof AxiosError) {
      success = false;
    }
    else {
      success = result;
    }

    setSuccess(success);

    if (success) {
      addCookie("registered", new Date().toString());
      toast.success("Success! Your subscription has been activated.");
    }

    //Updates becuase new subscription has been added
    await authCtx.Update(authCtx.UserDetails.Access_Token);
    setLoading(false);

    if (!success) {
      setSuccess(false);
      setLoading(false);
      return;
    }

    if (affiliateId !== undefined) {
      lscache.remove(LocalStorageKeys.Affiliate);
      localCtx.SetAffiliate(undefined);
    }

    //Updates becuase new subscripftion has been added
    await authCtx.Update(authCtx.UserDetails.Access_Token);
    setLoading(false);
  }


  async function Submit() {
    if (selectedPlan === undefined || selectedPlan === null) {
      setSuccess(false);
      setLoading(false);
      return;
    }

    if (paymentType === PaymentType.New && newCard.current !== undefined) {
      const newCardSuccess = await AddPaymentMethod(
          authCtx.UserDetails.AspNetUserId,
          newCard.current.id,
          controller
      );

      if (!newCardSuccess || newCardSuccess instanceof AxiosError) {
        setSuccess(false);
        setLoading(false);
        return;
      }

      await PurchaseSubscription(newCard.current.id);
      setLoading(false);

      navigate(RoutePaths.Browse);
      return;
    }

    await PurchaseSubscription(authCtx.UserDetails.PaymentMethodId);
    setLoading(false);
    navigate(RoutePaths.Browse);
  }

  return (
      <Container>
        <LogoContainer to="/">
          { IckonicLogo }
        </LogoContainer>

        <Card>
          <SignUpHeader active={SignUpHeaderActive.ConfirmSubscription} />

          <TitleContainer>
            <Heading type={ HeadingType.H2 }>Billing Information</Heading>

            <QuestionTooltip>
              You can still cancel the auto-renewal even after giving your card details,
              so don't panic! We just need your card details to verify you are human.
              Just keep in mind to cancel at least two days before the subscription renewal date.
            </QuestionTooltip>
          </TitleContainer>

          <CardContent>
            {authCtx.UserDetails.PaymentMethodId !== undefined &&
            authCtx.UserDetails.PaymentMethodId.length > 0 &&

            !loading ? (
                <PeriodContainer>
                  <Heading
                      type={ HeadingType.H4 }
                      className={
                        paymentType === PaymentType.New ? "active" : undefined
                      }
                      onClick={() => OnPaymentTypeClick(PaymentType.New)}
                  >
                    New Card
                  </Heading>

                  <Heading
                      type={ HeadingType.H4 }
                      className={
                        paymentType === PaymentType.Existing ? "active" : undefined
                      }
                      onClick={() => OnPaymentTypeClick(PaymentType.Existing)}
                  >
                    Existing Card
                  </Heading>
                </PeriodContainer>
            ) : null}

            {paymentType === PaymentType.New ? (
                <StripeCardForm
                    ref={newCardForm}
                    setPaymentMethod={SetSelectedPaymentMethodByObject}
                    hideSubmitBtn={true}
                    setIsValid={SetNewCardValid}
                    OnSubmit={Submit}
                    hideSuccess={true}
                />
            ) : (
                <CardDetails userId={authCtx.UserDetails.AspNetUserId} paymentMethodId={authCtx.UserDetails.PaymentMethodId} />
            )}

            {loading ? (
                <Spinner fontSize={"50pt"} />
            ) : (
                <>
                  <PlanDetailsContainer>
                    <PlanDetailsSplit>
                      <Heading type={HeadingType.H3}>
                        { selectedPlan !== null ?
                            `${ GetPlanTitle(selectedPlan.Type) }: ${ SymbolFromCurrency(selectedPlan.Currency) }${ selectedPlan.Title === 'Truth Seeker' ? '1.99 First Month' : '60 First Year' }`
                            :
                            ""
                        }
                      </Heading>
                    </PlanDetailsSplit>

                    <PlanDetailsSplit>
                      <WhiteButton
                          onClick={RemoveSelectedPlan}
                          disabled={false}
                      >
                        Change Plan
                      </WhiteButton>
                    </PlanDetailsSplit>
                  </PlanDetailsContainer>

                  { success !== undefined ? (
                      success ? null : (
                          <ErrorText>Error! Failed to purchase subscription.</ErrorText>
                      )
                  ) : null }

                  <PinkButton
                      disabled={
                        (paymentType === PaymentType.Existing
                            ? false
                            : !newCardValid) || loading
                      }
                      onClick={OnSubmit}
                  >
                    Purchase Subscription
                  </PinkButton>
                </>
            )}
          </CardContent>
        </Card>
      </Container>
  );
}

export default ConfirmSubscriptionScreen;

export async function Loader() {
  const userDetails = await UserSnapshot;

  const responce = ConfirmSubscriptionAuth(userDetails);

  if (responce !== null && responce !== undefined) {
    return responce;
  }

  SetTitle("Confirm Subscription");

  return null;
}