import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

interface ButtonPillUppercaseProps {
    label: string,
    className: string,
    link: string,
    openInNewWindow?: boolean,
    callbackFunction?: () => void,
    onClick?: () => void,
}

const styles = `
    width: 100%;
    height: calc(40rem/16);
    background: #404040;
    border-radius: calc(20rem/16);
    margin: 0 auto;
    display: block;
    font-weight: 700;
    padding: calc(5rem/16) calc(15rem/16);
    text-transform: uppercase;
    color: white;
    font-family: "Manrope", Helvetica sans-serif;
    text-align: center;
    text-decoration: none;
`;

const ButtonWrapper = styled.button`
    ${styles}
`;

const NavLinkWrapper = styled(NavLink)`
    ${styles}
`;

const ButtonPillUppercase: React.FC<ButtonPillUppercaseProps> = ({
    label = 'Click Here',
    className = '',
    link = '',
    callbackFunction = () => {} // Provide a default no-op function if undefined
}) => {
    return link === "" ? (
        <ButtonWrapper className={`ickonic-button ickonic-button__pill-uppercase ${className}`} onClick={callbackFunction}>
            {label}
        </ButtonWrapper>
    ) : (
        <NavLinkWrapper to={link} className={`ickonic-button ickonic-button__pill-uppercase ${className}`} onClick={callbackFunction}>
            {label}
        </NavLinkWrapper>
    );
};

export default ButtonPillUppercase;
