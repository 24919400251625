import styled from "styled-components";
import IPanelDTO from "../../../Models/DTOs/IPanelDTO";
import { PanelPropertyType } from "../../../Models/Enums/IPanelPropertyType";
import ImageLazyLoad from "../ImageLazyLoad";
import Heading from "../Text/Heading";
import { HeadingType } from "../../../Models/Enums/HeadingType";

const Container = styled.div`
    padding: 2rem;
    height: auto;
    text-align: center;
    h2 {
        margin-top: 1rem;
    }
`;

const ImageContainer = styled.div`
    max-width: 721px;
    margin: 0 auto;
`;

function PanelImageCentered(props: { panel: IPanelDTO }) {
    const background = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Image
    );

    const title = props.panel.Properties.find(
        (x) => x.PropertyType === PanelPropertyType.Title
    );

    if (background === null || background === undefined) {
        return null;
    }

    return (
        <Container>
            {
                !(title === null || title === undefined) ?
                    (
                        <Heading key={"TIC" + title.Id} type={ HeadingType.H2 }>
                            { title.Value }
                        </Heading>
                    ) : null
            }

            <ImageContainer>
                <ImageLazyLoad key={"ILL" + background.Id} source={background.Value} />
            </ImageContainer>

        </Container>
    )
}

export default PanelImageCentered;