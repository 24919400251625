import { redirect } from "react-router-dom";
import { RoutePaths } from "../Constants/RoutePaths";
import { PlanType } from "../Models/Enums/PlanType";
import { SubscriptionStatus } from "../Models/Enums/SubscriptionStatus";
import { IUserDetails } from "../Models/IUserDetails";
import { IsActive, IsAuthenticated, NeedsRenew } from "./UserUtility";

export function UnauthOnly(userDetails: IUserDetails) {
    if (IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Browse);
    }
}

export function AccountAuth(userDetails: IUserDetails) {
    if (!IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Login());
    }
}

export function RenewAuth(userDetails: IUserDetails) {
    if (!IsAuthenticated(userDetails) || !NeedsRenew(userDetails)) {
        return redirect(RoutePaths.Root);
    }
}

export function AuthValidSubOnly(userDetails: IUserDetails) {

    if (!IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Login());
    }

    if (NeedsRenew(userDetails)) {
        return redirect(RoutePaths.Renew);
    }

    // Disabling for "open site initiative"
    // if (!IsActive(userDetails)) {
    //     return redirect(RoutePaths.ChoosePlan);
    // }
}

export function AuthNotSubscribed(userDetails: IUserDetails) {
    if (IsActive(userDetails)) {
        return redirect(RoutePaths.Browse);
    }
}

export function ChoosePlanAuth(userDetails: IUserDetails) {
    if (!IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Login())
    }

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.None:
            break;
        default:
            return redirect(RoutePaths.Browse);
    }
}

export function ConfirmSubscriptionAuth(userDetails: IUserDetails) {
    if (!IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Login())
    }

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.None:
            break;
        default:
            return redirect(RoutePaths.Browse);
    }
}

export function FreeWatchAuth(userDetails: IUserDetails, videoId: number) {

    const isAuth = IsActive(userDetails);

    if (isAuth) {
        return redirect(RoutePaths.Watch(videoId));
    }
}

export function CreateAccountAuth(userDetails: IUserDetails, plan?: PlanType) {

    if (!IsAuthenticated(userDetails)) {
        return;
    }

    // Subscribed
    if (IsActive(userDetails)) {
        return redirect(RoutePaths.Browse);
    }

    // Not subscribed no plan
    if (!IsActive(userDetails) && (plan === null || plan === undefined)) {
        return redirect(RoutePaths.ChoosePlan);
    }
}
