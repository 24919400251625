import styled from "styled-components";
import Card from "../../../Components/UI/Card";
import Heading from "../../../Components/UI/Text/Heading";
import { Colours } from "../../../Constants/Colours";
import CreateUser from "../../../Components/UI/Forms/CreateUser";
import { useContext, useState, useEffect} from "react";
import AuthContext, { UserSnapshot } from "../../../Store/auth-context";
import {defer, useLoaderData, useNavigate, Navigate, NavLink} from "react-router-dom";
import { SetTitle } from "../../../Helpers/Utility";
import SignUpHeader from "../../../Components/UI/Subscription/SignUpHeader";
import { SignUpHeaderActive } from "../../../Models/Enums/SignUpHeaderActive";
import { IsAuthenticated } from "../../../Helpers/UserUtility";
import { PlanType } from "../../../Models/Enums/PlanType";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { GetPlanByType } from "../../../Api/Subscription";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { CreateAccountAuth } from "../../../Helpers/RouteAuth";
import { AxiosError } from "axios";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { IckonicLogo } from "../../../Assets/Images/Logos/Logos";
import { Ease } from "../../../Constants/EasingCurves";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import NavLinkPinkTextButton from "../../../Components/UI/Buttons/NavLinkPinkTextButton";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };
    
    > div {
        margin: 75px 0 35px 0;
    }
    
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        @media screen and (min-height: calc(1000em/16)) {
            padding: 0 0 5vh 0;
        }
    }
`;

const TitleContainer = styled.div`
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding-bottom: 20px;

    h2 {
        color: ${ Colours.TextBright }; 
        font-size: 2rem;
        margin: 2rem 0 0 0;
    }
    
    p {
        color: ${ Colours.LighterGrey };
        margin: 5px 0 1rem 0;
        font-size: 10pt;
        a {
            color: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

const LogoContainer = styled(NavLink)`
    position: absolute;
    top: 24px;
    left: calc(50% - (55rem/16));
    width: 110px;
    height: 30px;
    path {
        fill: ${ Colours.Text };
        transition: fill .3s ${ Ease.Smooth };
    }
    
    &:hover,
    &:focus {
        path {
            fill: ${ Colours.IckonicPinkHighlight };
        }
    }
`;

function CreateYourAccountScreen() {
    const { plan } = useLoaderData() as { plan: IPlanDTO | undefined };
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (success || IsAuthenticated(authCtx.UserDetails)) {
            if (plan !== undefined) {
                navigate(RoutePaths.ConfirmSubscription, { state: { plan: plan } });
            } else {
                navigate(RoutePaths.ChoosePlan);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ success ]);

    if (IsAuthenticated(authCtx.UserDetails) && !(plan === undefined || plan === null)) {
        return (<Navigate to={ RoutePaths.ConfirmSubscription } state={ { plan: plan } }/>);
    }

    function SetSuccess(value: boolean) {
        setSuccess(value);
    }
    return (
        <Container>
            <LogoContainer to="/">
                { IckonicLogo }
            </LogoContainer>

            <Card>
                <SignUpHeader active={ SignUpHeaderActive.CreateAccount } />

                <TitleContainer>
                    <Heading type={ HeadingType.H2 }>Create Your Account</Heading>

                    { !loading ? (
                        <PrimaryText>
                            Already have an account? <NavLinkPinkTextButton to={ RoutePaths.Login() }>Login here</NavLinkPinkTextButton>
                        </PrimaryText>
                    ) : null}
                </TitleContainer>

                <CreateUser setSuccess={SetSuccess} success={success} setLoading={setLoading} />
            </Card>
        </Container>
    );
}

export default CreateYourAccountScreen;

export async function Loader({selectedPlan} : { selectedPlan?: PlanType }){
    const controller = new AbortController();
    const userDetails = await UserSnapshot;

    async function InitPlan() {
        SetTitle("Create Your Account");        
        if (selectedPlan === undefined) {
            return;
        }

        const country =
            userDetails.CountryCode !== null &&
            userDetails.CountryCode !== undefined &&
            userDetails.CountryCode.length > 0
                ? userDetails.CountryCode
                : "GB";

        const result = await GetPlanByType(country, selectedPlan, controller);

        if (result === null || result === undefined || result instanceof AxiosError) {
            return;
        }

        return result;
    }

    const responce = CreateAccountAuth(userDetails, selectedPlan);

    if (responce !== undefined) {
        return responce;
    }

    return defer({
        plan: await InitPlan(),
    });
}
