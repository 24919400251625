import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper";
import styled from "styled-components";
import { Colours } from "../../Constants/Colours";
import { Ease } from "../../Constants/EasingCurves";

const SwiperContainer = styled(Swiper)`
    .swiper-pagination-bullet {
        background: ${ Colours.TertiaryHighlight };
        width: 10px;
        height: 10px;
        position: relative;
        margin: 0 8px !important;
        opacity: 1;
        transition: background .25s ${ Ease.Smooth };
        &:after {
            content: '';
            position: absolute;
            width: calc(20rem/16);
            height: calc(20rem/16);
            top: calc(-5rem/16);
            left: calc(-5rem/16);
            border-radius: 50%;
            box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.IckonicPinkHighlight };
            opacity: 0;
            transition: opacity .25s ${ Ease.Smooth };
        }
        
        &:hover,
        &:focus {
            background: ${ Colours.IckonicPinkSubtleHightlight };
        }
        
        &.swiper-pagination-bullet-active {
            background: ${ Colours.IckonicPinkHighlight };
            &:after {
                opacity: 1;
            }
        }
    }
    
    .swiper-button-next,
    .swiper-button-prev {

    }
    
    .swiper-button-next {
        right: calc(2rem/16);
    }
    
    .swiper-button-prev {
        left: calc(2rem/16);
    }

    .swiper-pagination-bullet:hover,
    .swiper-pagination-bullet-active {
        background: ${ Colours.IckonicPinkHighlight };
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: ${ Colours.IckonicPinkHighlight };
     
        text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) rgba(0, 0, 0, 0.3);
        &:after {
            font-size: 30pt;
            transition: color .25s ${ Ease.Smooth };
        }
    }
`;

//NEEDS ROOT FOR EACH CHILD TO BE A SwiperSlide component
function Carousel(props: {
    children: React.ReactNode[],
    sliderPerView: number,
    arrows?: boolean,
    bullets?: boolean,
    loop?: boolean,
    autoPlay?: boolean, spaceBetween?: number}) {
    return (
        <SwiperContainer
            modules={ [Navigation, Pagination, A11y] }
            spaceBetween={ props.spaceBetween }
            slidesPerView={ props.sliderPerView }
            navigation={ props.arrows }
            pagination={ props.bullets ? { clickable: true, type: "bullets" } : false }
            loop={ props.loop }
            autoplay={ props.autoPlay }
        >
            { props.children.map((content) => {
                return content;
            }) }
        </SwiperContainer>
    );
}

export default Carousel;
