import Modal from "./Modal";
import { useState } from 'react';
import styled from "styled-components";
import DailyNewsletterForm from "../Forms/DailyNewsletterForm";

const Container = styled.div`
    max-width: 560px;
`;

function DailyNewsletterModal(props: {
    token: string
}) {
    const [formComplete, setFormComplete] = useState<boolean>(false);

    function formSubmitted() {
        setFormComplete(true);
    }

    return (
        <Container>
            <Modal
                display={!formComplete}
                setDisplay={setFormComplete}
                title="SIGN UP FOR 'THE DAILY DOWNLOAD"
                subText="Receive the day's news and headlines straight to your inbox."
                closeOnClickOutside={false}
                displayClosebutton={false}
            >
                <DailyNewsletterForm token={props.token} formSubmitted={formSubmitted} />
            </Modal>
        </Container>
    );
}

export default DailyNewsletterModal;