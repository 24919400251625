import DesktopNavBar from "./Desktop/DesktopNavBar";
import MobileNavBar from "./Mobile/MobileNavBar";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { ISearchFilterDTO } from "../../../Models/DTOs/ISearchFilterDTO";
import { useLocation } from "react-router-dom";
import { ShouldHideNavbar } from "../../../Helpers/Utility";

function NavBar(props : {
    searchText : string
    onSearchUpdate: (text : string) => void,
    searchFilter : ISearchFilterDTO,
    onSearchFilterUpdate: (filters : ISearchFilterDTO) => void,
    isAuthenticated: boolean,
    currentCountryCode: string,
}) {

    const { width } = useWindowDimensions();
    const location = useLocation();

    if (ShouldHideNavbar(location.pathname)) {
        return (
            <></>
        );
    }

    if (width > ResponsiveBreakpoints.MobileNavbar) {
        return (<DesktopNavBar
            searchText={props.searchText}
            onSearchUpdate={props.onSearchUpdate}
            searchFilter={props.searchFilter}
            onSearchFilterUpdate={props.onSearchFilterUpdate}
            isAuthenticated={props.isAuthenticated}
            currentCountryCode={ props.currentCountryCode }
        />);
    }

    return <MobileNavBar
        searchText={props.searchText}
        onSearchUpdate={props.onSearchUpdate}
        searchFilter={props.searchFilter}
        onSearchFilterUpdate={props.onSearchFilterUpdate}
        isAuthenticated={props.isAuthenticated}
        currentCountryCode={ props.currentCountryCode }
    />
}

export default NavBar;
