import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import Heading from "../Text/Heading";
import Icon from "../../../Assets/Images/error-icon.png";
import PrimaryText from "../Text/PrimaryText";
import { RoutePaths } from "../../../Constants/RoutePaths";
import NavLinkPinkButton from "../Buttons/NavLinkPinkButton";

const ErrorContainer = styled.div`
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: -15px 26px 30px rgb(0 0 0 / 9%);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    max-width: 850px;
    flex-basis: 70%;
    gap: 20px;
    width: 70%;
    height: fit-content;

    img {
        height: 200px;
    }

    h1 {
        color: ${Colours.Text};
        margin: 0;
        font-size: 5em;
    }

    h2 {
        color: ${Colours.Text};
        margin: 0;
        font-size: 3.4em;
    }

    p {
        font-size: 1.05em;
    }
    @media only screen and (max-width: 800px) {
        margin: 10px 0;
    }
    @media only screen and (max-width: 600px) {
        margin: 5px 0;
        flex-basis: 80%;
        width: 80%;
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;

    a {
        font-size: 30px;
    }
`;

const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

function GenericError(props: { message: string; statusCode: number; statusText: string }) {
    const notFoundTxt = [
        "We looked everywhere but couldn't find the page you were searching for.",
        "It might have a new name, no longer exist, or never have existed at all.",
    ];

    const notFoundSubTitle = "Page Not Found";

    return (
        <ErrorContainer>
            <Heading>{props.statusCode}</Heading>
            <Heading type={HeadingType.H2}>
                {props.statusCode === 404 ? notFoundSubTitle : props.statusText}
            </Heading>
            <img src={Icon} alt={"logo"} />
            <MessageContainer>
                {props.statusCode === 404 ? (
                    notFoundTxt.map((txt, i) => {
                        return <PrimaryText key={i}>{txt}</PrimaryText>;
                    })
                ) : (
                    <PrimaryText>{props.message}</PrimaryText>
                )}
            </MessageContainer>

            <ButtonContainer>
                <NavLinkPinkButton to={RoutePaths.Root} testId={"homeBtn"}>Home Page</NavLinkPinkButton>
                <NavLinkPinkButton to={RoutePaths.HelpCentre} testId={"helpBtn"}>Help Centre</NavLinkPinkButton>
            </ButtonContainer>
        </ErrorContainer>
    );
}

export default GenericError;
