import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const buttonStyles = `
    margin: 0 0 0 calc(16rem/16);
    padding: calc(7rem/16) calc(12rem/16);
    background: ${ Colours.SecondaryHighlight };
    font-size: 10pt;
    text-transform: uppercase;
    box-shadow: none;
    color: ${ Colours.Text };
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    text-align: center;
    border: 0;
    line-height: 1;
    font-weight: 600;
    transition: background .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth };
    :disabled {
        opacity: 0.7;
    }
    
    svg {
        margin: 0 calc(8rem/16) 0 0;
        * {
            fill: white;
            transition: fill .25s ${ Ease.Smooth };
        }
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background: ${ Colours.IckonicPink };
    }
`;

const Button = styled(NavLink)`
    ${ buttonStyles }
`;

function NavLinkSmallInlineGreyButton(props: {children: React.ReactNode, to: string, testId?: string }){
    return(
        <Button to={props.to} data-testid={props.testId}>
            {props.children}
        </Button>
    )
}

export default NavLinkSmallInlineGreyButton;