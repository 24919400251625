import axios, { AxiosError } from "axios";
import IPartnerDTO from "../Models/DTOs/IPartnerDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.partner;

export async function GetRandomPartner(abortController: AbortController) {
    const responce = await axios
        .get(BACKEND_URL + "web", {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as IPartnerDTO;
        })
        .catch((error: AxiosError) => {
            console.log("GetRandomPartner " );
            return error;
        });

    return responce;
}