import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Fonts } from "../../../Constants/Fonts";
import { Colours } from "../../../Constants/Colours";
import { Ease } from "../../../Constants/EasingCurves";

const Button = styled(NavLink)`
    box-shadow: inset 0 0 0 calc(2rem/16) #252525;
    background: #252525;
    color: ${ Colours.TextBright };
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    font-size: 14pt;
    padding: 12px 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    text-align: center;
    border: 0;
    line-height: 1;
    margin: 0 auto;
    font-weight: 600;
    transition: background .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth };
    :disabled {
        opacity: 0.7;
    }
    
    svg {
        margin: 0 calc(8rem/16) 0 0;
        * {
            fill: ${ Colours.TextBright };
            transition: fill .25s ${ Ease.Smooth };
        }
    }

    &:not(:disabled):hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.Tertiary };
        color: ${ Colours.Text };
        background-color: none;
        background: none;
    }
`;

function NavLinkGreyButton(props: {children: string|React.ReactNode, to: string}){
    return(
        <Button to={props.to}>
            {props.children}
        </Button>
    )
}

export default NavLinkGreyButton;